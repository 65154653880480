import * as React from "react";
import { useMessageHandler } from "MessageHandler";
import { db } from "pickup-lib";
import { Straighten as Icon } from "@material-ui/icons";
import { makeStyles, createStyles, Theme, Chip } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      minWidth: 50,
    },
  })
);

const Weight: React.FunctionComponent = () => {
  const [value, setValue] = React.useState(0);
  const msg = useMessageHandler("weigh");
  const classes = useStyles();

  const handleMessage = React.useCallback(
    (msg: db.Message<db.WeighMessage>) => {
      setValue(msg.data.value);
    },
    []
  );

  React.useEffect(() => {
    msg.on("weigh", handleMessage);
    return () => {
      msg.off("weigh", handleMessage);
    };
  }, [handleMessage, msg]);

  return (
    <div className={classes.root}>
      <Chip label={value.toString() + "g"} icon={<Icon />} />
    </div>
  );
};

export default Weight;
