import * as React from "react";
import _ from "lodash";
import {
  Paper,
  createStyles,
  Theme,
  makeStyles,
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  TextField,
} from "@material-ui/core";
import { Sync as SyncIcon } from "@material-ui/icons";
import AppContext from "../AppContext";
import { useErrorHandler } from "../ErrorHandler";
import useRouter from "use-react-router";
import { utils } from "pickup-lib";
import SyncTypes from "./sync/SyncTypes";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(3),
    },
    input: { width: "100%", maxWidth: 600, marginTop: 5, marginBottom: 5 },
    btns: { width: "100%", maxWidth: 600, textAlign: "right" },
  })
);

const Sync: React.FunctionComponent<{}> = () => {
  const [type, setType] = React.useState("");
  const [step, setStep] = React.useState(0);
  const [config, setConfig] = React.useState<object>();
  const [key, setKey] = React.useState("");
  const [label, setLabel] = React.useState("");
  const { repos } = React.useContext(AppContext);
  const { history } = useRouter();
  const errorHandler = useErrorHandler();
  const classes = useStyles();

  function handleTypeChange(event: React.ChangeEvent<{}>, value: string) {
    setType(value);
    setStep(1);
  }

  function handleConfigChange(value: object) {
    setConfig(value);
    setStep(2);
  }

  function handelSaveClick() {
    repos!
      .getSyncs()
      .save({ type, key, label, config })
      .then(() => {
        history.push("/syncs");
      })
      .catch(errorHandler);
  }

  const syncType = SyncTypes.get(type);
  const CreateStep = syncType ? syncType.componentCreate : "";
  const typeName = syncType ? syncType.name : "";

  return (
    <Paper elevation={1} className={classes.root}>
      <Typography variant="h2">
        <SyncIcon /> Nouvelle synchronisation
      </Typography>
      <Stepper activeStep={step} orientation="vertical">
        <Step>
          <StepLabel>Type : {typeName}</StepLabel>
          <StepContent>
            <FormControl component={"fieldset" as "div"}>
              <RadioGroup
                aria-label="Type"
                name="type"
                value={type}
                onChange={handleTypeChange}
              >
                {Array.from(SyncTypes).map(([k, v]) => {
                  return (
                    <FormControlLabel
                      key={k}
                      value={k}
                      control={<Radio color="primary" />}
                      label={v.name}
                    />
                  );
                })}
              </RadioGroup>
            </FormControl>
          </StepContent>
        </Step>
        <Step>
          <StepLabel>Configuration</StepLabel>
          <StepContent>
            {CreateStep && <CreateStep onChange={handleConfigChange} />}
          </StepContent>
        </Step>
        <Step>
          <StepLabel>Finalisation</StepLabel>
          <StepContent>
            <div>
              <TextField
                label="Clé"
                value={key}
                className={classes.input}
                onChange={utils.Form.handleChange(setKey)}
                margin="normal"
              />
            </div>
            <div>
              <TextField
                label="Intitulé"
                value={label}
                className={classes.input}
                onChange={utils.Form.handleChange(setLabel)}
                margin="normal"
              />
            </div>
            {config &&
              _.map(config, (v, k) => (
                <div key={k}>
                  <strong>{k}: </strong>
                  {v}
                </div>
              ))}
            <div className={classes.btns}>
              <Button
                variant="contained"
                color="primary"
                onClick={handelSaveClick}
              >
                Continuer
              </Button>
            </div>
          </StepContent>
        </Step>
      </Stepper>
    </Paper>
  );
};

export default Sync;
