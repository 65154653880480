import * as React from "react";
import { db } from "pickup-lib";

type ItemTypesContextProps = {
  types: db.ItemType[];
};

const ItemTypesContext = React.createContext<ItemTypesContextProps>({
  types: [],
});

type UserContextProps = {
  user: db.User;
  org: db.Org;
};
const UserContext = React.createContext<UserContextProps>({
  org: new db.Org(),
  user: new db.User(),
});

const OrgConfigDouaneContext = React.createContext<db.ConfigDouane>({});

export { ItemTypesContext, UserContext, OrgConfigDouaneContext };
