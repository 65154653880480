import * as React from "react";
import { db } from "pickup-lib";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import saveSync from "save-file";
import moment from "moment";
import { TextEncoder } from "text-encoding";
import AppContext from "AppContext";

type DoneCallback = () => void;

interface Props {
  orders: db.Order[];
  onDone: DoneCallback;
}

function header(o: db.Order, count: number): string {
  return (
    [
      "MANU" + o.sourceId, // 01 Numéro
      o.facturation!.nom + " " + o.facturation!.prenom, // 02 Nom du client
      count, // 03 Compteur ligne
      "T", // 04 Marqueur
      "", // 05 Compteur commentaire
      "", // 06 Code fournisseur
      "", // 07 Code famille
      "", // 08 Reference
      "", // 09 Repère
      o.facturation!.nom + " " + o.facturation!.prenom + " " + o.sourceId, // 10 Désignation
      "", // 11 Dimensions
      "", // 12 Quantité
      "", // 13 Prix achat
      "", // 14 Unité
      "", // 15 Prix achat
      "", // 16 Prix fournitures
      "", // 17 Prix achat total
      "", // 18 Prix vente Unitaire HT
      "", // 19 Code TVA
      "", // 20 Reference doc
      "", // 21 Acompte percu
      "", // 22 Code sous famille
    ].join("|") + "\r\n"
  );
}

function item(
  o: db.Order,
  i: db.OrderItem,
  prixAchat: KeyNumber,
  count: number
): string {
  const prixVente = Math.round((i.ttc * 100) / 1.2) / 100;
  return [
    "MANU" + o.sourceId, // 01 Numéro
    o.facturation!.nom + " " + o.facturation!.prenom, // 02 Nom du client
    count, // 03 Compteur ligne
    "A", // 04 Marqueur
    "", // 05 Compteur commentaire
    "", // 06 Code fournisseur
    "", // 07 Code famille
    i.ref, // 08 Reference
    "", // 09 Repère
    i.label, // 10 Désignation
    "", // 11 Dimensions
    i.qty, // 12 Quantité
    "", // 13 Prix achat
    "UN", // 14 Unité
    "", // 15 Prix achat
    "", // 16 Prix fournitures
    prixAchat[i.ref] || prixVente, // 17 Prix achat total
    prixVente, // 18 Prix vente Unitaire HT
    "2000", // 19 Code TVA
    "MANU" + o.sourceId, // 20 Reference doc
    "", // 21 Acompte percu
    "", // 22 Code sous famille
  ].join("|");
}

function shipping(o: db.Order, count: number): string {
  return [
    "MANU" + o.sourceId, // 01 Numéro
    o.facturation!.nom + " " + o.facturation!.prenom, // 02 Nom du client
    count, // 03 Compteur ligne
    "A", // 04 Marqueur
    "", // 05 Compteur commentaire
    "", // 06 Code fournisseur
    "", // 07 Code famille
    "Shipping", // 08 Reference
    "", // 09 Repère
    o.livraison!.modeLabel, // 10 Désignation
    "", // 11 Dimensions
    "1", // 12 Quantité
    "", // 13 Prix achat
    "UN", // 14 Unité
    "", // 15 Prix achat
    "", // 16 Prix fournitures
    Math.round((o.livraison!.totalTtc * 100) / 1.2) / 100, // 17 Prix achat total
    Math.round((o.livraison!.totalTtc * 100) / 1.2) / 100, // 18 Prix vente Unitaire HT
    "2000", // 19 Code TVA
    "MANU" + o.sourceId, // 20 Reference doc
    "", // 21 Acompte percu
    "", // 22 Code sous famille
  ].join("|");
}

const ExportOpenPro: React.FC<Props> = ({ orders, onDone }) => {
  const { repos } = React.useContext(AppContext);

  React.useEffect(() => {
    if (orders.length === 0) {
      return;
    }
    const refs = orders.reduce<string[]>((prev, cur) => {
      return [...prev, ...cur.items.map(i => i.ref)];
    }, []);

    repos!
      .getItems()
      .listByRef(refs)
      .then(items => {
        const prixAchat: KeyNumber = items.reduce<KeyNumber>((prev, cur) => {
          return { ...prev, [cur.ref]: cur.prixAchat || 0 };
        }, {});

        let count = 0;
        const content = orders.map(o => {
          let content = header(o, ++count);
          content +=
            o.items
              .map(i => {
                return item(o, i, prixAchat, ++count);
              })
              .join("\r\n") + "\r\n";
          content += shipping(o, ++count);
          return content;
        });
        const uint8array = new TextEncoder("windows-1252", {
          NONSTANDARD_allowLegacyEncoding: true,
        }).encode(content.join("\r\n") + "\r\n");
        saveSync(
          new Blob([uint8array], {
            type: "text/plain;charset=windows-1252",
          }),
          orders[0].source.toUpperCase() +
            "_" +
            moment(orders[0].date)
              .utc()
              .format("YYYY-MM-DD_HH-mm-ss") +
            ".txt"
        );
        onDone();
      });
  }, [repos, orders, onDone]);

  return (
    <Dialog open={true}>
      <DialogTitle>Export OpenPro</DialogTitle>
      <DialogContent>
        <DialogContentText>Préparation en cours...</DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default ExportOpenPro;
