import * as React from "react";
import _ from "lodash";
import {
  createStyles,
  Paper,
  Theme,
  Fab,
  makeStyles,
  IconButton,
  TextField,
  Grid,
  FormControlLabel,
  MenuItem,
  Checkbox,
} from "@material-ui/core";
import {
  Save as SaveIcon,
  ArrowBack as BackIcon,
  FilterList as FilterIcon,
} from "@material-ui/icons";
import { db, utils } from "pickup-lib";
import AppContext from "../AppContext";
import useReactRouter from "use-react-router";
import EditRow from "./douanes/EditRow";
import LoaderInline from "./components/LoaderInline";
import { ItemValue, calcTotal } from "./douanes/Douanes";
import { useErrorHandler } from "../ErrorHandler";
import IconMenu from "./components/IconMenu";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      overflowX: "auto",
      marginBottom: 80,
      overflow: "auto",
    },
    header: {
      padding: theme.spacing(1),
      borderBottom: "1px solid #e0e0e0",
    },
    ref: {
      fontFamily: '"Courier New", monospace',
      color: "rgb(90,90,90)",
    },
    fab: {
      position: "fixed",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    labelField: {
      width: 400,
    },
  })
);

interface Params {
  id?: string;
}

type ValuesType = { [k: string]: ItemValue[] };

const DouaneEdit: React.FunctionComponent = () => {
  const [loading, setLoading] = React.useState("Chargement des données...");
  const [inventory, setInventory] = React.useState<db.Douane>();
  const [values, setValues] = React.useState<ValuesType>();
  const [label, setLabel] = React.useState("");
  const [items, setItems] = React.useState<{ [k: string]: db.Item }>({});
  const [showZero, setShowZero] = React.useState(false);
  const [showDisabled, setShowDisabled] = React.useState(false);
  const { match } = useReactRouter<Params>();
  const context = React.useContext(AppContext);
  const classes = useStyles();
  const { history } = useReactRouter();
  const errorHandler = useErrorHandler();

  React.useEffect(() => {
    setLoading("Chargement des données...");
    context
      .repos!.getDouanes()
      .get(match.params.id!)
      .then(inventory => {
        if (inventory) {
          const v = inventory.items.reduce<ValuesType>((prev, cur) => {
            prev[cur.id.toHexString()] = cur.packagings.map(v => {
              return {
                label: v.label,
                count: v.count === null || v.count === undefined ? "" : v.count,
                multiplier: v.multiplier,
              };
            });
            return prev;
          }, {});
          setValues(v);
          setInventory(inventory);
          setLabel(inventory.label);
          return context
            .repos!.getItems()
            .listByIds(
              Object.getOwnPropertyNames(v),
              { ref: 1 },
              { disabled: 1 }
            );
        }
        return [];
      })
      .then(items => {
        setItems(_.keyBy(items, "_id"));
      })
      .finally(() => {
        setLoading("");
      })
      .catch(errorHandler);
  }, [context.repos, match.params.id, errorHandler]);

  const handleChange = React.useCallback(
    (id: string, index: number, value: number | string) => {
      setValues(c => {
        if (c) {
          const data = c[id];
          data[index].count = value;
          return { ...c, [id]: [...data] };
        }
      });
    },
    []
  );

  function handleSave() {
    if (inventory && values) {
      setLoading("Enregistrement en cours...");
      const items = inventory.items.map(i => {
        const itemId = i.id.toHexString();
        const packagings = i.packagings.map((p, idx) => {
          if (values[itemId] && values[itemId][idx]) {
            const count = values[itemId][idx].count;
            return {
              ...p,
              count: typeof count == "string" ? undefined : count,
            };
          }
          return p;
        });
        return {
          ...i,
          packagings,
          stock: calcTotal(values[itemId], inventory.type, i.initialStock),
        };
      });

      const newlabel = label.trim().length > 0 ? label : inventory.label;

      context
        .repos!.getDouanes()
        .update({ _id: inventory._id, items, label: newlabel })
        .then(() => {
          history.push("/douanes");
        })
        .catch(errorHandler)
        .finally(() => {
          setLoading("");
        });
    }
  }

  const readonly = inventory ? inventory.next !== null : true;

  return (
    <Paper className={classes.root} elevation={1}>
      <Grid
        justify="space-between"
        container
        className={classes.header}
        spacing={0}
      >
        <Grid item>
          <IconButton size="small" onClick={() => history.push("/douanes")}>
            <BackIcon />
          </IconButton>
          {inventory && (
            <span>
              <TextField
                value={label}
                className={classes.labelField}
                onChange={utils.Form.handleChange(setLabel)}
                InputProps={{
                  readOnly: readonly,
                  disabled: readonly,
                }}
              />
              <small>({db.DouaneTypeLabel.get(inventory.type)})</small>
            </span>
          )}
        </Grid>
        <Grid item>
          <IconMenu icon={<FilterIcon />}>
            <MenuItem>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={utils.Form.handleChangeCheck(setShowZero)}
                    checked={showZero}
                  />
                }
                label="Articles stock 0"
              />
            </MenuItem>
            <MenuItem>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={utils.Form.handleChangeCheck(setShowDisabled)}
                    checked={showDisabled}
                  />
                }
                label="Articles désactivés"
              />
            </MenuItem>
          </IconMenu>
        </Grid>
      </Grid>
      <LoaderInline show={Boolean(loading)} message={loading} />
      {inventory &&
        values &&
        !Boolean(loading) &&
        inventory.items.map(i => {
          const id = i.id.toHexString();
          if (!showZero && i.initialStock + i.stock === 0) {
            return null;
          }
          if (!showDisabled && items[id] && items[id].disabled) {
            return null;
          }
          return (
            <EditRow
              key={id}
              item={i}
              type={inventory.type}
              value={values[id]}
              readonly={readonly}
              onChange={handleChange}
            />
          );
        })}

      {inventory && inventory.next === null && (
        <Fab color="secondary" className={classes.fab} onClick={handleSave}>
          <SaveIcon />
        </Fab>
      )}
    </Paper>
  );
};

export default DouaneEdit;
