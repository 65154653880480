import * as React from "react";
import SnackbarStatic from "./SnackbarStatic";

interface Props {
  error?: string[];
  warning?: string[];
  info?: string[];
  success?: string[];
}

export default class SnackbarGroup extends React.Component<Props> {
  public render() {
    return (
      <span>
        {this.renderSnacks("error", this.props.error)}
        {this.renderSnacks("warning", this.props.warning)}
        {this.renderSnacks("info", this.props.info)}
        {this.renderSnacks("success", this.props.success)}
      </span>
    );
  }

  private renderSnacks(
    variant: "success" | "warning" | "error" | "info",
    messages?: string[]
  ): React.ReactNode {
    if (messages) {
      return messages.map((v, i) => {
        return (
          <SnackbarStatic key={variant + v} variant={variant}>
            <span>{v}</span>
          </SnackbarStatic>
        );
      });
    }
    return null;
  }
}
