import * as React from "react";
import {
  SnackbarContent,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import classNames from "classnames";
import { green, amber } from "@material-ui/core/colors";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import WarningIcon from "@material-ui/icons/Warning";

const styles = (theme: Theme) => ({
  root: {
    marginTop: 10,
    marginBottom: 10,
    overflow: "hidden",
  },
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
});

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

interface Props extends WithStyles<typeof styles> {
  children?: React.ReactNode;
  className?: string;
  variant: "success" | "warning" | "error" | "info";
}

const SnackbarStatic = (props: Props) => {
  const { classes, className, variant } = props;
  const Icon = variantIcon[variant];
  return (
    <SnackbarContent
      className={classNames(classes[variant], classes.root, className)}
      message={
        <span className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          <div>{props.children}</div>
        </span>
      }
    />
  );
};

export default withStyles(styles)(SnackbarStatic);
