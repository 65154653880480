import * as React from "react";
import { MenuItem, Input, Menu } from "@material-ui/core";
import { InputProps } from "@material-ui/core/Input";

interface LocalProps {
  options?: string[];
  value?: string;
  onChange?: (value: string) => void;
}

type Props = Pick<InputProps, Exclude<keyof InputProps, keyof LocalProps>> &
  LocalProps;

interface State {
  anchor?: HTMLElement;
}

class AutoCompleteInput extends React.Component<Props, State> {
  private input?: HTMLInputElement;
  private ignoreNextFocus: boolean = false;
  public constructor(props: Props) {
    super(props);
    this.state = {};
  }

  public render() {
    const { anchor } = this.state;
    const { options, onChange, ...props } = this.props;
    return (
      <>
        <Input
          inputRef={ref => (this.input = ref)}
          onFocus={this.handleFocus}
          onClick={this.handleClick}
          onChange={this.handleChange}
          {...props}
        />
        {options && (
          <Menu
            open={Boolean(anchor)}
            anchorEl={anchor}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
            getContentAnchorEl={null}
            onClose={this.handleClose}
            disableAutoFocus
            disableAutoFocusItem
          >
            {options.map(v => {
              return (
                <MenuItem key={v} onClick={() => this.handleItemClick(v)}>
                  {v}
                </MenuItem>
              );
            })}
          </Menu>
        )}
      </>
    );
  }

  public handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (this.props.onChange) {
      this.props.onChange(event.target.value);
    }
  };

  public handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    if (this.ignoreNextFocus) {
      this.ignoreNextFocus = false;
      return;
    }
    this.setState({ anchor: event.currentTarget });
  };

  public handleClick = (event: React.MouseEvent<HTMLInputElement>) => {
    this.setState({ anchor: event.currentTarget });
  };

  public handleItemClick = (value: string) => {
    if (this.input) {
      this.input.value = value;
      if (this.props.onChange) {
        this.props.onChange(value);
      }
    }
    this.ignoreNextFocus = true;
    this.setState({ anchor: undefined });
  };

  public handleClose = () => {
    this.ignoreNextFocus = true;
    this.setState({ anchor: undefined });
  };
}

export default AutoCompleteInput;
