import * as React from "react";
import { HighlightOff as ClearIcon } from "@material-ui/icons";
import {
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { ParsableDate } from "@material-ui/pickers/constants/prop-types";

interface Props {
  label: string;
  value: ParsableDate;
  onChange: (date: MaterialUiPickersDate) => void;
}

const DatePickerWithClear: React.FunctionComponent<Props> = ({
  label,
  value,
  onChange,
}) => {
  return (
    <DatePicker
      variant="inline"
      value={value}
      format="DD/MM/YYYY"
      label={label}
      onChange={onChange}
      InputProps={{
        endAdornment: Boolean(value) && (
          <InputAdornment position="end">
            <IconButton
              aria-label="Effacer"
              onClick={e => {
                onChange(null);
                e.stopPropagation();
              }}
            >
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      disableToolbar
      autoOk
      fullWidth
    />
  );
};

export default DatePickerWithClear;
