import * as React from "react";
import { db, IAuth, stitch } from "pickup-lib";

export type AppContextProps = {
  auth?: IAuth;
  repos?: db.Repos;
  org?: db.Org;
  proxy?: stitch.StitchProxy;
};

const AppContext = React.createContext<AppContextProps>({});

export default AppContext;
