"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

var __values = this && this.__values || function (o) {
  var s = typeof Symbol === "function" && Symbol.iterator,
      m = s && o[s],
      i = 0;
  if (m) return m.call(o);
  if (o && typeof o.length === "number") return {
    next: function next() {
      if (o && i >= o.length) o = void 0;
      return {
        value: o && o[i++],
        done: !o
      };
    }
  };
  throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var db = __importStar(require("../db"));

var utils_1 = require("../utils");

var GenericImporter_1 = require("./GenericImporter");

var OrderImporter =
/** @class */
function (_super) {
  __extends(OrderImporter, _super);

  function OrderImporter(reader, repo) {
    var _this = _super.call(this, reader) || this;

    _this.name = "Import commandes";
    _this.key = "import_orders";

    _this.createOrder = function (order) {
      return new Promise(function (res, rej) {
        _this.repo.save(order).then(function () {
          res();
        }).catch(function (err) {
          console.error(err);
          rej(err);
        });
      });
    };

    _this.repo = repo;

    _this.fields.set("ref", {
      name: "Numéro",
      parser: utils_1.String.parseString,
      required: true
    });

    _this.fields.set("date", {
      name: "Date",
      parser: utils_1.String.parseDate,
      required: true
    });

    _this.fields.set("facturationNom", {
      name: "Nom (Facturation)",
      parser: utils_1.String.parseString,
      required: true
    });

    _this.fields.set("facturationPrenom", {
      name: "Prénom (Facturation)",
      parser: utils_1.String.parseString,
      required: true
    });

    _this.fields.set("facturationSociete", {
      name: "Société (Facturation)",
      parser: utils_1.String.parseString,
      required: true
    });

    _this.fields.set("facturationLine1", {
      name: "Adresse1 (Facturation)",
      parser: utils_1.String.parseString,
      required: true
    });

    _this.fields.set("facturationLine2", {
      name: "Adresse2 (Facturation)",
      parser: utils_1.String.parseString
    });

    _this.fields.set("facturationLine3", {
      name: "Adresse3 (Facturation)",
      parser: utils_1.String.parseString
    });

    _this.fields.set("facturationPostCode", {
      name: "Code postal (Facturation)",
      parser: utils_1.String.parseString,
      required: true
    });

    _this.fields.set("facturationCity", {
      name: "Ville (Facturation)",
      parser: utils_1.String.parseString,
      required: true
    });

    _this.fields.set("facturationCountryCode", {
      name: "Code pays (Facturation)",
      parser: utils_1.String.parseString,
      required: true
    });

    _this.fields.set("facturationEmail", {
      name: "Email (Facturation)",
      parser: utils_1.String.parseString
    });

    _this.fields.set("facturationTel", {
      name: "Tel (Facturation)",
      parser: utils_1.String.parseString
    });

    _this.fields.set("facturationGsm", {
      name: "Gsm (Facturation)",
      parser: utils_1.String.parseString
    });

    _this.fields.set("livraisonNom", {
      name: "Nom (Livraison)",
      parser: utils_1.String.parseString
    });

    _this.fields.set("livraisonPrenom", {
      name: "Prénom (Livraison)",
      parser: utils_1.String.parseString
    });

    _this.fields.set("livraisonSociete", {
      name: "Société (Livraison)",
      parser: utils_1.String.parseString
    });

    _this.fields.set("livraisonLine1", {
      name: "Adresse1 (Livraison)",
      parser: utils_1.String.parseString
    });

    _this.fields.set("livraisonLine2", {
      name: "Adresse2 (Livraison)",
      parser: utils_1.String.parseString
    });

    _this.fields.set("livraisonLine3", {
      name: "Adresse3 (Livraison)",
      parser: utils_1.String.parseString
    });

    _this.fields.set("livraisonPostCode", {
      name: "Code postal (Livraison)",
      parser: utils_1.String.parseString
    });

    _this.fields.set("livraisonCity", {
      name: "Ville (Livraison)",
      parser: utils_1.String.parseString
    });

    _this.fields.set("livraisonCountryCode", {
      name: "Code pays (Livraison)",
      parser: utils_1.String.parseString
    });

    _this.fields.set("livraisonEmail", {
      name: "Email (Livraison)",
      parser: utils_1.String.parseString
    });

    _this.fields.set("livraisonTel", {
      name: "Tel (Livraison)",
      parser: utils_1.String.parseString
    });

    _this.fields.set("livraisonGsm", {
      name: "Gsm (Livraison)",
      parser: utils_1.String.parseString
    });

    _this.fields.set("livraisonMode", {
      name: "Mode (Livraison)",
      parser: utils_1.String.parseString
    });

    _this.fields.set("livraisonModeLabel", {
      name: "Libellé (Livraison)",
      parser: utils_1.String.parseString
    });

    _this.fields.set("livraisonTotalTtc", {
      name: "Montant TTC (Livraison)",
      parser: utils_1.String.parseNumber
    });

    _this.fields.set("itemRef", {
      name: "Référence (Article)",
      parser: utils_1.String.parseString,
      required: true
    });

    _this.fields.set("itemLabel", {
      name: "Libellé (Article)",
      parser: utils_1.String.parseString,
      required: true
    });

    _this.fields.set("itemQty", {
      name: "Quantité (Article)",
      parser: utils_1.String.parseNumber,
      required: true
    });

    _this.fields.set("itemTtc", {
      name: "TTC (Article)",
      parser: utils_1.String.parseNumber,
      required: true
    });

    _this.fields.set("totalHt", {
      name: "Total HT",
      parser: utils_1.String.parseNumber,
      required: true
    });

    _this.fields.set("totalTva", {
      name: "Total TVA",
      parser: utils_1.String.parseNumber,
      required: true
    });

    _this.fields.set("totalTtc", {
      name: "Total TTC",
      parser: utils_1.String.parseNumber,
      required: true
    });

    return _this;
  }

  OrderImporter.prototype.doDryRun = function () {
    var _this = this;

    return new Promise(function (res, rej) {
      _this.getExistingRefs().then(function (refs) {
        _this.refs = refs;

        _this.repo.countByRef(_this.refs).then(function (c) {
          res({
            messages: [c + " enregistrements existants vont \xEAtre ignor\xE9s.", refs.length - c + " enregistrements vont \xEAtre cr\xE9\xE9s."],
            errors: [],
            count: refs.length,
            canCreate: false
          });
        });
      });
    });
  };

  OrderImporter.prototype.getExistingRefs = function () {
    var _this = this;

    var refs = [];
    return new Promise(function (res) {
      var e_1, _a;

      try {
        for (var _b = __values(_this.reader), _c = _b.next(); !_c.done; _c = _b.next()) {
          var rows = _c.value;

          var ref = rows[_this.fields.get('ref').map].toString();

          if (refs.indexOf(ref) < 0) {
            refs.push(ref);
          }
        }
      } catch (e_1_1) {
        e_1 = {
          error: e_1_1
        };
      } finally {
        try {
          if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        } finally {
          if (e_1) throw e_1.error;
        }
      }

      res(refs);
    });
  };

  OrderImporter.prototype.import = function (create) {
    var _this = this;

    if (create === void 0) {
      create = true;
    }

    return new Promise(function (res) {
      var prom;

      if (!_this.refs) {
        prom = _this.dryrun();
      } else {
        prom = Promise.resolve();
      }

      prom.then(function () {
        _this.repo.findExistingRefs(_this.refs).then(function (existing) {
          return __awaiter(_this, void 0, void 0, function () {
            var errors, count, skippedRefs, created, order, _a, _b, row, data, err_1, e_2_1;

            var e_2, _c;

            return __generator(this, function (_d) {
              switch (_d.label) {
                case 0:
                  errors = [];
                  count = 0;
                  skippedRefs = [];
                  created = 0;
                  order = undefined;
                  _d.label = 1;

                case 1:
                  _d.trys.push([1, 11, 12, 13]);

                  _a = __values(this.reader), _b = _a.next();
                  _d.label = 2;

                case 2:
                  if (!!_b.done) return [3
                  /*break*/
                  , 10];
                  row = _b.value;
                  data = this.rowToData(row);
                  if (!existing.has(data.ref.toLowerCase())) return [3
                  /*break*/
                  , 3]; // Skip

                  if (skippedRefs.indexOf(data.ref) < 0) {
                    skippedRefs.push(data.ref);
                    count++;
                    this.emit("progress", count, this.refs.length);
                  }

                  return [3
                  /*break*/
                  , 9];

                case 3:
                  _d.trys.push([3, 8,, 9]);

                  if (!order) return [3
                  /*break*/
                  , 6];
                  if (!(order.ref !== data.ref)) return [3
                  /*break*/
                  , 5]; // Sauvegarde la commande en cours

                  return [4
                  /*yield*/
                  , this.createOrder(order)];

                case 4:
                  // Sauvegarde la commande en cours
                  _d.sent();

                  count++;
                  created++;
                  this.emit("progress", count, this.refs.length); // Nouvelle commande

                  order = this.dataToOrder(data);
                  _d.label = 5;

                case 5:
                  return [3
                  /*break*/
                  , 7];

                case 6:
                  // Première commande
                  order = this.dataToOrder(data);
                  _d.label = 7;

                case 7:
                  order.items.push(this.dataToItem(data));
                  return [3
                  /*break*/
                  , 9];

                case 8:
                  err_1 = _d.sent();
                  errors.push(err_1);
                  return [3
                  /*break*/
                  , 9];

                case 9:
                  _b = _a.next();
                  return [3
                  /*break*/
                  , 2];

                case 10:
                  return [3
                  /*break*/
                  , 13];

                case 11:
                  e_2_1 = _d.sent();
                  e_2 = {
                    error: e_2_1
                  };
                  return [3
                  /*break*/
                  , 13];

                case 12:
                  try {
                    if (_b && !_b.done && (_c = _a.return)) _c.call(_a);
                  } finally {
                    if (e_2) throw e_2.error;
                  }

                  return [7
                  /*endfinally*/
                  ];

                case 13:
                  if (!order) return [3
                  /*break*/
                  , 15]; // Sauvegarde la dernière commande

                  return [4
                  /*yield*/
                  , this.createOrder(order)];

                case 14:
                  // Sauvegarde la dernière commande
                  _d.sent();

                  created++;
                  count++;
                  this.emit("progress", count, this.refs.length);
                  _d.label = 15;

                case 15:
                  if (skippedRefs.length) {
                    errors.push(skippedRefs.length + " commandes ignor\xE9e car d\xE9j\xE0 existantes : " + skippedRefs);
                  }

                  res({
                    messages: [created + " commandes ajout\xE9es"],
                    errors: errors,
                    count: count
                  });
                  return [2
                  /*return*/
                  ];
              }
            });
          });
        });
      });
    });
  };

  OrderImporter.prototype.dataToItem = function (data) {
    return {
      ref: data.itemRef,
      label: data.itemLabel,
      qty: data.itemQty,
      ttc: data.itemTtc
    };
  };

  OrderImporter.prototype.dataToOrder = function (data) {
    var order = new db.Order();
    order.date = data.date;
    order.ref = data.ref;
    order.totalHt = data.totalHt;
    order.totalTva = data.totalTva;
    order.totalTtc = data.totalTtc;
    order.source = "manual";
    order.facturation = {
      nom: data.facturationNom,
      prenom: data.facturationPrenom,
      societe: data.facturationSociete,
      line1: data.facturationLine1,
      line2: data.facturationLine2,
      line3: data.facturationLine3,
      postCode: data.facturationPostCode,
      city: data.facturationCity,
      countryCode: data.facturationCountryCode,
      email: data.facturationEmail,
      tel: data.facturationTel,
      gsm: data.facturationGsm
    };
    var livraisonAdr;

    if (data.livraisonNom && data.livraisonPrenom && data.livraisonLine1 && data.livraisonPostCode && data.livraisonCity && data.livraisonCountryCode) {
      livraisonAdr = {
        nom: data.livraisonNom,
        prenom: data.livraisonPrenom,
        societe: data.livraisonSociete,
        line1: data.livraisonLine1,
        line2: data.livraisonLine2,
        line3: data.livraisonLine3,
        postCode: data.livraisonPostCode,
        city: data.livraisonCity,
        countryCode: data.livraisonCountryCode,
        email: data.livraisonEmail,
        tel: data.livraisonTel,
        gsm: data.livraisonGsm
      };
    } else {
      livraisonAdr = __assign({}, order.facturation);
    }

    order.livraison = __assign(__assign({}, livraisonAdr), {
      mode: data.livraisonMode,
      modeLabel: data.livraisonModeLabel,
      totalTtc: data.livraisonTotalTtc
    });
    return order;
  };

  return OrderImporter;
}(GenericImporter_1.GenericImporter);

exports.OrderImporter = OrderImporter;