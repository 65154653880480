import * as React from "react";
import { Chip, Theme, makeStyles, createStyles } from "@material-ui/core";
import { ObjectId } from "bson";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      margin: theme.spacing(0.25),
    },
  })
);

interface OrderInfo {
  id: ObjectId;
  ref: string;
  source: string;
}

interface Props {
  value: OrderInfo[];
  className?: string;
  chipClassName?: string;
  style?: React.CSSProperties;
}

const OrderChips: React.FunctionComponent<Props> = React.memo(
  ({ value, className, chipClassName, style }) => {
    const [showMore, setShowMore] = React.useState(false);
    const classes = useStyles();

    const handleClick = React.useCallback(() => {
      setShowMore(!showMore);
    }, [showMore]);

    return (
      <div className={className} style={style}>
        {(showMore ? value : value.slice(0, 15)).map(v => {
          return (
            <Chip
              key={v.id.toHexString()}
              label={v.ref}
              className={clsx(classes.chip, chipClassName)}
              variant="outlined"
            />
          );
        })}
        {value.length > 15 && (
          <Chip
            label={showMore ? "<" : "+" + (value.length - 10)}
            className={clsx(classes.chip, chipClassName)}
            onClick={handleClick}
          />
        )}
      </div>
    );
  }
);

export default OrderChips;
