import * as React from "react";
import * as _ from "lodash";
import {
  createStyles,
  Paper,
  Theme,
  Fab,
  makeStyles,
  IconButton,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Grid,
} from "@material-ui/core";
import {
  CloudDownload as DownloadIcon,
  ArrowBack as BackIcon,
  FilterList as FilterIcon,
} from "@material-ui/icons";
import { db, utils, AnvokError } from "pickup-lib";
import AppContext from "../AppContext";
import useReactRouter from "use-react-router";
import LoaderRow from "./components/LoaderRow";
import CielRow from "./douanes/CielRow";
import { CielValues, createCielXml, reduceCielValue } from "./douanes/Ciel";
import { OrgConfigDouaneContext } from "../Contexts";
import moment from "moment";
import { useErrorHandler } from "../ErrorHandler";
import IconMenu from "./components/IconMenu";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      overflowX: "auto",
      marginBottom: 80,
      overflow: "auto",
    },
    table: {
      minWidth: 700,
    },
    header: {
      padding: theme.spacing(1),
      borderBottom: "1px solid #e0e0e0",
    },
    headerData: {
      fontWeight: "bold",
    },
    ref: {
      fontFamily: '"Courier New", monospace',
      color: "rgb(90,90,90)",
    },
    fab: {
      position: "fixed",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    warn: {
      fontSize: 12,
      fontFamily: '"Courier New", monospace',
    },
    hl: {
      fontSize: 12,
      fontFamily: '"Courier New", monospace',
      lineHeight: 1.7,
      whiteSpace: "nowrap",
    },
  })
);

interface Params {
  id?: string;
}

type ErrorsType = { [id: string]: string };

const DouaneDeclaration: React.FunctionComponent = () => {
  const [loading, setLoading] = React.useState("Chargement des données...");
  const [inventory, setInventory] = React.useState<db.Douane>();
  const [values, setValues] = React.useState<CielValues>({});
  const [items, setItems] = React.useState<db.Item[]>([]);
  const [errors, setErrors] = React.useState<ErrorsType>({});
  const [month, setMonth] = React.useState(moment().format("MM"));
  const [year, setYear] = React.useState(moment().format("YYYY"));
  const [showZero, setShowZero] = React.useState(false);
  const [showDisabled, setShowDisabled] = React.useState(false);
  const { match } = useReactRouter<Params>();
  const context = React.useContext(AppContext);
  const contextConfig = React.useContext(OrgConfigDouaneContext);
  const classes = useStyles();
  const { history } = useReactRouter();
  const errorHandler = useErrorHandler();

  React.useEffect(() => {
    setLoading("Chargement des données...");
    context
      .repos!.getDouanes()
      .get(match.params.id!)
      .then(r => {
        if (!r) {
          throw new Error("Inventaire introuvable");
        }
        setInventory(r);
        return r.drmitems.reduce<CielValues>(
          reduceCielValue(r.stockOutSusp),
          {}
        );
      })
      .then(r => {
        setValues(r);
        return context
          .repos!.getItems()
          .listByIds(Object.getOwnPropertyNames(r), { place: 1, ref: 1 });
      })
      .then(items => {
        const woPlace = items.filter(i => !Boolean(i.place));
        items = items.slice(woPlace.length).concat(woPlace);
        setItems(items);
        setLoading("");
      })
      .catch(errorHandler);
  }, [context.repos, match.params.id, errorHandler]);

  const handleChange = React.useCallback((id: string, value: string) => {
    const v = utils.String.parseNumber(value);
    setValues(c => {
      if (c[id]) {
        return {
          ...c,
          [id]: {
            ...c[id],
            stockOutAcq: c[id].stockOut - (v === undefined ? 0 : v),
            stockOutSusp: v === undefined ? "" : v.toString(),
          },
        };
      }
      return c;
    });
  }, []);

  function handleDownload() {
    setLoading("Préparation du fichier...");
    const stockOutSusp: { [id: string]: number } = {};
    _.forEach(values, (v, k) => {
      const value = utils.String.parseNumber(v.stockOutSusp);
      if (value !== undefined) {
        stockOutSusp[k] = value;
      }
    });
    context
      .repos!.getDouanes()
      .updateStockOutSusp(match.params.id!, stockOutSusp)
      .then(() => {
        setErrors({});
        return createCielXml(
          contextConfig.numeroAccise,
          month,
          year,
          items,
          values
        );
      })
      .then(xml => {
        const now = moment().format("YYYY-MM-DD");
        return xml.saveAs(
          `DRM_${month}-${year}_${now}_${context.org!.org}.xml`
        );
      })
      .catch(err => {
        if (err instanceof AnvokError && err.data) {
          setErrors(err.data as ErrorsType);
        }
        errorHandler(err);
      })
      .finally(() => {
        setLoading("");
      });
  }

  return (
    <Paper className={classes.root} elevation={1}>
      <Grid
        justify="space-between"
        container
        className={classes.header}
        spacing={0}
      >
        <Grid item>
          <IconButton size="small" onClick={() => history.push("/douanes")}>
            <BackIcon />
          </IconButton>
          {inventory && (
            <span>
              {inventory.label + " - "}
              Période de la DRM :{" "}
              <Select
                value={month}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  setMonth(event.target.value as string);
                }}
              >
                <MenuItem value="01">Janvier</MenuItem>
                <MenuItem value="02">Février</MenuItem>
                <MenuItem value="03">Mars</MenuItem>
                <MenuItem value="04">Avril</MenuItem>
                <MenuItem value="05">Mai</MenuItem>
                <MenuItem value="06">Juin</MenuItem>
                <MenuItem value="07">Juillet</MenuItem>
                <MenuItem value="08">Août</MenuItem>
                <MenuItem value="09">Septembre</MenuItem>
                <MenuItem value="10">Octobre</MenuItem>
                <MenuItem value="11">Novembre</MenuItem>
                <MenuItem value="12">Décembre</MenuItem>
              </Select>
              {" / "}
              <Select
                value={year}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  setYear(event.target.value as string);
                }}
              >
                <MenuItem value="2019">2019</MenuItem>
                <MenuItem value="2020">2020</MenuItem>
                <MenuItem value="2021">2021</MenuItem>
                <MenuItem value="2022">2022</MenuItem>
                <MenuItem value="2023">2023</MenuItem>
                <MenuItem value="2024">2024</MenuItem>
                <MenuItem value="2025">2025</MenuItem>
                <MenuItem value="2026">2026</MenuItem>
              </Select>
            </span>
          )}
        </Grid>
        <Grid item>
          <IconMenu icon={<FilterIcon />}>
            <MenuItem>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={utils.Form.handleChangeCheck(setShowZero)}
                    checked={showZero}
                  />
                }
                label="Articles sans entrées/sorties"
              />
            </MenuItem>
            <MenuItem>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={utils.Form.handleChangeCheck(setShowDisabled)}
                    checked={showDisabled}
                  />
                }
                label="Articles désactivés"
              />
            </MenuItem>
          </IconMenu>
        </Grid>
      </Grid>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Article</TableCell>
            <TableCell align="right">Stock début</TableCell>
            <TableCell align="right">Entrées</TableCell>
            <TableCell align="right">Sorties acquittées</TableCell>
            <TableCell align="right">Sorties suspendues</TableCell>
            <TableCell align="right">Stock fin</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <LoaderRow show={Boolean(loading)} message={loading} cols={6} />
          {items.map(i => {
            const id = i._id.toHexString();
            if (values[id]) {
              if (!showZero && values[id].stockIn + values[id].stockOut === 0) {
                return null;
              }
              if (!showDisabled && i.disabled) {
                return null;
              }
              return (
                <CielRow
                  item={i}
                  key={id}
                  value={values[id]}
                  onChange={handleChange}
                  error={errors[id]}
                />
              );
            }
            return null;
          })}
        </TableBody>
      </Table>
      <Fab color="secondary" className={classes.fab} onClick={handleDownload}>
        <DownloadIcon />
      </Fab>
    </Paper>
  );
};

export default DouaneDeclaration;
