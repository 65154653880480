import * as React from "react";
import {
  createStyles,
  Theme,
  makeStyles,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { db } from "pickup-lib";
import { SubdirectoryArrowRight as DisplayIcon } from "@material-ui/icons";
import SyncActions, { SyncActionConfigComponent } from "./SyncActions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    display: {
      marginLeft: theme.spacing(2),
    },
    treeIcon: {
      fontSize: 18,
      verticalAlign: "text-bottom",
      color: "rgb(100,100,100)",
    },
  })
);

const ActionConfig: SyncActionConfigComponent<db.SyncActionConfig> = ({
  config,
  onChange,
}) => {
  const classes = useStyles();

  const type = config.type === undefined ? "" : config.type;
  const action = SyncActions.get(type);
  const ActionComponent = action ? action.componentConfig : null;

  if (!onChange) {
    return (
      <div className={classes.display}>
        <DisplayIcon className={classes.treeIcon} />
        {ActionComponent && <ActionComponent config={config} />}
      </div>
    );
  }

  function handleSelectChange(
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) {
    let value: db.SyncActionTypes | undefined = event.target.value as db.SyncActionTypes;
    if (onChange && config.type !== value) {
      onChange({ ...config, type: value }, false);
    }
  }

  function handleActionChange(action: db.SyncActionConfig, valid: boolean) {
    if (onChange) {
      onChange({ ...action }, valid);
    }
  }

  return (
    <>
      <FormControl fullWidth required>
        <InputLabel>Réaliser l'action suivante</InputLabel>
        <Select name="type" value={type} onChange={handleSelectChange}>
          {Array.from(SyncActions).map(([k, v]) => (
            <MenuItem key={k} value={k}>
              {v.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {ActionComponent && (
        <ActionComponent config={config} onChange={handleActionChange} />
      )}
    </>
  );
};

export default ActionConfig;
