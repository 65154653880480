import * as React from "react";
import clsx from "clsx";
import {
  createStyles,
  Theme,
  makeStyles,
  Checkbox,
  Grid,
} from "@material-ui/core";
import {
  Event as EventIcon,
  Timer as TimerIcon,
  CloudDownload as LoadingIcon,
  CloudDone as LoadedIcon,
  Storefront as SourceIcon,
  ShoppingCart as PickedIcon,
  Help as DefaultIcon,
  AddShoppingCart as PickerIcon,
} from "@material-ui/icons";
import { db } from "pickup-lib";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import moment from "moment";
import "moment/locale/fr";
import AppContext from "AppContext";

moment.locale("fr");

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: "0 0 225px",
      fontSize: 12,
      lineHeight: 1.5,
      color: "rgba(0,0,0,0.54)",
      padding: 0,
      borderRight: "1px solid rgba(224,224,224,1)",
      borderLeft: "4px solid #FFFFFF",
      "&:last-child": {
        paddingRight: 0,
      },
    },
    light: {
      whiteSpace: "nowrap",
      color: "rgb(90,90,90)",
    },
    lightIcon: {
      fontSize: 18,
      verticalAlign: "text-bottom",
      color: "rgb(200,200,200)",
      marginRight: 3,
      marginLeft: 3,
    },
    datetime: {
      whiteSpace: "nowrap",
      fontSize: 12,
      fontFamily: '"Courier New", monospace',
      margin: 2,
      textAlign: "center",
      flexGrow: 1,
    },
    source: {
      fontSize: 12,
      fontFamily: '"Courier New", monospace',
      margin: 2,
      textAlign: "center",
    },
    state: {
      whiteSpace: "nowrap",
      fontSize: 14,
      padding: 0,
      flex: "1 0 40px",
      display: "flex",
      textTransform: "uppercase",
      fontWeight: "bold",
      alignItems: "center",
      cursor: "pointer",
    },
    stateContent: {
      display: "flex",
      width: "100%",
      cursor: "pointer",
    },
    stateColor0: {
      borderLeft: "4px solid #9e9e9e",
    },
    stateColor10: {
      borderLeft: "4px solid #618833",
    },
    stateColor20: {
      borderLeft: "4px solid #ffa500",
    },
    stateColorBg0: {
      backgroundColor: "#ececec",
    },
    stateColorBg10: {
      backgroundColor: "#f1f7ea",
    },
    stateColorBg20: {
      backgroundColor: "#fff8eb",
    },
    stateCheckbox: {
      float: "left",
    },
    stateIcon: {
      flexGrow: 0,
      verticalAlign: "bottom",
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(0.5),
      height: "auto",
      opacity: 0.7,
    },
    stateText: {
      flexGrow: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  })
);

type OnChangeCallback = (id: string, selected: boolean) => void;

const stateIcons: { [k: number]: React.ComponentType<SvgIconProps> } = {
  0: LoadingIcon,
  10: LoadedIcon,
  20: PickedIcon,
};

interface Props {
  order: db.Order;
  selected: boolean;
  onChange?: OnChangeCallback;
}

const StateCell: React.FunctionComponent<Props> = ({
  order,
  selected,
  onChange,
}) => {
  const { org } = React.useContext(AppContext);
  const classes = useStyles();
  const stateColors: { [k: number]: string } = {
    0: classes.stateColor0,
    10: classes.stateColor10,
    20: classes.stateColor20,
  };
  const stateColorsBg: { [k: number]: string } = {
    0: classes.stateColorBg0,
    10: classes.stateColorBg10,
    20: classes.stateColorBg20,
  };

  const stateClass = stateColors[order.state]
    ? stateColors[order.state]
    : stateColors[0];
  const stateClassBg = stateColorsBg[order.state]
    ? stateColorsBg[order.state]
    : stateColorsBg[0];
  const stateIcon = React.createElement(
    stateIcons[order.state] ? stateIcons[order.state] : DefaultIcon,
    {
      className: classes.stateIcon,
    }
  );

  return (
    <Grid item className={clsx(classes.root, stateClass)}>
      <div className={clsx(classes.state, stateClassBg)}>
        <label className={classes.stateContent}>
          <Checkbox
            color="default"
            checked={selected}
            className={classes.stateCheckbox}
            onChange={() => {
              if (onChange) {
                onChange(order._id!.toHexString(), !selected);
              }
            }}
          />
          <span className={classes.stateText}>
            {order.ref} {order.sourceId}
          </span>
          {stateIcon}
        </label>
      </div>
      <div className={classes.datetime}>
        <span className={classes.light}>
          <EventIcon className={classes.lightIcon} />{" "}
          {moment(order.date)
            .utc()
            .format("L")}
        </span>{" "}
        <span className={classes.light}>
          <TimerIcon className={classes.lightIcon} />{" "}
          {moment(order.date)
            .utc()
            .format("LT")}
        </span>
      </div>
      <div className={classes.source}>
        {org!.config.orders.showSource && order.source && (
          <span className={classes.light}>
            <SourceIcon className={classes.lightIcon} />
            {order.source}
          </span>
        )}
        {order.prepa && (
          <span className={classes.light}>
            <PickerIcon className={classes.lightIcon} />
            {order.prepa.username}
          </span>
        )}
      </div>
    </Grid>
  );
};

export default StateCell;
