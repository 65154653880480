import * as React from "react";
import {
  TextField,
  InputAdornment,
  IconButton,
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import {
  Close as MultiplyIcon,
  Remove as RemoveIcon,
  Add as AddIcon,
} from "@material-ui/icons";
import { utils } from "pickup-lib";

const styles = (theme: Theme) =>
  createStyles({
    line: {
      display: "flex",
    },
    inputref: {
      flexGrow: 1,
    },
    inputqte: {
      width: 150,
    },
    buttons: {
      textAlign: "right",
    },
  });

interface ItemComponent {
  ref: string;
  qty: number;
}

interface Props extends WithStyles<typeof styles> {
  value: ItemComponent[];
  refErrors?: { [i: number]: string };
  qtyErrors?: { [i: number]: string };
  onChange?: (value: ItemComponent[]) => void;
}

interface State {
  anchor?: HTMLElement;
  components: ItemComponent[];
}

class ItemComponents extends React.Component<Props, State> {
  private input?: HTMLInputElement;
  public constructor(props: Props) {
    super(props);
    this.state = {
      components: props.value,
    };
  }

  public render() {
    const { classes, refErrors, qtyErrors, value } = this.props;
    return (
      <>
        {value.map((v, i) => {
          return (
            <div className={classes.line} key={i}>
              <TextField
                className={classes.inputref}
                label="Référence"
                name="ref"
                value={v.ref}
                error={refErrors && Boolean(refErrors[i])}
                helperText={refErrors ? refErrors[i] : ""}
                onChange={this.handleChangeRef(i)}
              />
              <TextField
                className={classes.inputqte}
                label="Quantité"
                name="qty"
                type="number"
                value={v.qty}
                error={qtyErrors && Boolean(qtyErrors[i])}
                helperText={qtyErrors ? qtyErrors[i] : ""}
                onChange={this.handleChangeQty(i)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MultiplyIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Retirer correspondance"
                        onClick={e => {
                          e.stopPropagation();
                          this.handleRemove(i);
                        }}
                      >
                        <RemoveIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          );
        })}
        <div className={classes.buttons}>
          <IconButton
            aria-label="Ajouter correspondance"
            onClick={this.handleAdd}
          >
            <AddIcon />
          </IconButton>
        </div>
      </>
    );
  }

  private handleRemove = (i: number): void => {
    this.props.value.splice(i, 1);
    if (this.props.onChange) {
      this.props.onChange(this.props.value);
    }
  };

  private handleAdd = (): void => {
    this.props.value.push({
      ref: "",
      qty: 1,
    });

    if (this.props.onChange) {
      this.props.onChange(this.props.value);
    }
  };

  public handleChangeRef = (index: number) => (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const value = event.target.value;
    this.props.value[index].ref = value;
    if (this.props.onChange) {
      this.props.onChange(this.props.value);
    }
  };

  public handleChangeQty = (index: number) => (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    let value = utils.String.parseNumber(event.target.value, 0);
    this.props.value[index].qty = value;
    if (this.props.onChange) {
      this.props.onChange(this.props.value);
    }
  };
}

export default withStyles(styles)(ItemComponents);
