import * as React from "react";
import _ from "lodash";
import {
  createStyles,
  Paper,
  Theme,
  makeStyles,
  TextField,
  Grid,
  Button,
} from "@material-ui/core";
import Loader from "../components/Loader";
import SnackbarStatic from "../components/SnackbarStatic";
import { IAuth, utils, AnvokError } from "pickup-lib";
import useRouter from "use-react-router";
import qs from "query-string";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      display: "block", // Fix IE 11 issue.
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      marginTop: theme.spacing(3),
      [theme.breakpoints.up(400 + theme.spacing(3 * 2))]: {
        marginLeft: "auto",
        marginRight: "auto",
        maxWidth: 400,
        width: "50%",
      },
      width: "auto",
    },
    paper: {
      alignItems: "center",
      padding: theme.spacing(2),
    },
    input: { width: "100%", marginTop: 15, marginBottom: 15 },
  })
);

interface Props {
  auth: IAuth;
}

const Reset: React.FunctionComponent<Props> = ({ auth }) => {
  const classes = useStyles();
  const [error, setError] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirm, setConfirm] = React.useState("");
  const [done, setDone] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { history, location } = useRouter();
  const q = qs.parse(location.search, {
    parseBooleans: false,
    parseNumbers: false,
  });
  const token = _.get(q, "token", "")!.toString();
  const user = _.get(q, "user", "")!.toString();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (password !== confirm) {
      setError("Les mots de passe ne correspondent pas");
      return;
    }
    if (password.length < 8) {
      setError("Veuillez choisir un mot de passe de minimum 8 caractères");
      return;
    }
    setLoading(true);
    setError("");
    auth
      .passwordReset(user, token, password)
      .then(() => {
        setDone(true);
      })
      .catch((err: AnvokError) => {
        setError(err.message);
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className={classes.main}>
      <Paper className={classes.paper} elevation={1}>
        <h2>Nouveau mot de passe</h2>
        Pour le compte : <strong>{user}</strong>
        {!done && (
          <Loader show={loading}>
            <form onSubmit={handleSubmit}>
              <TextField
                name="password"
                className={classes.input}
                value={password}
                onChange={utils.Form.handleChange(setPassword)}
                label="Mot de passe"
                error={Boolean(error)}
                helperText={error ? error : "Au minimum 8 caractères"}
                type="password"
              />
              <TextField
                name="confirm"
                className={classes.input}
                value={confirm}
                onChange={utils.Form.handleChange(setConfirm)}
                label="Confirmer le mot de passe"
                type="password"
              />
              <Grid container justify="space-between">
                <Grid item> </Grid>
                <Grid item>
                  <Button type="submit" variant="contained" color="primary">
                    Valider
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Loader>
        )}
        {done && (
          <div>
            <SnackbarStatic variant="success">
              Votre mot de passe a bien été modifié.
              <br />
              Cliquez sur continuer pour vous identifier.
            </SnackbarStatic>
            <Grid container justify="space-between">
              <Grid item> </Grid>
              <Grid item>
                {done && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      history.push("/");
                    }}
                  >
                    Continuer
                  </Button>
                )}
              </Grid>
            </Grid>
          </div>
        )}
      </Paper>
    </div>
  );
};

export default Reset;
