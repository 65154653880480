import * as React from "react";
import {
  makeStyles,
  createStyles,
  Theme,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@material-ui/core";
import Loader from "../components/Loader";
import { utils } from "pickup-lib";
import AppContext from "../../AppContext";
import { useErrorHandler } from "ErrorHandler";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 400,
    },
  })
);

type CloseCallback = (created: boolean) => void;

interface Props {
  onClose: CloseCallback;
}

const Invite: React.FunctionComponent<Props> = ({ onClose }) => {
  const [loading, setLoading] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [name, setName] = React.useState("");
  const [nameError, setNameError] = React.useState("");
  const context = React.useContext(AppContext);
  const classes = useStyles();
  const errorHandler = useErrorHandler();

  function handleSave() {
    let error = false;
    if (email.trim().length === 0) {
      setEmailError("Valeur requise");
      error = true;
    } else {
      setEmailError("");
    }
    if (name.trim().length === 0) {
      setNameError("Valeur requise");
      error = true;
    } else {
      setNameError("");
    }
    if (error) {
      return;
    }
    setLoading("Envoi de l'invitation...");

    context
      .repos!.getItems()
      .find({ types: "AL" }, { place: 1, ref: 1 })
      .then(items => {
        context
          .auth!.invite(email, name)
          .then(() => {
            onClose(true);
          })
          .catch(err => {
            onClose(false);
            errorHandler(err);
          });
      });
  }

  return (
    <Dialog
      open={true}
      onClose={() => onClose(false)}
      aria-labelledby="types-form-dialog-title"
      classes={{ paper: classes.root }}
    >
      <Loader show={Boolean(loading)} message={loading}>
        <DialogTitle id="types-form-dialog-title">
          Inviter un utilisateur
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Nom"
            value={name}
            fullWidth
            onChange={utils.Form.handleChange(setName)}
            helperText={nameError}
            error={Boolean(nameError)}
          />
          <TextField
            label="Adresse email"
            value={email}
            fullWidth
            onChange={utils.Form.handleChange(setEmail)}
            helperText={emailError}
            error={Boolean(emailError)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose(false)}>Annuler</Button>
          <Button onClick={handleSave} color="primary">
            Enregistrer
          </Button>
        </DialogActions>
      </Loader>
    </Dialog>
  );
};

export default Invite;
