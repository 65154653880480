import * as React from "react";
import ReactLoader from "react-loader-advanced";
import CircularProgress from "@material-ui/core/CircularProgress";

interface Props {
  show: boolean;
  message?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  backgroundStyle?: React.CSSProperties;
}

const content = (
  <CircularProgress
    key="progress"
    color="secondary"
    style={{ marginBottom: 10 }}
    disableShrink={true}
  />
);
const Loader = (props: Props) => {
  const message = (
    <div className={props.className} style={props.style}>
      {content}
      <div key="message">{props.message}</div>
    </div>
  );
  return (
    <ReactLoader
      show={props.show}
      backgroundStyle={{ background: "white", ...props.backgroundStyle }}
      foregroundStyle={{ color: "black" }}
      message={message}
    >
      {props.children}
    </ReactLoader>
  );
};

export default Loader;
