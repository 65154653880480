"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var StitchReadOperationServer =
/** @class */
function () {
  function StitchReadOperationServer(op) {
    this.op = op;
  }

  StitchReadOperationServer.prototype.first = function () {
    return this.op.first();
  };

  StitchReadOperationServer.prototype.toArray = function () {
    return this.op.toArray();
  };

  StitchReadOperationServer.prototype.asArray = function () {
    return this.op.asArray();
  };

  StitchReadOperationServer.prototype.iterator = function () {
    return this.op.iterator();
  };

  return StitchReadOperationServer;
}();

exports.default = StitchReadOperationServer;