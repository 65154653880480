import * as React from "react";
import _ from "lodash";
import {
  Button,
  createStyles,
  makeStyles,
  Theme,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Modal,
  Tooltip,
} from "@material-ui/core";
import { Help as HelpIcon } from "@material-ui/icons";
import Loader from "../Loader";
import { db, io } from "pickup-lib";
import SnackbarGroup from "../SnackbarGroup";
import AppContext from "../../../AppContext";
import EditFormat from "./EditFormat";
import { Preview } from "pickup-lib/dist/src/io";
import PreviewTable from "./PreviewTable";
import { useErrorHandler } from "../../../ErrorHandler";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selectFormat: {
      maxWidth: 600,
      display: "flex",
    },
    formControl: {
      margin: theme.spacing(1),
      flexGrow: 1,
    },
    button: {
      margin: theme.spacing(1),
    },
    loader: {
      maxWidth: 300,
    },
    modal: {
      position: "absolute",
      width: "90%",
      maxHeight: "90%",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(4),
      outline: "none",
      top: "10%",
      left: "50%",
      transform: "translate(-50%, -10%)",
      overflow: "auto",
    },
  })
);

type EndCallBack = (mapping: io.FieldMapping[]) => void;
type CancelCallBack = () => void;

interface Props {
  importer: io.ImporterInterface;
  onEnd: EndCallBack;
  onCancel: CancelCallBack;
}

interface LookupResult {
  format: db.Format;
  reason?: string;
}

interface LookupResults {
  selected?: db.Format;
  formats: LookupResult[];
}

const Step2Preview: React.FunctionComponent<Props> = ({
  importer,
  onEnd,
  onCancel,
}) => {
  const classes = useStyles();
  const context = React.useContext(AppContext);
  const [loading, setLoading] = React.useState("Lecture du fichier...");
  const [warnings, setWarnings] = React.useState<string[]>([]);
  const [errors, setErrors] = React.useState<string[]>([]);
  const [formats, setFormats] = React.useState<LookupResult[]>([]);
  const [selected, setSelected] = React.useState<db.Format>();
  const [showCreate, setShowCreate] = React.useState(false);
  const [preview, setPreview] = React.useState<Preview | undefined>();
  const [headers, setHeaders] = React.useState<string[]>([]);
  const errorHandler = useErrorHandler();

  React.useEffect(() => {
    if (headers.length === 0) {
      return;
    }
    setLoading("Chargement des formats...");
    function isFormatValid(format: db.Format, headers: string[]) {
      const srcFields = format.fields.map(f => f.src);
      const diff = _.difference(srcFields, headers);
      if (diff.length === 0) {
        return undefined;
      } else {
        return `Champs manquants : ${diff.join(", ")}`;
      }
    }

    context
      .repos!.getFormats()
      .listByImporter(importer.key)
      .then(formats => {
        let def: db.Format | undefined = undefined;
        const r = formats.reduce<LookupResult[]>((prev, cur) => {
          const reason = isFormatValid(cur, headers);
          if (!Boolean(reason) && !def) {
            def = cur;
          }
          prev.push({
            format: cur,
            reason,
          });
          return prev;
        }, []);
        if (def) {
          setSelected(c => (c ? c : def));
        }
        setFormats(r);
      })
      .catch(err => {
        errorHandler("Erreur lors du chargement des formats.");
      })
      .finally(() => {
        setLoading("");
      });
  }, [importer, context.repos, headers, errorHandler]);

  React.useEffect(() => {
    importer.reader
      .read()
      .then(r => {
        const warnings: string[] = [];
        if (r.getRowHasMore()) {
          warnings.push(
            `Limite de lignes atteinte (${
              r.rowMax
            }), Vérifiez qu'il ne manque pas de données.`
          );
        }
        if (r.getColHasMore()) {
          warnings.push(
            `Limite de colonnes atteinte (${
              r.colMax
            }), Vérifiez qu'il ne manque pas de données.`
          );
        }
        r.getHeaders();
        setLoading("Recherche d'un format correspondant...");
        setWarnings(warnings);
        setHeaders(r.getHeaders());
      })
      .catch(err => {
        const error = `Vérifiez que le format du fichier est correct. : ${String(
          err
        )}.`;
        setErrors([error]);
      })
      .finally(() => {
        setLoading("");
      });
  }, [importer, context.repos]);

  React.useEffect(() => {
    if (selected) {
      importer.setMapping(selected.fields);
      importer.generatePreview().then(p => setPreview(p));
    } else {
      importer.setMapping([]);
      setPreview(undefined);
    }
  }, [importer, selected]);

  function renderMenuItem(r: LookupResult): React.ReactNode {
    if (r.reason) {
      return (
        <MenuItem
          key={r.format._id.toHexString()}
          disabled={true}
          style={{ pointerEvents: "all" }}
        >
          {r.format.label}{" "}
          <Tooltip title={r.reason} placement="right">
            <HelpIcon />
          </Tooltip>
        </MenuItem>
      );
    } else {
      return (
        <MenuItem
          key={r.format._id.toHexString()}
          value={r.format._id.toHexString()}
        >
          {r.format.label}
        </MenuItem>
      );
    }
  }

  function handleChange(
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) {
    const found = formats.find(v => {
      return v.format._id.toHexString() === (event.target.value as string);
    });
    setSelected(found ? found.format : undefined);
  }

  function handleCreated(format: db.Format) {
    setShowCreate(false);
    setFormats(c => [...c, { format }]);
    setSelected(format);
  }

  function handleContinue() {
    if (selected) {
      onEnd(selected.fields);
    }
  }

  return (
    <div>
      <Loader
        className={classes.loader}
        show={Boolean(loading)}
        message={loading}
      >
        <div className={classes.selectFormat}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="format-select">Format du fichier</InputLabel>
            <Select
              value={selected ? selected._id.toHexString() : ""}
              onChange={handleChange}
              inputProps={{ id: "format-select" }}
            >
              {formats.map(v => renderMenuItem(v))}
            </Select>
          </FormControl>
          <div style={{ flexShrink: 0 }}>
            <Button
              className={classes.button}
              color={selected ? "default" : "primary"}
              variant="contained"
              onClick={() => setShowCreate(true)}
            >
              Créer un format
            </Button>
          </div>
        </div>
        {preview && <PreviewTable data={preview} />}
        <SnackbarGroup error={errors} warning={warnings} />
        <div>
          <Button
            className={classes.button}
            component="label"
            onClick={onCancel}
          >
            Annuler
          </Button>
          <Button
            className={classes.button}
            component="label"
            color="primary"
            variant="contained"
            onClick={handleContinue}
            disabled={!Boolean(preview)}
          >
            Continuer
          </Button>
        </div>
      </Loader>
      <Modal open={showCreate}>
        <div className={classes.modal}>
          <EditFormat
            importer={importer}
            onEnd={handleCreated}
            onCancel={() => setShowCreate(false)}
          />
        </div>
      </Modal>
    </div>
  );
};

export default Step2Preview;
