import * as React from "react";
import {
  makeStyles,
  createStyles,
  Theme,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Switch,
} from "@material-ui/core";
import { uniqueId } from "lodash";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listitemText: {
      paddingRight: 10,
    },
  })
);

interface Props {
  label: string;
  checked?: boolean;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
}

const ListItemSwitch: React.FunctionComponent<Props> = ({
  label,
  checked,
  onChange,
}) => {
  const id = React.useRef(uniqueId("list-item-switch-"));
  const classes = useStyles();

  return (
    <ListItem>
      <ListItemText
        className={classes.listitemText}
        primary={label}
        primaryTypographyProps={{ id: id.current }}
      />
      <ListItemSecondaryAction>
        <Switch
          edge="end"
          inputProps={{ "aria-labelledby": id.current }}
          checked={checked}
          onChange={onChange}
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default ListItemSwitch;
