import * as React from "react";
import {
  createStyles,
  Theme,
  makeStyles,
  Grid,
  IconButton,
} from "@material-ui/core";
import {
  Event as EventIcon,
  Face as UserIcon,
  RemoveRedEye as ViewIcon,
} from "@material-ui/icons";
import { db } from "pickup-lib";
import clsx from "clsx";
import moment from "moment";
import "moment/locale/fr";
import useRouter from "use-react-router";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 42,
      borderBottom: "1px solid rgba(224,224,224,1)",
    },
    light: {
      color: "rgb(90,90,90)",
      whiteSpace: "nowrap",
      fontSize: 12,
      fontFamily: '"Courier New", monospace',
      margin: theme.spacing(1),
    },
    lightIcon: {
      fontSize: 18,
      verticalAlign: "text-bottom",
      color: "rgb(200,200,200)",
    },
    btns: {
      textAlign: "right",
      overflow: "hidden",
      margin: theme.spacing(0.5),
    },
  })
);

interface Props {
  picking: db.Picking;
}

const PickingListRow: React.FunctionComponent<Props> = React.memo(
  ({ picking }) => {
    const { history } = useRouter();
    const classes = useStyles();

    return (
      <Grid container className={classes.root}>
        <Grid item className={classes.light} xs>
          <EventIcon className={classes.lightIcon} />{" "}
          {moment(picking.created)
            .utc()
            .format("L") +
            " " +
            moment(picking.created)
              .utc()
              .format("LT")}
        </Grid>
        <Grid item className={classes.light} xs>
          <UserIcon className={classes.lightIcon} /> {picking.username}
        </Grid>
        <Grid item className={clsx(classes.light, classes.btns)} xs>
          <IconButton size="small" onClick={() => history.push("/picking/" + picking._id!.toHexString())}>
            <ViewIcon />
          </IconButton>
        </Grid>
      </Grid>
    );
  }
);

export default PickingListRow;
