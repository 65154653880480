import * as React from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Divider,
} from "@material-ui/core";
import { SyncEventConfigComponent } from "./SyncEvents";
import { db, utils } from "pickup-lib";
import ActionConfig from "./ActionConfig";
import { OrderStateLabel } from "pickup-lib/dist/src/db";

function validateConfig(config: db.SyncEventOrderArchiveConfig) {
  return (
    Boolean(config.type) &&
    Boolean(config.action) &&
    Boolean(config.action.type) &&
    config.archive !== undefined &&
    config.archive !== null
  );
}

const EventOrderArchiveConfig: SyncEventConfigComponent<db.SyncEventOrderArchiveConfig> = ({
  config,
  onChange,
}) => {
  const archive =
    config.archive === undefined ? "" : config.archive ? "true" : "false";
  const from =
    config.from === undefined || config.from === null ? "" : config.from;
  const action: db.SyncActionConfig =
    config.action === undefined ? { type: "" } : config.action;

  if (!onChange) {
    return (
      <>
        <span>
          Lorsque la commande est{" "}
          <strong>
            {archive ? "mise en archive" : "retirée des archives"}
          </strong>
        </span>
        {from !== "" && (
          <span>
            {" "}
            et uniquement si le statut est{" "}
            <strong>
              {utils.Maps.valueByKey(OrderStateLabel, from, "???")}
            </strong>
          </span>
        )}
        <ActionConfig config={action} />
      </>
    );
  }

  function handleSelectChange(
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) {
    const prop = event.target.name!;
    let value: number | undefined = event.target.value as number;
    if (value === -1) {
      value = undefined;
    }
    const newconfig = { ...config, [prop]: value };
    if (onChange) {
      onChange(newconfig, validateConfig(newconfig));
    }
  }

  function handleArchiveSelectChange(
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) {
    let value = event.target.value;
    const newconfig = { ...config, archive: value === "true" };
    if (onChange) {
      onChange(newconfig, validateConfig(newconfig));
    }
  }

  function handleActionChange(action: db.SyncActionConfig, valid: boolean) {
    const newconfig = { ...config, action };
    if (onChange) {
      onChange(newconfig, valid && validateConfig(newconfig));
    }
  }

  return (
    <>
      <FormControl fullWidth required>
        <InputLabel>Lorsque la commande est </InputLabel>
        <Select
          name="archive"
          value={archive}
          onChange={handleArchiveSelectChange}
        >
          <MenuItem value="true">Mise en archive</MenuItem>
          <MenuItem value="false">Retirée des archives</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel>Uniquement si le statut est</InputLabel>
        <Select name="from" value={from} onChange={handleSelectChange}>
          <MenuItem value={-1}>
            <em>Sans importance</em>
          </MenuItem>
          {Array.from(OrderStateLabel).map(([k, v]) => (
            <MenuItem key={k} value={k}>
              {v}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Divider />
      <ActionConfig config={action} onChange={handleActionChange} />
    </>
  );
};

export default EventOrderArchiveConfig;
