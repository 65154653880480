import * as React from "react";
import {
  createStyles,
  Theme,
  makeStyles,
  TableCell,
  TableRow,
  TextField,
} from "@material-ui/core";
import { db, utils } from "pickup-lib";
import { CielValue } from "./Ciel";
import ErrorText from "../components/ErrorText";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    warn: {
      fontSize: 12,
      fontFamily: '"Courier New", monospace',
    },
    hl: {
      fontSize: 12,
      fontFamily: '"Courier New", monospace',
      lineHeight: 1.7,
      whiteSpace: "nowrap",
    },
  })
);

type ChangeCallback = (id: string, value: string) => void;

interface Props {
  item: db.Item;
  value: CielValue;
  error?: string;
  onChange: ChangeCallback;
}

const CielRow: React.FunctionComponent<Props> = React.memo(
  ({ item, value, error, onChange }) => {
    const classes = useStyles();

    function renderCapacity(qty: number, capacity?: number) {
      return capacity ? (
        <div className={classes.hl}>{(qty * capacity) / 100000} hl</div>
      ) : null;
    }

    const handleSuspChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      onChange(item._id.toHexString(), value);
    };

    return (
      <TableRow>
        <TableCell>
          <div>{item.ref}</div>
          <div>{item.label}</div>
          {error && (
            <div className={classes.warn}>
              <ErrorText text={error} />
            </div>
          )}
          {!item.capacity && !error && (
            <div className={classes.warn}>
              <ErrorText text="Contentance non définie" />
            </div>
          )}
        </TableCell>
        <TableCell align="right">
          <div>{value.stockStart}</div>
          <div>{renderCapacity(value.stockStart, item.capacity)}</div>
        </TableCell>
        <TableCell align="right">
          <div>{value.stockIn}</div>
          <div>{renderCapacity(value.stockIn, item.capacity)}</div>
        </TableCell>
        <TableCell align="right">
          <div>{value.stockOutAcq}</div>
          <div>{renderCapacity(value.stockOutAcq, item.capacity)}</div>
        </TableCell>
        <TableCell align="right">
          <div>
            <TextField value={value.stockOutSusp} onChange={handleSuspChange} />
          </div>
          <div>
            {renderCapacity(
              utils.String.parseNumber(value.stockOutSusp, 0),
              item.capacity
            )}
          </div>
        </TableCell>
        <TableCell align="right">
          <div>{value.stockEnd}</div>
          <div>{renderCapacity(value.stockEnd, item.capacity)}</div>
        </TableCell>
      </TableRow>
    );
  }
);

export default CielRow;
