import * as React from "react";
import {
  createStyles,
  Theme,
  makeStyles,
  Button,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btns: { width: "100%", maxWidth: 600, textAlign: "right" },
  })
);

interface Props {
  onChange: (config: object) => void;
}

const StepToropShop: React.FunctionComponent<Props> = ({ onChange }) => {
  const classes = useStyles();

  const onContinueClick = () => {
    onChange({});
  };

  return (
    <>
      <div>Pas de configuration, cliquez sur continuer.</div>
      <div className={classes.btns}>
        <Button variant="contained" color="primary" onClick={onContinueClick}>
          Continuer
        </Button>
      </div>
    </>
  );
};

export default StepToropShop;
