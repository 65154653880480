import { db } from "pickup-lib";

export interface ItemValue {
  label: string;
  multiplier: number;
  count: number | string;
}

export function calcTotal(
  value: ItemValue[],
  type: db.DouaneType,
  initialStock: number
): number {
  const total = value.reduce<number | undefined>(
    (prev, cur) => {
      if (typeof cur.count !== "string") {
        return (prev ? prev : 0) + cur.count * cur.multiplier;
      }
      return prev;
    },
    type === db.DouaneType.FULL ? undefined : initialStock
  );
  return total !== undefined ? total : initialStock;
}
