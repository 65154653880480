import * as React from "react";
import {
  createStyles,
  Theme,
  makeStyles,
  Grid,
  MenuItem,
  Select,
} from "@material-ui/core";
import { db } from "pickup-lib";
import AppContext from "AppContext";
import RelaisId from "./RelaisId";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: "1 1 100px",
      fontSize: 12,
      lineHeight: 1.5,
      color: "rgba(0,0,0,0.54)",
      padding: theme.spacing(1),
    },
    light: {
      whiteSpace: "nowrap",
      color: "rgb(90,90,90)",
    },
    lightIcon: {
      fontSize: 18,
      verticalAlign: "text-bottom",
      color: "rgb(200,200,200)",
    },
    relaisId: {
      float: "right",
    },
  })
);

export type OnCarrierChangeCallback = (id: string, value: string) => void;

interface Props {
  order: db.Order;
  onCarrierChange: OnCarrierChangeCallback;
}

const ShippingCell: React.FunctionComponent<Props> = React.memo(
  ({ order, onCarrierChange }) => {
    const { org } = React.useContext(AppContext);
    const relaisIdRef = React.useRef(null);
    const classes = useStyles();

    const handleChange = React.useCallback(
      (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        const value = event.target.value as string;
        onCarrierChange(order._id!.toHexString(), value);
      },
      [order._id, onCarrierChange]
    );

    return (
      <Grid item className={classes.root}>
        <div>
          <span className={classes.light} ref={relaisIdRef}>
            {order.livraison!.modeLabel}
          </span>
          {order.livraison!.relaisId && (
            <RelaisId
              className={clsx(classes.light, classes.relaisId)}
              id={order.livraison!.relaisId}
              address={order.livraison!.relaisAdr}
            />
          )}
        </div>
        {order.internal && (
          <div>
            <Select value={order.internal.carrier} onChange={handleChange}>
              {org!.config.carriers.map((carrier) => {
                return (
                  <MenuItem key={carrier.key} value={carrier.key}>
                    {carrier.label}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
        )}
      </Grid>
    );
  }
);

export default ShippingCell;
