import * as React from "react";
import {
  Theme,
  makeStyles,
  createStyles,
  Avatar,
  Box,
} from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    avatar: {
      margin: theme.spacing(1),
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
    },
    avatar1: { opacity: 0.8, transform: "scale(0.9)" },
    avatar2: { opacity: 0.6, transform: "scale(0.8)" },
    avatar3: { opacity: 0.4, transform: "scale(0.7)" },
    avatar4: { opacity: 0.4, transform: "scale(0.6)" },
    avatar5: { opacity: 0.4, transform: "scale(0.5)" },
  })
);

interface Props {
  value: string;
  separator?: string;
}

const PlaceAvatar: React.FunctionComponent<Props> = React.memo(
  ({ value, separator }) => {
    if (value.startsWith("__UNDEFINED")) {
      value = value === "__UNDEFINED_PLACE" ? "?" : "??";
    }
    separator = separator ? separator : "-";
    const parts = value.split("").reduce<string[]>(
      (prev, cur) => {
        if (prev[prev.length - 1].length >= 2) {
          prev.push("");
        }
        prev[prev.length - 1] += cur;
        return prev;
      },
      [""]
    );
    const classes = useStyles();

    function avatarClass(i: number) {
      switch (i) {
        case 1:
          return classes.avatar1;
        case 2:
          return classes.avatar2;
        case 3:
          return classes.avatar3;
        case 4:
          return classes.avatar4;
        case 5:
          return classes.avatar5;
      }
    }

    return (
      <Box display="flex">
        {parts.map((v, i) => (
          <Avatar key={i} className={clsx(classes.avatar, avatarClass(i))}>
            {v}
          </Avatar>
        ))}
      </Box>
    );
  }
);

export default PlaceAvatar;
