import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
} from "@material-ui/core";
import * as React from "react";
import { io } from "pickup-lib";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      overflow: "auto",
    },
    select: {
      minWidth: 100,
    },
    footer: {
      textAlign: "right",
    },
  });

interface Props extends WithStyles<typeof styles> {
  data?: io.Preview;
}

class PreviewTable extends React.Component<Props> {
  public render() {
    if (!this.props.data) {
      return null;
    }
    const { classes, data } = this.props;
    return (
      <div className={classes.root}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {data.headers.map((v, i) => (
                <TableCell key={i}>{v}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.head.map((row, ri) => (
              <TableRow key={ri}>
                {row.map((v, i) => (
                  <TableCell key={i}>{v}</TableCell>
                ))}
              </TableRow>
            ))}
            <TableRow key="separator">
              <TableCell colSpan={data.headers.length}>(...)</TableCell>
            </TableRow>
            {data.tail.map((row, ri) => (
              <TableRow key={ri + 100}>
                {row.map((v, i) => (
                  <TableCell key={i}>{v}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={data.headers.length} align="right">
                {data.count} lignes lues.
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    );
  }
}

export default withStyles(styles)(PreviewTable);
