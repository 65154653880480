import * as React from "react";
import {
  createStyles,
  Theme,
  makeStyles,
  Typography,
  IconButton,
  Card,
  CardHeader,
  CardContent,
  Divider,
  CardActions,
  Fab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  CircularProgress,
} from "@material-ui/core";
import {
  ArrowBack as BackIcon,
  Add as AddIcon,
  Save as SaveIcon,
  Edit as EditIcon,
} from "@material-ui/icons";
import AppContext from "../AppContext";
import { useErrorHandler } from "../ErrorHandler";
import useRouter from "use-react-router";
import { db } from "pickup-lib";
import LoaderInline from "./components/LoaderInline";
import EventConfig from "./sync/EventConfig";
import update from "immutability-helper";
import SyncTypes from "./sync/SyncTypes";
import { SyncContext } from "./sync/SyncContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 700,
      margin: "auto",
    },
    input: { width: "100%", maxWidth: 600, marginTop: 5, marginBottom: 5 },
    actions: { justifyContent: "right" },
    dialogPaper: {
      minWidth: 600,
    },
    wrapper: {
      margin: theme.spacing(1),
      position: "relative",
    },
    fabProgress: {
      color: theme.palette.primary.main,
      position: "absolute",
      top: -6,
      left: -6,
      zIndex: 1,
    },
  })
);

interface Params {
  id: string;
}

const SyncConfig: React.FunctionComponent<{}> = () => {
  const [sync, setSync] = React.useState<db.Sync | null>(null);
  const [saveLoading, setSaveLoading] = React.useState(false);
  const [config, setConfig] = React.useState<db.SyncEventConfig>();
  const [configIndex, setConfigIndex] = React.useState<number>();
  const [configValid, setConfigValid] = React.useState(false);
  const { repos } = React.useContext(AppContext);
  const { history, match } = useRouter<Params>();
  const errorHandler = useErrorHandler();
  const classes = useStyles();

  React.useEffect(() => {
    if (match.params.id) {
      repos!
        .getSyncs()
        .get(match.params.id)
        .then(s => {
          if (!s) {
            return Promise.reject("Synchronisation inconnue");
          }
          setSync(s);
        })
        .catch(errorHandler);
    } else {
      setSync(null);
    }
  }, [repos, match.params.id, errorHandler]);

  function unsetConfig() {
    setConfig(undefined);
    setConfigIndex(undefined);
    setConfigValid(false);
  }

  function saveCurrentConfig() {
    setSync(c => {
      if (c) {
        return update(c, {
          events: c.events
            ? configIndex !== undefined
              ? { [configIndex]: { $set: config! } }
              : { $push: [config!] }
            : { $set: [config!] },
        });
      }
      return c;
    });
    unsetConfig();
  }

  function deleteCurrentConfig() {
    setSync(c => {
      if (c && configIndex !== undefined) {
        return update(c, {
          events: { $splice: [[configIndex, 1]] },
        });
      }
      return c;
    });
    unsetConfig();
  }

  function handelSaveClick() {
    if (sync) {
      setSaveLoading(true);
      repos!
        .getSyncs()
        .updateEvents(sync._id!, sync.events)
        .then(() => {
          history.push("/syncs");
        })
        .catch(err => {
          errorHandler(err);
          setSaveLoading(false);
        });
    }
  }

  const handleEdit = React.useCallback(
    (index?: number) => {
      if (sync) {
        setConfigIndex(index);
        if (index === undefined) {
          setConfig({
            type: "",
            action: { type: "" },
          });
        } else {
          setConfig(sync.events[index]);
        }
      }
    },
    [sync]
  );

  if (!sync) {
    return <LoaderInline />;
  }

  const syncType = SyncTypes.get(sync.type)!;
  return (
    <SyncContext.Provider value={{ type: syncType }}>
      <Typography variant="h2">
        <IconButton onClick={() => history.push("/syncs")}>
          <BackIcon />
        </IconButton>{" "}
        Configuration synchronisation : {sync.label}
      </Typography>
      <Card className={classes.root}>
        <CardHeader title="Événements" />
        <Divider />
        <CardContent>
          <List>
            {(!sync.events || sync.events.length === 0) && (
              <ListItem>
                <ListItemText>Aucun événement</ListItemText>
              </ListItem>
            )}
            {sync.events &&
              sync.events.map((event, idx) => (
                <ListItem key={idx}>
                  <ListItemText>
                    <EventConfig config={event} />
                  </ListItemText>
                  <ListItemSecondaryAction>
                    <IconButton
                      onClick={() => {
                        setConfig(event);
                        setConfigIndex(idx);
                        setConfigValid(true);
                      }}
                      edge="end"
                      aria-label="modifier"
                    >
                      <EditIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
          </List>
        </CardContent>
        <Divider />
        <CardActions className={classes.actions}>
          <Fab
            color="primary"
            size="small"
            onClick={() => {
              handleEdit();
            }}
            aria-label="Ajouter"
          >
            <AddIcon />
          </Fab>
          <div className={classes.wrapper}>
            <Fab
              color="primary"
              onClick={handelSaveClick}
              aria-label="Enregistrer"
            >
              <SaveIcon />
            </Fab>
            {saveLoading && (
              <CircularProgress size={68} className={classes.fabProgress} />
            )}
          </div>
        </CardActions>
      </Card>
      <Dialog open={Boolean(config)} classes={{ paper: classes.dialogPaper }}>
        <DialogTitle>
          {configIndex === undefined ? "Ajouter" : "Modifier"} un événement
        </DialogTitle>
        <DialogContent>
          {config && (
            <EventConfig
              config={config}
              onChange={(config, valid) => {
                setConfig(config);
                setConfigValid(valid);
              }}
            />
          )}
        </DialogContent>
        <DialogActions>
          {configIndex !== undefined && (
            <Button onClick={deleteCurrentConfig} color="secondary">
              Supprimer
            </Button>
          )}
          <Button onClick={unsetConfig}>Annuler</Button>
          <Button
            onClick={saveCurrentConfig}
            variant="contained"
            color="primary"
            disabled={!configValid}
          >
            Valider
          </Button>
        </DialogActions>
      </Dialog>
    </SyncContext.Provider>
  );
};

export default SyncConfig;
