import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core";
import * as React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import TopBar from "./components/TopBar";
import Drawer from "./components/Drawer";
import OrderList from "./OrderList";
import ItemList from "./ItemList";
import ItemEdit from "./ItemEdit";
import { IAuth } from "pickup-lib";
import Import from "./Import";
import Config from "./Config";
import DouaneList from "./DouaneList";
import DouaneEdit from "./DouaneEdit";
import DouaneDeclaration from "./DouaneDeclaration";
import UserList from "./UserList";
import ArchiveList from "./ArchiveList";
import Picking from "./Picking";
import PickingList from "./PickingList";
import SyncList from "./SyncList";
import Sync from "./Sync";
import SyncConfig from "./SyncConfig";

const styles = (theme: Theme) =>
  createStyles({
    main: {
      display: "flex",
      width: "auto",
      height: "100%",
    },
    content: {
      minWidth: 0,
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(2),
      paddingTop: theme.spacing(2) + 65,
    },
  });

interface Props extends WithStyles<typeof styles> {
  auth: IAuth;
}

class Main extends React.Component<Props> {
  public render() {
    const classes = this.props.classes;
    return (
      <div className={classes.main}>
        <Router>
          <Drawer />
          <TopBar />
          <div className={classes.content}>
            <Route path="/orders/:id?" exact component={OrderList} />
            <Route path="/archives/:id?" exact component={ArchiveList} />
            <Route path="/items" exact component={ItemList} />
            <Route path="/item/:id?" exact component={ItemEdit} />
            <Route path="/import" exact component={Import} />
            <Route path="/config" exact component={Config} />
            <Route path="/douanes" exact component={DouaneList} />
            <Route path="/douane/:id?" exact component={DouaneEdit} />
            <Route path="/declaration/:id?" exact component={DouaneDeclaration} />
            <Route path="/users" exact component={UserList} />
            <Route path="/pickings" exact component={PickingList} />
            <Route path="/picking/:id" exact component={Picking} />
            <Route path="/syncs" exact component={SyncList} />
            <Route path="/sync" exact component={Sync} />
            <Route path="/sync/:id" exact component={SyncConfig} />
          </div>
        </Router>
      </div>
    );
  }
}

export default withStyles(styles)(Main);
