import * as React from "react";
import {
  createStyles,
  Paper,
  Theme,
  Fab,
  makeStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from "@material-ui/core";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Visibility as ViewIcon,
  Delete as DeleteIcon,
} from "@material-ui/icons";
import LoaderRow from "./components/LoaderRow";
import { db } from "pickup-lib";
import AppContext from "../AppContext";
import Create from "./douanes/Create";
import useReactRouter from "use-react-router";
import { useErrorHandler } from "../ErrorHandler";
import { ObjectId } from "bson";
import DialogDelete from "./components/DialogDelete";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      overflowX: "auto",
    },
    table: {
      minWidth: 700,
    },
    fab: {
      position: "fixed",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  })
);

type DeleteData = {
  id: ObjectId;
  label: string;
};

const DouaneList: React.FunctionComponent = () => {
  const [loading, setLoading] = React.useState(true);
  const [inventories, setInventories] = React.useState<db.Douane[]>([]);
  const [create, setCreate] = React.useState(false);
  const [deleteData, setDeleteData] = React.useState<DeleteData>();
  const { history } = useReactRouter();
  const context = React.useContext(AppContext);
  const classes = useStyles();
  const errorHandler = useErrorHandler();

  const loadData = React.useCallback(() => {
    setLoading(true);
    context
      .repos!.getDouanes()
      .list({ created: -1 })
      .then(inventories => {
        setInventories(inventories);
        setLoading(false);
      })
      .catch(errorHandler);
  }, [context.repos, errorHandler]);

  React.useEffect(() => {
    loadData();
  }, [loadData]);

  function handleClose(id?: ObjectId, type?: db.DouaneType) {
    if (id) {
      history.push(
        `/${
          type === db.DouaneType.DRM ? "declaration" : "douane"
        }/${id.toHexString()}`
      );
    } else {
      setCreate(false);
    }
  }

  const handleDelete = React.useCallback(() => {
    if (deleteData) {
      context
        .repos!.getDouanes()
        .delete(deleteData.id)
        .then(() => {
          setDeleteData(undefined);
          loadData();
        })
        .catch(e => {
          setDeleteData(undefined);
          errorHandler(e);
        });
    }
  }, [context.repos, deleteData, errorHandler, loadData]);

  return (
    <Paper className={classes.root} elevation={1}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Date création</TableCell>
            <TableCell>Libellé</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <LoaderRow
            show={loading}
            message="Chargement des données..."
            cols={5}
          />
          {inventories.map(inv => (
            <TableRow key={inv._id!.toHexString()}>
              <TableCell>{inv.created.toLocaleDateString()}</TableCell>
              <TableCell>{inv.label}</TableCell>
              <TableCell>{db.DouaneTypeLabel.get(inv.type)}</TableCell>
              <TableCell align="right">
                {!inv.next && (
                  <IconButton color="secondary"
                    onClick={() =>
                      setDeleteData({ id: inv._id!, label: inv.label })
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
                <IconButton
                  onClick={() =>
                    history.push(
                      `/${
                        inv.type === db.DouaneType.DRM
                          ? "declaration"
                          : "douane"
                      }/${inv._id.toHexString()}`
                    )
                  }
                >
                  {inv.next ? <ViewIcon /> : <EditIcon />}
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Fab
        color="secondary"
        className={classes.fab}
        onClick={() => setCreate(true)}
      >
        <AddIcon />
      </Fab>
      {create && <Create onClose={handleClose} />}
      {deleteData && (
        <DialogDelete
          onCancel={() => setDeleteData(undefined)}
          onConfirm={handleDelete}
        >
          {deleteData.label}
        </DialogDelete>
      )}
    </Paper>
  );
};

export default DouaneList;
