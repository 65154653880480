import * as React from "react";
import {
  Fab,
  TextField,
} from "@material-ui/core";
import { Save as SaveIcon } from "@material-ui/icons";
import { OrgConfigDouaneContext } from "../../Contexts";
import AppContext from "../../AppContext";
import CardFlex from "screens/components/CardFlex";

interface Props {
  className?: string;
}

const Douane: React.FC<Props> = ({ className }) => {
  const [loading, setLoading] = React.useState(false);
  const [numeroAccise, setNumeroAccise] = React.useState("");
  const contextConfig = React.useContext(OrgConfigDouaneContext);
  const context = React.useContext(AppContext);

  React.useEffect(() => {
    setNumeroAccise(
      contextConfig.numeroAccise ? contextConfig.numeroAccise : ""
    );
  }, [contextConfig]);

  function handleSave() {
    setLoading(true);
    context
      .repos!.getOrgs()
      .updateConfigDouane({ numeroAccise: numeroAccise })
      .then(() => {
        setLoading(false);
      });
  }

  function handleNumeroAcciseChange(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    setNumeroAccise(event.target.value.trim().substr(0, 13));
  }

  const actions = (
    <Fab
      color="primary"
      size="small"
      onClick={handleSave}
      aria-label="Enregistrer"
    >
      <SaveIcon />
    </Fab>
  );

  return (
    <CardFlex title="Douanes" actions={actions} loading={loading}>
      <TextField
        fullWidth
        label="Numéro d'accise"
        value={numeroAccise}
        onChange={handleNumeroAcciseChange}
      />
    </CardFlex>
  );
};

export default Douane;
