import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { deepOrange, teal } from "@material-ui/core/colors";
import "./App.css";
import Login from "./screens/public/Login";
import Forgot from "screens/public/Forgot";
import { CssBaseline } from "@material-ui/core";
import Main from "./screens/Main";
import { IAuth, stitch, Auth, db, utils } from "pickup-lib";
import AppContext from "./AppContext";
import {
  ItemTypesContext,
  OrgConfigDouaneContext,
  UserContext,
} from "./Contexts";
import ErrorHandler from "./ErrorHandler";
import { AuthObserverState } from "pickup-lib/dist/src/Auth";
import Loading from "Loading";
import { StylesProvider } from "@material-ui/styles";
import Reset from "screens/public/Reset";
import { useMessageHandler } from "MessageHandler";

const theme = createMuiTheme({
  palette: {
    primary: teal,
    secondary: deepOrange,
    background: {
      default: "#eceff1",
      paper: "#fff",
    },
  },
  overrides: {
    MuiTypography: {
      h2: {
        fontSize: 30,
      },
    },
  },
});

const App: React.FunctionComponent<{}> = () => {
  const [isReady, setIsReady] = React.useState(false);
  const [auth, setAuth] = React.useState<IAuth>();
  const [repos, setRepos] = React.useState<db.Repos>();
  const [org, setOrg] = React.useState<db.Org>();
  const [proxy, setProxy] = React.useState<stitch.StitchProxy>();
  const [user, setUser] = React.useState<db.User>();
  const [types, setTypes] = React.useState<db.ItemType[]>([]);
  const [configDouane, setConfigDouane] = React.useState<db.ConfigDouane>({});
  const msgHandler = useMessageHandler(["user", "org"]);

  React.useEffect(() => {
    const handleOrgMessage = (msg: db.Message<db.OrgMessage>) => {
      if (repos && msg.data.operation === "update") {
        repos
          .getOrgs()
          .getCurrent()
          .then(org => setOrg(org));
      }
    };
    const handleUserMessage = (msg: db.Message<db.UserMessage>) => {
      if (
        repos &&
        msg.data.operation === "update" &&
        user &&
        msg.data.id.toHexString() === user._id!.toHexString()
      ) {
        repos
          .getUsers()
          .get(user._id!)
          .then(user => setUser(user || undefined));
      }
    };

    msgHandler.on("org", handleOrgMessage);
    msgHandler.on("user", handleUserMessage);
    return () => {
      msgHandler.off("org", handleOrgMessage);
      msgHandler.off("user", handleUserMessage);
    };
  }, [msgHandler, repos, user]);

  React.useEffect(() => {
    let authObserver: utils.Unsubscribe | undefined = undefined;
    const initPromise = utils.CancelablePromise.makeCancelable(
      stitch.StitchProxy.init(
        "browser",
        process.env.REACT_APP_PROD_BUILD === "1"
          ? "pickup-urark"
          : "pickup-dev-kynuk",
        "pickup-cluster",
        process.env.REACT_APP_PROD_BUILD === "1" ? "pickup" : "pickup-dev"
      )
    );
    initPromise
      .then(proxy => {
        console.log("Stitch ready on", proxy.db.name);
        const auth = new Auth(proxy);
        const repos = new db.Repos(proxy, auth);
        setProxy(proxy);
        setAuth(auth);
        setRepos(repos);
        authObserver = auth.onStateChanged(event => {
          if (event.state === AuthObserverState.UNKNOWN) {
            setIsReady(false);
            setUser(undefined);
            setOrg(undefined);
            setConfigDouane({});
          } else if (event.state === AuthObserverState.CONNECTING) {
            setIsReady(false);
            setUser(undefined);
            setOrg(undefined);
            setConfigDouane({});
          } else if (event.state === AuthObserverState.DISCONNECTED) {
            setIsReady(true);
            setUser(undefined);
            setOrg(undefined);
            setConfigDouane({});
          } else if (event.state === AuthObserverState.CONNECTED) {
            setIsReady(false);
            setUser(undefined);
            setOrg(undefined);
            repos
              .getUsers()
              .get(event.user!.id)
              .then(user => {
                setUser(user || undefined);
                repos
                  .getOrgs()
                  .getCurrent()
                  .then(org => {
                    setOrg(org);
                    setConfigDouane(org!.config.douane);
                    setIsReady(true);
                  });
                repos
                  .getItemTypes()
                  .list()
                  .then(types => {
                    setTypes(types);
                  });
              });
          }
        });
      })
      .catch(r => {
        if (utils.CancelablePromise.isCanceled(r)) {
          return;
        }
      });
    return () => {
      initPromise.cancel();
      if (authObserver) {
        authObserver();
      }
    };
  }, []);

  let screen = <div />;
  if (auth && isReady) {
    if (user) {
      screen = <Main auth={auth!} />;
    } else {
      screen = <Login auth={auth!} />;
    }
  } else {
    screen = <Loading></Loading>;
  }

  return (
    <StylesProvider>
      <AppContext.Provider
        value={{
          auth,
          repos,
          org,
          proxy,
        }}
      >
        <ItemTypesContext.Provider value={{ types }}>
          <UserContext.Provider
            value={{
              org: org || new db.Org(),
              user: user || new db.User(),
            }}
          >
            <OrgConfigDouaneContext.Provider value={configDouane}>
              <MuiThemeProvider theme={theme}>
                <CssBaseline />
                <ErrorHandler>
                  <div className="App">
                    <Router>
                      <Switch>
                        <Route exact path="/passwordLost">
                          <Forgot auth={auth!} />
                        </Route>
                        <Route exact path="/resetPassword">
                          <Reset auth={auth!} />
                        </Route>
                        <Route>{screen}</Route>
                      </Switch>
                    </Router>
                  </div>
                </ErrorHandler>
              </MuiThemeProvider>
            </OrgConfigDouaneContext.Provider>
          </UserContext.Provider>
        </ItemTypesContext.Provider>
      </AppContext.Provider>
    </StylesProvider>
  );
};

export default App;
