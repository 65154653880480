import * as React from "react";
import {
  createStyles,
  Theme,
  makeStyles,
  TextField,
  Button,
} from "@material-ui/core";
import AppContext from "../../AppContext";
import { utils } from "pickup-lib";
import SnackbarStatic from "screens/components/SnackbarStatic";
import Loader from "screens/components/Loader";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    input: { width: "100%", maxWidth: 600, marginTop: 5, marginBottom: 5 },
    btns: { width: "100%", maxWidth: 600, textAlign: "right" },
  })
);

type OnChangeCallback = (config: object) => void;

interface Props {
  onChange: OnChangeCallback;
}

const StepPrestashop: React.FunctionComponent<Props> = ({ onChange }) => {
  const [loading, setLoading] = React.useState("");
  const [error, setError] = React.useState("");
  const [url, setUrl] = React.useState("");
  const [key, setKey] = React.useState("");
  const { repos } = React.useContext(AppContext);
  const classes = useStyles();

  const onContinueClick = () => {
    let finalurl = url;
    if (!(finalurl.startsWith("http://") || finalurl.startsWith("https://"))) {
      finalurl = "https://" + finalurl;
      setUrl(finalurl);
    }
    setLoading("Connexion à " + finalurl);
    setError("");
    repos!
      .getSyncs()
      .prestashopGuessConfig(finalurl, key)
      .then(r => {
        setLoading("");
        onChange({ ...r, key });
      })
      .catch(err => {
        setLoading("");
        setError(
          "Impossible de connecter votre site, veuillez verifier les informations"
        );
      });
  };

  return (
    <>
      <Loader show={Boolean(loading)} message={loading}>
        <div>
          <TextField
            label="Adresse du site (URL)"
            value={url}
            className={classes.input}
            onChange={utils.Form.handleChange(setUrl)}
            margin="normal"
          />
        </div>
        <div>
          <TextField
            label="Clé API"
            value={key}
            className={classes.input}
            onChange={utils.Form.handleChange(setKey)}
            margin="normal"
          />
        </div>
        {error && <SnackbarStatic variant="error">{error}</SnackbarStatic>}
        <div className={classes.btns}>
          <Button variant="contained" color="primary" onClick={onContinueClick}>
            Continuer
          </Button>
        </div>
      </Loader>
    </>
  );
};

export default StepPrestashop;
