import * as React from "react";
import {
  createStyles,
  Theme,
  makeStyles,
  Grid,
  Checkbox,
  IconButton,
} from "@material-ui/core";
import {
  CloudDownload as LoadingIcon,
  CloudDone as LoadedIcon,
  ShoppingCart as PickedIcon,
  Help as DefaultIcon,
  Event as EventIcon,
  Face as UserIcon,
  List as ListIcon,
  EuroSymbol as EuroIcon,
  RemoveRedEye as ViewIcon,
} from "@material-ui/icons";
import { db } from "pickup-lib";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import clsx from "clsx";
import moment from "moment";
import "moment/locale/fr";
import useRouter from "use-react-router";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 42,
      borderBottom: "1px solid rgba(224,224,224,1)",
    },
    state: {
      fontSize: 12,
      lineHeight: 1.5,
      color: "rgba(0,0,0,0.54)",
      padding: 0,
      borderRight: "1px solid rgba(224,224,224,1)",
      borderLeft: "4px solid #FFFFFF",
      "&:last-child": {
        paddingRight: 0,
      },
    },
    stateContent: {
      display: "flex",
      width: "100%",
      cursor: "pointer",
      fontSize: 14,
      textTransform: "uppercase",
      fontWeight: "bold",
    },
    stateText: {
      flexGrow: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    stateColor0: {
      borderLeft: "4px solid #9e9e9e",
    },
    stateColor10: {
      borderLeft: "4px solid #618833",
    },
    stateColor20: {
      borderLeft: "4px solid #ffa500",
    },
    stateColorBg0: {
      backgroundColor: "#ececec",
    },
    stateColorBg10: {
      backgroundColor: "#f1f7ea",
    },
    stateColorBg20: {
      backgroundColor: "#fff8eb",
    },
    stateCheckbox: {
      float: "left",
    },
    stateIcon: {
      flexGrow: 0,
      verticalAlign: "bottom",
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(0.5),
      height: "auto",
      opacity: 0.7,
    },
    light: {
      color: "rgb(90,90,90)",
      whiteSpace: "nowrap",
      fontSize: 12,
      fontFamily: '"Courier New", monospace',
      margin: theme.spacing(1),
    },
    lightIcon: {
      fontSize: 18,
      verticalAlign: "text-bottom",
      color: "rgb(200,200,200)",
    },
    societe: {
      fontWeight: "bold",
      color: "rgb(100,100,100)",
    },
    btns: {
      textAlign: "right",
      overflow: "hidden",
      margin: theme.spacing(0.5),
    },
  })
);

type OnChangeCallback = (id: string, selected: boolean) => void;

interface Props {
  order: db.Order;
  selected: boolean;
  onChange: OnChangeCallback;
}

const stateIcons: { [k: number]: React.ComponentType<SvgIconProps> } = {
  0: LoadingIcon,
  10: LoadedIcon,
  20: PickedIcon,
};

const ArchiveRow: React.FunctionComponent<Props> = React.memo(
  ({ order, onChange, selected }) => {
    const { history } = useRouter();
    const classes = useStyles();

    const stateColors: { [k: number]: string } = {
      0: classes.stateColor0,
      10: classes.stateColor10,
      20: classes.stateColor20,
    };
    const stateColorsBg: { [k: number]: string } = {
      0: classes.stateColorBg0,
      10: classes.stateColorBg10,
      20: classes.stateColorBg20,
    };

    const stateClass = stateColors[order.state]
      ? stateColors[order.state]
      : stateColors[0];
    const stateClassBg = stateColorsBg[order.state]
      ? stateColorsBg[order.state]
      : stateColorsBg[0];
    const stateIcon = React.createElement(
      stateIcons[order.state] ? stateIcons[order.state] : DefaultIcon,
      {
        className: classes.stateIcon,
      }
    );
    const livraison = order.livraison!;

    const handleViewClick = function() {
      history.push("/archives/" + order._id!.toHexString());
    };

    return (
      <Grid container className={classes.root}>
        <Grid item className={clsx(classes.state, stateClass)} xs>
          <div className={clsx(stateClassBg)}>
            <label className={classes.stateContent}>
              <Checkbox
                color="default"
                checked={selected}
                className={classes.stateCheckbox}
                onChange={() => {
                  if (onChange) {
                    onChange(order._id!.toHexString(), !selected);
                  }
                }}
              />
              <span className={classes.stateText}>{order.ref}</span>
              {stateIcon}
            </label>
          </div>
        </Grid>
        <Grid item className={classes.light} xs>
          <EventIcon className={classes.lightIcon} />{" "}
          {moment(order.date)
            .utc()
            .format("L") +
            " " +
            moment(order.date)
              .utc()
              .format("LT")}
        </Grid>
        <Grid item className={classes.light} xs>
          <UserIcon className={classes.lightIcon} /> {livraison.nom}{" "}
          {livraison.prenom}
          {livraison.societe && (
            <span className={classes.societe}> / {livraison.societe}</span>
          )}
        </Grid>
        <Grid item className={classes.light} xs>
          {order.internal && (
            <>
              <ListIcon className={classes.lightIcon} /> {order.internal.count}{" "}
              Articles ({order.internal.distinct}&ne;){" "}
              <EuroIcon className={classes.lightIcon} />{" "}
            </>
          )}
          {order.totalTtc.toFixed(2).replace(".", ",")}
        </Grid>
        <Grid item className={clsx(classes.light, classes.btns)} xs>
          <IconButton size="small" onClick={handleViewClick}>
            <ViewIcon />
          </IconButton>
        </Grid>
      </Grid>
    );
  }
);

export default ArchiveRow;
