import * as React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles, Theme, createStyles, WithStyles } from "@material-ui/core";
import classNames from "classnames";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      textAlign: "center",
    },
    message: {
      verticalAlign: "65%",
      marginLeft: 10,
    },
  });

interface Props extends WithStyles<typeof styles> {
  show?: boolean;
  message?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

const loaderInline = (props: Props) => {
  const style: React.CSSProperties = {};
  if (props.show === false) {
    style.display = "none";
  }
  return (
    <div
      className={classNames(props.classes.root, props.className)}
      style={{ ...style, ...props.style }}
    >
      <CircularProgress
        key="progress"
        color="secondary"
        style={{ marginBottom: 10 }}
        disableShrink={true}
      />
      {props.message && (
        <span className={props.classes.message}>{props.message}</span>
      )}
    </div>
  );
};

export default withStyles(styles)(loaderInline);
