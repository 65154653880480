import * as React from "react";
import {
  createStyles,
  Theme,
  makeStyles,
  TextField,
  InputAdornment,
  Hidden,
} from "@material-ui/core";
import { utils, db } from "pickup-lib";
import { ItemValue, calcTotal } from "./Douanes";
import PlaceAvatar from "screens/components/PlaceAvatar";

type ChangeCallback = (
  id: string,
  index: number,
  value: number | string
) => void;

interface Props {
  type: db.DouaneType;
  item: db.DouaneItem;
  value: ItemValue[];
  readonly: boolean;
  onChange: ChangeCallback;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      borderBottom: "1px solid #e0e0e0",
    },
    ref: {
      display: "block",
      fontFamily: '"Courier New", monospace',
      color: "rgb(90,90,90)",
    },
    item: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      padding: theme.spacing(2),
      flexGrow: 2,
      minWidth: 300,
    },
    fields: {
      display: "flex",
      padding: theme.spacing(1),
      width: 200,
      flexDirection: "column",
      flexShrink: 0,
    },
    field: {
      display: "flex",
    },
    add: {
      fontSize: "18pt",
      display: "flex",
      alignItems: "end",
      paddingRight: 4,
      width: 20,
    },
    result: {
      display: "flex",
      padding: theme.spacing(1),
      alignItems: "end",
      justifyContent: "center",
      width: 200,
      fontSize: "18pt",
      flexShrink: 0,
    },
    place: {
      display: "flex",
      alignItems: "center",
    },
  })
);

const EditRow: React.FunctionComponent<Props> = React.memo(
  ({ item, type, value, readonly, onChange }) => {
    const classes = useStyles();
    const id = item.id.toHexString();

    const handleChange = (index: number) => (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      if (event.target.value === "-") {
        onChange(id, index, "-");
      } else {
        const v = utils.String.parseNumber(event.target.value);
        onChange(id, index, v === undefined ? "" : v);
      }
    };

    const place = item.place || "__UNDEFINED";

    return (
      <div className={classes.root}>
        <Hidden smDown>
          <div className={classes.place}>
            <PlaceAvatar value={place} />
          </div>
        </Hidden>
        <div className={classes.item}>
          <div className={classes.ref}>{item.ref}</div>
          <div>{item.label}</div>
          <div>Stock précédent : {item.initialStock}</div>
        </div>
        <div className={classes.fields}>
          {value.map((p, i) => (
            <div key={i} className={classes.field}>
              <TextField
                value={p.count}
                label={p.label}
                onChange={handleChange(i)}
                // eslint-disable-next-line
                inputProps={{ pattern: "\\d*" }}
                // eslint-disable-next-line
                InputProps={{
                  readOnly: readonly,
                  disabled: readonly,
                  endAdornment:
                    p.multiplier > 1 ? (
                      <InputAdornment position="end">
                        x{p.multiplier}
                      </InputAdornment>
                    ) : null,
                }}
              />
            </div>
          ))}
        </div>
        <div className={classes.result}>
          {"= "}
          {calcTotal(value, type, item.initialStock)}
        </div>
      </div>
    );
  }
);

export default EditRow;
