import * as React from "react";
import _ from "lodash";
import { Paper, createStyles, Theme, makeStyles } from "@material-ui/core";
import {
  AddShoppingCart as PickupListIcon,
  Archive as ArchiveIcon,
  Print as PrintIcon,
  Cached as ReintegrateIcon,
  FileCopy as OpenProIcon,
} from "@material-ui/icons";
import { db } from "pickup-lib";
import AppContext from "../AppContext";
import { UserContext } from "Contexts";
import { useErrorHandler } from "../ErrorHandler";
import CreatePicking from "./orderlist/CreatePicking";
import { useMessageHandler } from "MessageHandler";
import OrderRow from "./orderlist/OrderRow";
import { FixedSizeList, areEqual, ListChildComponentProps } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import UpdateService from "./orderlist/UpdateService";
import useRouter from "use-react-router";
import { FilterMenuValue } from "./components/FilterMenu";
import OrderHeader, { SortValue } from "./orderlist/OrderHeader";
import { ObjectId } from "bson";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@material-ui/lab";
import OrderDetailsDialog from "./orderlist/OrderDetailsDialog";
import ExportOpenPro from "./orderlist/ExportOpenPro";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    content: {
      flexGrow: 1,
      overflowX: "auto",
      overflowY: "hidden",
    },
    footer: {
      padding: theme.spacing(1),
    },
    speeddial: {
      position: "absolute",
      bottom: theme.spacing(4),
      right: theme.spacing(4),
    },
  })
);

interface Params {
  id?: string;
}

function itemKey(index: number, data: any) {
  return data.orders[index]._id!.toHexString();
}

function notEmpty<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

function buildQuery(filter: FilterMenuValue) {
  const states: number[] = _.map(filter, function(v, k) {
    if (k.startsWith("state") && v) {
      return parseInt(k.substr(5));
    }
  }).filter(notEmpty);
  const qry: any = { archive: false, state: { $in: states } };
  if (filter.dateFrom) {
    qry.date = { $gte: filter.dateFrom };
  }
  if (filter.dateTo) {
    if (qry.date) {
      qry.date["$lte"] = filter.dateTo;
    } else {
      qry.date = { $lte: filter.dateTo };
    }
  }

  const stock: number[] = [];
  if (filter.disponible) {
    stock.push(db.OrderStock.InStock);
  }
  if (filter.reappro) {
    stock.push(db.OrderStock.Reappro);
  }
  if (filter.missing) {
    stock.push(db.OrderStock.Missing);
  }
  if (stock.length !== 3) {
    qry["internal.stock"] = { $in: stock };
  }

  return qry;
}

function buildSort(sort: SortValue) {
  const dir = sort.state === "asc" ? 1 : -1;
  switch (sort.key) {
    case "client":
      return { "livraison.nom": dir, "livraison.prenom": dir, date: dir };
    case "livraison":
      return { "livraison.modeLabel": dir, date: dir };
    default:
      return { date: dir };
  }
}

const Row = React.memo((props: ListChildComponentProps) => {
  const { index, style, data } = props;
  const order: db.Order = data.orders[index];
  const id: string = order._id!.toHexString();
  const selected: boolean = data.selected.indexOf(id) > -1;
  const loading: boolean = data.loadingOrders.indexOf(id) > -1;

  return (
    <div style={style}>
      <OrderRow
        order={order}
        selected={selected}
        loading={loading}
        onChange={data.handleChange}
        onCarrierChange={data.handleCarrierChange}
        onPrint={data.handlePrint}
      />
    </div>
  );
}, areEqual);

const OrderList: React.FunctionComponent<{}> = () => {
  const [loading, setLoading] = React.useState("Chargement des commandes...");
  const [speedDialOpen, setSpeedDialOpen] = React.useState(false);
  const [exportOrders, setExportOrders] = React.useState<db.Order[]>([]);
  const [orders, setOrders] = React.useState<db.Order[]>([]);
  const [order, setOrder] = React.useState<db.Order>();
  const [printOrders, setPrintOrders] = React.useState<db.Order[]>([]);
  const [selected, setSelected] = React.useState<string[]>([]);
  const [loadingOrders, setLoadingOrders] = React.useState<string[]>([]);
  const [pickuplist, setPickuplist] = React.useState<string[]>([]);
  const [filter, setFilter] = React.useState<FilterMenuValue>({
    state0: false,
    state10: true,
    state20: true,
    state30: true,
    state50: false,
    disponible: true,
    reappro: true,
    missing: true,
    date: null,
    dateFrom: null,
    dateTo: null,
  });
  const [sort, setSort] = React.useState<SortValue>({
    key: "date",
    state: "desc",
  });
  const [search, setSearch] = React.useState("");
  const [updateService, setUpdateService] = React.useState<UpdateService>();
  const { repos } = React.useContext(AppContext);
  const { org } = React.useContext(UserContext);
  const { match, history } = useRouter<Params>();
  const errorHandler = useErrorHandler();
  const classes = useStyles();
  const msg = useMessageHandler("order");

  const handleUpdate = React.useCallback((orders: db.Order[]) => {
    const updatedIds: string[] = [];
    setOrders(current => {
      orders.forEach(order => {
        const idx = current.findIndex(o => {
          return o._id!.toHexString() === order._id!.toHexString();
        });
        updatedIds.push(order._id!.toHexString());
        if (idx > -1) {
          current[idx] = order;
        }
      });
      return [...current];
    });
    if (updatedIds.length > 0) {
      setLoadingOrders(c => {
        return c.filter(v => updatedIds.indexOf(v) === -1);
      });
    }
  }, []);

  const handleMessage = React.useCallback(
    msg => {
      const id = msg.data.id.toHexString();
      setLoadingOrders(c => [...c, id]);
      if (updateService) {
        updateService.update(id);
      }
    },
    [updateService]
  );

  React.useEffect(() => {
    msg.on("order", handleMessage);
    return () => {
      msg.off("order", handleMessage);
    };
  }, [msg, handleMessage]);

  React.useEffect(() => {
    const idx = orders.findIndex(o => {
      return o._id!.toHexString() === match.params.id;
    });
    setOrder(idx > -1 ? orders[idx] : undefined);
  }, [match.params.id, orders]);

  const handleClosePickupList = React.useCallback(() => {
    setPickuplist([]);
  }, []);

  const handleSpeedDialClose = function(
    event: React.SyntheticEvent<{}>,
    reason: string
  ) {
    setSpeedDialOpen(false);
    if (reason === "toggle") {
      setPickuplist(selected);
    }
  };

  const handlePrintClick = React.useCallback(() => {
    setSpeedDialOpen(false);
    setPrintOrders(
      orders.filter(v => selected.indexOf(v._id!.toHexString()) > -1)
    );
  }, [orders, selected]);

  const handleOpenPro = React.useCallback(() => {
    setSpeedDialOpen(false);
    setExportOrders(
      orders.filter(v => selected.indexOf(v._id!.toHexString()) > -1)
    );
  }, [orders, selected]);

  const handleArchiveClick = React.useCallback(() => {
    setLoading(`Mise à jour de ${selected.length} commandes`);
    repos!
      .getOrders()
      .archive(selected)
      .then(() => {
        setOrders(c =>
          c.filter(v => selected.indexOf(v._id!.toHexString()) === -1)
        );
        setSelected([]);
      })
      .catch(errorHandler)
      .finally(() => setLoading(""));
  }, [errorHandler, repos, selected]);

  const handleReintegrateClick = React.useCallback(() => {
    setLoading(`Mise à jour de ${selected.length} commandes`);
    repos!
      .getOrders()
      .updateState(selected, 0)
      .then(() => {
        setSelected([]);
      })
      .catch(errorHandler)
      .finally(() => setLoading(""));
  }, [errorHandler, repos, selected]);

  const handleChange = React.useCallback((id: string, selected: boolean) => {
    if (selected) {
      setSelected(c => [...c, id]);
    } else {
      setSelected(c => c.filter(v => v !== id));
    }
  }, []);

  const handleCarrierChange = React.useCallback(
    (id: string, value: string) => {
      setLoadingOrders(c => [...c, id]);
      setOrders(current => {
        const idx = current.findIndex(o => {
          return o._id!.toHexString() === id;
        });
        if (current[idx].internal) {
          current[idx].internal!.carrier = value;
        }
        return [...current];
      });
      repos!.getOrders().updateCarrier(id, value);
    },
    [repos]
  );

  const handlePrint = React.useCallback(
    (id: ObjectId) => {
      const idx = orders.findIndex(o => {
        return o._id === id;
      });
      setPrintOrders(idx > -1 ? [orders[idx]] : []);
    },
    [orders]
  );

  const handleSelectAllChange = React.useCallback(
    (checked: boolean) => {
      setSelected(c => (checked ? orders.map(o => o._id!.toHexString()) : []));
    },
    [orders]
  );

  React.useEffect(() => {
    setLoading("Chargement des commandes...");
    repos!
      .getOrders()
      .find(buildQuery(filter), buildSort(sort))
      .then(result => {
        setOrders(result);
        setLoading("");
      })
      .catch(errorHandler);
  }, [repos, errorHandler, handleUpdate, filter, sort]);

  React.useEffect(() => {
    const svc = new UpdateService(repos!.getOrders(), handleUpdate);
    setUpdateService(svc);
    return () => {
      svc.dispose();
    };
  }, [repos, handleUpdate]);

  const data = React.useMemo(() => {
    return {
      orders:
        search.length > 0
          ? orders.filter(o => {
              const s = search.toLowerCase();
              return (
                o.ref.toLowerCase().indexOf(s) > -1 ||
                (o.sourceId && o.sourceId.toLowerCase().indexOf(s) > -1) ||
                o.livraison!.nom.toLowerCase().indexOf(s) > -1 ||
                (o.refClient && o.refClient.toLowerCase().indexOf(s) > -1)
              );
            })
          : orders,
      selected,
      loadingOrders,
      handleChange,
      handleCarrierChange,
      handlePrint,
    };
  }, [
    orders,
    selected,
    loadingOrders,
    search,
    handleChange,
    handleCarrierChange,
    handlePrint,
  ]);

  return (
    <Paper elevation={1} className={classes.root}>
      <>
        <div className={classes.content}>
          <AutoSizer>
            {({ width, height }) => {
              width = width < 1100 ? 1100 : width;
              return (
                <>
                  <OrderHeader
                    count={orders.length}
                    selected={selected.length}
                    loading={loading}
                    sort={sort}
                    search={search}
                    onSelectAllChange={handleSelectAllChange}
                    onFilterChange={v => setFilter(v)}
                    onSortChange={v => setSort(v)}
                    onSearchChange={v => setSearch(v)}
                    style={{ width }}
                  />
                  <FixedSizeList
                    itemCount={data.orders.length}
                    itemData={data}
                    itemSize={95}
                    height={height - 47}
                    width={width}
                    itemKey={itemKey}
                  >
                    {Row}
                  </FixedSizeList>
                </>
              );
            }}
          </AutoSizer>
        </div>
        <SpeedDial
          className={classes.speeddial}
          icon={<SpeedDialIcon icon={<PickupListIcon />} />}
          hidden={selected.length < 1}
          onOpen={() => setSpeedDialOpen(true)}
          onClose={handleSpeedDialClose}
          open={speedDialOpen}
          ariaLabel="actions"
        >
          {org.modules.indexOf("openpro") > -1 && (
            <SpeedDialAction
              icon={<OpenProIcon />}
              tooltipTitle="Fichier OpenPRO"
              onClick={handleOpenPro}
            />
          )}
          <SpeedDialAction
            icon={<PrintIcon />}
            tooltipTitle="Imprimer"
            onClick={handlePrintClick}
          />
          <SpeedDialAction
            icon={<ArchiveIcon />}
            tooltipTitle="Archiver"
            onClick={handleArchiveClick}
          />
          <SpeedDialAction
            icon={<ReintegrateIcon />}
            tooltipTitle="Remettre en intégration"
            onClick={handleReintegrateClick}
          />
        </SpeedDial>
        {pickuplist.length > 0 && (
          <CreatePicking ids={pickuplist} onClose={handleClosePickupList} />
        )}
      </>
      <OrderDetailsDialog
        orders={order ? [order] : []}
        onClose={() => history.push("/orders")}
      />
      {printOrders.length > 0 && (
        <OrderDetailsDialog
          orders={printOrders}
          print={true}
          onClose={() => {
            setPrintOrders([]);
          }}
        />
      )}
      {exportOrders.length > 0 && (
        <ExportOpenPro
          orders={exportOrders}
          onDone={() => setExportOrders([])}
        />
      )}
    </Paper>
  );
};

export default OrderList;
