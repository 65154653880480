"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

Object.defineProperty(exports, "__esModule", {
  value: true
});

var BaseModel =
/** @class */
function () {
  function BaseModel() {
    this.created = new Date();
    this.modified = new Date();
    this.org = "";
    this.owner = "";
  }

  return BaseModel;
}();

exports.BaseModel = BaseModel;

var Org =
/** @class */
function (_super) {
  __extends(Org, _super);

  function Org() {
    var _this = _super !== null && _super.apply(this, arguments) || this;

    _this.name = "";
    _this.members = [];
    _this.config = {
      carriers: [],
      douane: {},
      orders: {
        showSource: true,
        sortLivraisonReverse: false,
        pickingPrintOrders: false,
        pickingShowMissingRefs: true,
        pickingShowMissingPlaces: true
      }
    };
    _this.modules = [];
    return _this;
  }

  return Org;
}(BaseModel);

exports.Org = Org;

var User =
/** @class */
function (_super) {
  __extends(User, _super);

  function User() {
    var _this = _super !== null && _super.apply(this, arguments) || this;

    _this.name = "";
    _this.password = "";
    _this.email = "";
    _this.org = "";
    _this.level = "";
    return _this;
  }

  return User;
}(BaseModel);

exports.User = User;
var OrderStock;

(function (OrderStock) {
  OrderStock[OrderStock["InStock"] = 0] = "InStock";
  OrderStock[OrderStock["Reappro"] = 1] = "Reappro";
  OrderStock[OrderStock["Missing"] = 2] = "Missing";
})(OrderStock = exports.OrderStock || (exports.OrderStock = {}));

var OrderState;

(function (OrderState) {
  OrderState[OrderState["Loading"] = 0] = "Loading";
  OrderState[OrderState["Loaded"] = 10] = "Loaded";
  OrderState[OrderState["Picked"] = 20] = "Picked";
  OrderState[OrderState["Missing"] = 30] = "Missing";
  OrderState[OrderState["Done"] = 50] = "Done";
})(OrderState = exports.OrderState || (exports.OrderState = {}));

exports.OrderStateLabel = new Map([[OrderState.Loading, "En intégration"], [OrderState.Loaded, "A faire"], [OrderState.Picked, "Préparation"], [OrderState.Missing, "Relicat"], [OrderState.Done, "Terminé"]]);

var Order =
/** @class */
function (_super) {
  __extends(Order, _super);

  function Order() {
    var _this = _super !== null && _super.apply(this, arguments) || this;

    _this.ref = "";
    _this.source = "";
    _this.clientOrderCount = 0;
    _this.date = new Date();
    _this.state = OrderState.Loading;
    _this.archive = false;
    _this.items = [];
    _this.totalHt = 0;
    _this.totalTva = 0;
    _this.totalTtc = 0;
    return _this;
  }

  return Order;
}(BaseModel);

exports.Order = Order;
var DouaneType;

(function (DouaneType) {
  DouaneType[DouaneType["FULL"] = 1] = "FULL";
  DouaneType[DouaneType["RELATIVE"] = 2] = "RELATIVE";
  DouaneType[DouaneType["DRM"] = 3] = "DRM";
})(DouaneType = exports.DouaneType || (exports.DouaneType = {}));

exports.DouaneTypeLabel = new Map([[DouaneType.FULL, "Inventaire"], [DouaneType.RELATIVE, "Entrées"], [DouaneType.DRM, "Déclaration"]]);

var BaseCarrier =
/** @class */
function (_super) {
  __extends(BaseCarrier, _super);

  function BaseCarrier() {
    return _super !== null && _super.apply(this, arguments) || this;
  }

  return BaseCarrier;
}(BaseModel);

exports.BaseCarrier = BaseCarrier;

var CarrierDpd =
/** @class */
function (_super) {
  __extends(CarrierDpd, _super);

  function CarrierDpd() {
    var _this = _super !== null && _super.apply(this, arguments) || this;

    _this.type = "dpd";
    _this.label = "";
    _this.user = "";
    _this.password = "";
    _this.countryCode = "";
    _this.centerNumber = "";
    _this.customerNumber = "";
    _this.country = "";
    _this.zipCode = "";
    _this.city = "";
    _this.street = "";
    _this.name = "";
    _this.phone = "";
    return _this;
  }

  return CarrierDpd;
}(BaseCarrier);

exports.CarrierDpd = CarrierDpd;

var CarrierChronopost =
/** @class */
function (_super) {
  __extends(CarrierChronopost, _super);

  function CarrierChronopost() {
    var _this = _super !== null && _super.apply(this, arguments) || this;

    _this.type = "chronopost";
    _this.label = "";
    _this.idclient = "";
    return _this;
  }

  return CarrierChronopost;
}(BaseCarrier);

exports.CarrierChronopost = CarrierChronopost;