import * as React from "react";
import _ from "lodash";
import {
  Button,
  createStyles,
  makeStyles,
  Theme,
  Table,
  TableHead,
  TableCell,
  TableBody,
  Divider,
  NativeSelect,
  TableRow,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { SubdirectoryArrowRight as TreeIcon } from "@material-ui/icons";
import { db, io } from "pickup-lib";
import SnackbarGroup from "../SnackbarGroup";
import AppContext from "../../../AppContext";
import { green } from "@material-ui/core/colors";
import { useErrorHandler } from "../../../ErrorHandler";

type Mapping = { [k: string]: string };

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    select: {
      minWidth: 100,
    },
    footer: {
      display: "flex",
      flexWrap: "nowrap",
      alignItems: "end",
    },
    label: {
      marginTop: theme.spacing(1),
      flexGrow: 1,
    },
    wrapper: {
      margin: theme.spacing(1),
      position: "relative",
    },
    buttonProgress: {
      color: green[500],
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
  })
);

type EndCallBack = (created: db.Format) => void;
type CancelCallBack = () => void;

interface Props {
  importer: io.ImporterInterface;
  onEnd: EndCallBack;
  onCancel: CancelCallBack;
}

const EditFormat: React.FunctionComponent<Props> = ({
  importer,
  onEnd,
  onCancel,
}) => {
  const classes = useStyles();
  const context = React.useContext(AppContext);
  const [preview, setPreview] = React.useState<io.Preview>();
  const [mapping, setMapping] = React.useState<Mapping>({});
  const [errors, setErrors] = React.useState<string[]>([]);
  const [label, setLabel] = React.useState<string>(
    `${importer.name} du ${new Date().toLocaleDateString()} (${
      importer.reader.src.name
    })`
  );
  const [labelError, setLabelError] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const errorHandler = useErrorHandler();

  const handleSave = function() {
    setSaving(true);
    const fields: db.FormatField[] = [];
    _.forOwn(mapping, (v, k) => {
      fields.push({ src: k, dest: v });
    });
    importer
      .setMapping(fields)
      .checkFields()
      .then(r => {
        const newerrors = r.errors;
        if (label.trim().length === 0) {
          newerrors.push("Veuillez spécifier un intitulé pour ce format");
          setLabelError(true);
        }
        setErrors(newerrors);
        if (!newerrors.length) {
          const format = {
            label: label,
            importer: importer.key,
            fields,
          };
          return context.repos!.getFormats().save(format);
        }
        return Promise.reject();
      })
      .then(r => {
        return context.repos!.getFormats().get(r);
      })
      .then(r => {
        setSaving(false);
        if (r) {
          onEnd(r);
        } else {
          throw new Error("Erreur lors du chargement du format");
        }
      })
      .catch(err => {
        errorHandler(err);
        setSaving(false);
      })
  };

  const handleChange = (i: string) => (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const value = event.target.value;
    setMapping(current => {
      const newmapping = {
        ...current,
      };
      if (!value && newmapping[i]) {
        delete newmapping[i];
      } else {
        newmapping[i] = value;
      }
      return newmapping;
    });
  };

  const options: Array<React.ReactNode> = React.useMemo(() => {
    return Array.from(importer.fields, ([key, field]) => (
      <option key={key} value={key}>
        {field.name + (field.required ? "*" : "")}
      </option>
    ));
  }, [importer.fields]);

  React.useEffect(() => {
    importer.reader.generatePreview().then(p => setPreview(p));
  }, [importer]);

  return (
    <>
      <div>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Champs</TableCell>
              <TableCell>Ligne 1</TableCell>
              <TableCell>Ligne 2</TableCell>
              <TableCell>Ligne 3</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {preview &&
              preview.headers.map((hv, hi) => (
                <TableRow key={hi}>
                  <TableCell>
                    <div>{hv}</div>
                    <div>
                      <TreeIcon />
                      <NativeSelect
                        className={classes.select}
                        onChange={handleChange(hv)}
                      >
                        <option value="">Ignorer</option>
                        {options}
                      </NativeSelect>
                    </div>
                  </TableCell>
                  {preview.head.map((v, i) => (
                    <TableCell key={hi + "-" + i}>{v[hi]}</TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
      <Divider />
      <SnackbarGroup error={errors} />
      <div className={classes.footer}>
        <TextField
          required
          label="Intitulé du format"
          defaultValue={label}
          className={classes.label}
          margin="normal"
          onChange={e => {
            setLabel(e.target.value);
            setLabelError(false);
          }}
          error={labelError}
        />
        <div className={classes.wrapper}>
          <Button component="label" onClick={onCancel} disabled={saving}>
            Annuler
          </Button>
        </div>
        <div className={classes.wrapper}>
          <Button
            component="label"
            color="primary"
            variant="contained"
            onClick={handleSave}
            disabled={saving}
          >
            Enregistrer
          </Button>
          {saving && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      </div>
    </>
  );
};

export default EditFormat;
