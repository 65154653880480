import * as React from "react";
import {
  Avatar,
  Chip,
  Theme,
  makeStyles,
  createStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fixAvatarSize: {
      height: 32,
      width: 32,
      fontSize: "1rem",
    },
  })
);

interface Props {
  value: string[];
  labels: Map<string, string>;
  className?: string;
  chipClassName?: string;
  style?: React.CSSProperties;
  variant?: "default" | "outlined";
}

const ChipGroup: React.FunctionComponent<Props> = ({
  value,
  labels,
  className,
  chipClassName,
  style,
  variant,
}) => {
  const classes = useStyles();
  return (
    <div className={className} style={style}>
      {(value as string[]).map(key => {
        if (!labels.has(key)) {
          return null;
        }
        return (
          <Chip
            key={key}
            label={labels.get(key)}
            className={chipClassName}
            avatar={<Avatar className={classes.fixAvatarSize}>{key}</Avatar>}
            variant={variant}
          />
        );
      })}
    </div>
  );
};

export default ChipGroup;
