import * as React from "react";
import Carriers from "./config/Carriers";
import ItemTypes from "./config/ItemTypes";
import Douane from "./config/Douane";
import Commande from "./config/Commande";
import AppContext from "AppContext";
import { Grid } from "@material-ui/core";
import { GridTypeMap } from "@material-ui/core/Grid";
import { DefaultComponentProps } from "@material-ui/core/OverridableComponent";

const Config: React.FunctionComponent<{}> = () => {
  const { org } = React.useContext(AppContext);
  const itemProps: DefaultComponentProps<GridTypeMap> = {
    xs: 12,
    sm: 6,
    lg: 4,
  };
  return (
    <Grid container spacing={3}>
      <Grid {...itemProps} item>
        <Commande />
      </Grid>
      <Grid {...itemProps} item>
        <Carriers />
      </Grid>
      <Grid {...itemProps} item>
        <ItemTypes />
      </Grid>
      {org!.modules.indexOf("douanes") > -1 && (
        <Grid {...itemProps} item>
          <Douane />
        </Grid>
      )}
    </Grid>
  );
};

export default Config;
