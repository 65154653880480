import * as React from "react";
import {
  FilterList as FilterIcon,
} from "@material-ui/icons";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import {
  IconButton,
  Popover,
  makeStyles,
  Theme,
  createStyles,
  Grid,
  Checkbox,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
} from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import clsx from "clsx";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import DatePickerWithClear from "./DatePickerWithClear";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menu: {
      padding: theme.spacing(2),
      maxWidth: 800,
    },
    titre: {
      lineHeight: 1.33,
      fontSize: "1.2rem",
      textAlign: "center",
      color: theme.palette.primary.main,
      fontWeight: 400,
    },
    item: {
      padding: theme.spacing(0, 2),
    },
    btns: {
      textAlign: "right",
    },
    btn: {
      marginRight: theme.spacing(2),
    },
    dateBtns: {
      marginTop: theme.spacing(1),
    },
    dateBtn: {
      margin: theme.spacing(0.5),
    },
    verticalSep: {
      borderRight: "1px solid silver",
    },
  })
);

type DateType =
  | "today"
  | "yesterday"
  | "week"
  | "lastweek"
  | "month"
  | "lastmonth";

export interface FilterMenuValue {
  state0: boolean;
  state10: boolean;
  state20: boolean;
  state30: boolean;
  state50: boolean;
  disponible: boolean;
  reappro: boolean;
  missing: boolean;
  date: DateType | null;
  dateFrom: Date | null;
  dateTo: Date | null;
}

const CheckItem = (
  label: string,
  state: boolean,
  dispatch: React.Dispatch<React.SetStateAction<boolean>>
) => {
  return (
    <ListItem dense button onClick={() => dispatch(v => !v)}>
      <Checkbox disableRipple edge="start" checked={state} />
      <ListItemText primary={label} />
    </ListItem>
  );
};

interface Props {
  className?: string;
  style?: React.CSSProperties;
  onChange?: (value: FilterMenuValue) => void;
}

const FilterMenu: React.FunctionComponent<Props> = ({
  className,
  style,
  onChange,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [state0, setState0] = React.useState(false);
  const [state10, setState10] = React.useState(true);
  const [state20, setState20] = React.useState(true);
  const [state50, setState50] = React.useState(false);
  const [disponible, setDisponible] = React.useState(true);
  const [reappro, setReappro] = React.useState(true);
  const [missing, setMissing] = React.useState(true);
  const [date, setDate] = React.useState<DateType | null>(null);
  const [dateFrom, setDateFrom] = React.useState<MaterialUiPickersDate>(null);
  const [dateTo, setDateTo] = React.useState<MaterialUiPickersDate>(null);
  const classes = useStyles();

  const DateButton = React.useCallback(
    (label: string, value: DateType) => {
      return (
        <Button
          variant={value === date ? "contained" : "outlined"}
          color={value === date ? "primary" : "default"}
          size="small"
          className={classes.dateBtn}
          onClick={() => setDate(value)}
        >
          {label}
        </Button>
      );
    },
    [classes, date]
  );

  const handleResetClick = () => {
    setState0(false);
    setState10(true);
    setState20(true);
    setState50(false);
    setDisponible(true);
    setReappro(true);
    setMissing(true);
    setDate(null);
    setDateFrom(null);
    setDateTo(null);
  };

  const handleOpenClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleApply = () => {
    setAnchorEl(null);
    if (onChange) {
      onChange({
        state0,
        state10,
        state20,
        state30: true,
        state50,
        disponible,
        missing,
        reappro,
        date,
        dateFrom: dateFrom ? dateFrom.utc(true).toDate() : null,
        dateTo: dateTo ? dateTo.utc(true).toDate() : null,
      });
    }
  };

  const handleDateChangeFrom = (value: MaterialUiPickersDate) => {
    setDate(null);
    if (value) {
      setDateFrom(
        value
          .hours(0)
          .minutes(0)
          .seconds(0)
      );
    } else {
      setDateFrom(value);
    }
  };

  const handleDateChangeTo = (value: MaterialUiPickersDate) => {
    setDate(null);
    if (value) {
      setDateTo(
        value
          .hours(23)
          .minutes(59)
          .seconds(59)
      );
    } else {
      setDateTo(value);
    }
  };

  React.useEffect(() => {
    if (date === null) {
      return;
    }
    let from: moment.Moment | null = null;
    let to: moment.Moment | null = null;
    switch (date) {
      case "today":
        from = moment();
        break;
      case "yesterday":
        from = moment().add(-1, "day");
        to = moment().add(-1, "day");
        break;
      case "week":
        from = moment().weekday(0);
        break;
      case "lastweek":
        from = moment()
          .add(-1, "week")
          .weekday(0);
        to = moment()
          .add(-1, "week")
          .weekday(6);
        break;
      case "month":
        from = moment().date(1);
        break;
      case "lastmonth":
        from = moment()
          .add(-1, "month")
          .date(1);
        to = moment()
          .date(1)
          .add(-1, "day");
        break;
    }
    setDateFrom(
      from
        ? from
            .hours(0)
            .minutes(0)
            .seconds(0)
        : null
    );
    setDateTo(
      to
        ? to
            .hours(23)
            .minutes(59)
            .seconds(59)
        : null
    );
  }, [date]);

  const xs = 4;

  return (
    <>
      <div className={className} style={style}>
        <IconButton size="small" onClick={handleOpenClick}>
          <FilterIcon />
        </IconButton>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          classes={{ paper: classes.menu }}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <Grid container>
              <Grid
                item
                xs={xs}
                className={clsx(classes.verticalSep, classes.item)}
              >
                <Typography className={classes.titre}>Date</Typography>
                <DatePickerWithClear
                  value={dateFrom}
                  label="Du"
                  onChange={handleDateChangeFrom}
                />
                <DatePickerWithClear
                  value={dateTo}
                  label="Au"
                  onChange={handleDateChangeTo}
                />
                <div className={classes.dateBtns}>
                  {DateButton("Aujourd'hui", "today")}
                  {DateButton("Hier", "yesterday")}
                  {DateButton("Semaine", "week")}
                  {DateButton("Semaine -1", "lastweek")}
                  {DateButton("Mois", "month")}
                  {DateButton("Mois -1", "lastmonth")}
                </div>
              </Grid>
              <Grid
                item
                xs={xs}
                className={clsx(classes.verticalSep, classes.item)}
              >
                <Typography className={classes.titre}>Disponibilité</Typography>
                <List>
                  {CheckItem("Disponible", disponible, setDisponible)}
                  {CheckItem("Réapprovisionnement", reappro, setReappro)}
                  {CheckItem("Manquant", missing, setMissing)}
                </List>
              </Grid>
              <Grid item xs={xs} className={clsx(classes.item)}>
                <Typography className={classes.titre}>Statut</Typography>
                <List>
                  {CheckItem("Intégration", state0, setState0)}
                  {CheckItem("A faire", state10, setState10)}
                  {CheckItem("Préparation", state20, setState20)}
                  {CheckItem("Terminé", state50, setState50)}
                </List>
              </Grid>
              <Grid item className={classes.btns} xs={12}>
                <Button
                  variant="contained"
                  color="default"
                  size="small"
                  onClick={handleResetClick}
                  className={classes.btn}
                >
                  RAZ
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={handleApply}
                  className={classes.btn}
                >
                  Appliquer
                </Button>
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
        </Popover>
      </div>
    </>
  );
};

export default FilterMenu;
