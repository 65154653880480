import * as React from "react";
import {
  makeStyles,
  createStyles,
  Theme,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slide,
} from "@material-ui/core";
import Loader from "../components/Loader";
import moment from "moment";
import "moment/locale/fr";
import { TransitionProps } from "@material-ui/core/transitions";

const Transition = React.forwardRef<unknown, TransitionProps>(
  function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  }
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 400,
    },
  })
);

type CancelCallback = () => void;
type ConfirmCallback = (data?: any) => void;

interface Props {
  onCancel?: CancelCallback;
  onConfirm?: ConfirmCallback;
  data?: any;
  children?: React.ReactNode;
}

const DialogDelete: React.FunctionComponent<Props> = ({
  onCancel,
  onConfirm,
  children,
  data,
}) => {
  moment.locale("fr");
  const [loading, setLoading] = React.useState("");
  const classes = useStyles();

  function handleCancel() {
    if (onCancel) {
      onCancel();
    }
  }

  function handleConfirm() {
    setLoading("Suppression en cours...");
    if (onConfirm) {
      onConfirm(data);
    }
  }

  return (
    <Dialog
      open={true}
      onClose={handleCancel}
      aria-labelledby="types-form-dialog-title"
      classes={{ paper: classes.root }}
      TransitionComponent={Transition}
      keepMounted
    >
      <Loader show={Boolean(loading)} message={loading}>
        <DialogTitle id="types-form-dialog-title">
          Confirmer suppression ?
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="default">
            Annuler
          </Button>
          <Button onClick={handleConfirm} color="primary">
            Confirmer
          </Button>
        </DialogActions>
      </Loader>
    </Dialog>
  );
};

export default DialogDelete;
