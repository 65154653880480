import { db } from "pickup-lib";

type UpdateCallback = (orders: db.Order[]) => void;

export default class UpdateService {
  private listener?: UpdateCallback;
  private repo: db.OrdersRepo;
  private ids: string[] = [];
  private timer: NodeJS.Timeout | undefined = undefined;

  constructor(repo: db.OrdersRepo, listener: UpdateCallback) {
    this.listener = listener;
    this.repo = repo;
  }

  public update(id: string) {
    this.ids.push(id);
    if (this.timer) {
      return;
    }
    this.timer = setTimeout(() => {
      this.asyncUpdate();
    }, 100);
  }

  public dispose() {
    this.listener = undefined;
  }

  private asyncUpdate() {
    if (this.ids.length === 0) {
      this.timer = undefined;
      return;
    }
    const ids = this.ids.splice(0, this.ids.length);
    this.repo.listByIds(ids).then(orders => {
      if (this.listener) {
        this.listener(orders);
        this.timer = setTimeout(() => {
          this.asyncUpdate();
        }, 100);
      }
    });
  }
}
