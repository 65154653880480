import * as React from "react";
import {
  createStyles,
  Theme,
  makeStyles,
  Tooltip,
  withStyles,
} from "@material-ui/core";
import { StoreMallDirectory as RelaisIcon } from "@material-ui/icons";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    lightIcon: {
      fontSize: 18,
      verticalAlign: "text-bottom",
      color: "rgb(200,200,200)",
    },
    relaisId: {
      maxWidth: 100,
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  })
);

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 12,
    fontWeight: "normal",
  },
}))(Tooltip);

interface Props {
  className?: string;
  id?: string;
  address?: string;
}

const RelaisId: React.FunctionComponent<Props> = React.memo(
  ({ id, address, className }) => {
    const classes = useStyles();

    if (!id || !address) {
      return null;
    }

    return (
      <LightTooltip
        title={address.split("|").map((v) => (
          <div>{v}</div>
        ))}
      >
        <span className={clsx(className, classes.relaisId)}>
          <RelaisIcon className={classes.lightIcon} /> <span>{id}</span>
        </span>
      </LightTooltip>
    );
  }
);

export default RelaisId;
