import * as React from "react";
import { IconButton, Menu } from "@material-ui/core";

interface Props {
  icon: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

const IconMenu: React.FunctionComponent<Props> = React.forwardRef(({ icon, children, className, style }, ref: React.Ref<HTMLDivElement>) => {
  const [menuAnchor, setMenuAnchor] = React.useState<HTMLElement | null>(null);
  const isMenuOpen = Boolean(menuAnchor);

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    const target = event.currentTarget;
    setMenuAnchor(target);
  };

  return (
    <div ref={ref} className={className} style={style}>
      <IconButton size="small" onClick={handleMenuOpen}>
        {icon}
      </IconButton>
      <Menu
        anchorEl={menuAnchor}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMenuOpen}
        onClose={handleMenuClose}
        getContentAnchorEl={null}
      >
        {children}
      </Menu>
    </div>
  );
});

export default IconMenu;
