import * as React from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core";
import { Loyalty as LoyaltyIcon } from "@material-ui/icons";
import { db } from "pickup-lib";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: 10,
      marginBottom: 10,
    },
    address: {
      lineHeight: 1.6,
    },
    label: {
      color: "rgb(60,60,60)",
      fontSize: "14px",
      fontWeight: "bold",
    },
    part1: {
      color: "rgb(90,90,90)",
      textDecorationColor: "rgb(200,200,200)",
    },
    part2: {
      color: "rgb(120,120,120)",
    },
    part3: {
      color: "rgb(160,160,160)",
    },
    societe: {
      fontWeight: "bold",
      color: "rgb(100,100,100)",
    },
    refClient: {
      fontSize: 12,
      fontWeight: "normal",
      color: "rgb(160,160,160)",
    },
    light: {
      fontSize: 12,
      fontWeight: "normal",
      whiteSpace: "nowrap",
      marginLeft: theme.spacing(1),
      color: "rgb(160,160,160)",
    },
    lightIcon: {
      fontSize: 12,
      verticalAlign: "text-bottom",
    },
  })
);

interface Props {
  address?: db.Address;
  refClient?: string;
  loyalty?: number;
}

const Address: React.FunctionComponent<Props> = ({
  address,
  refClient,
  loyalty,
}) => {
  const classes = useStyles();
  if (!address) {
    return null;
  }

  return (
    <div className={classes.address}>
      <div className={classes.label}>
        {address.nom} {address.prenom}
        {address.societe && (
          <span className={classes.societe}> / {address.societe}</span>
        )}
        {refClient && <span className={classes.refClient}> / {refClient}</span>}
        {Boolean(loyalty) && (
          <span className={classes.light}>
            <LoyaltyIcon className={classes.lightIcon} />{" "}
            {loyalty}
          </span>
        )}
      </div>
      <div>
        {address.line1}
        {address.line2 && (
          <span className={classes.part2}> / {address.line2}</span>
        )}
        {address.line3 && (
          <span className={classes.part3}> / {address.line3}</span>
        )}
      </div>
      <div>
        {address.postCode} <span className={classes.part2}>{address.city}</span>{" "}
        <span className={classes.part3}>{address.countryCode}</span>
      </div>
      <div>
        <span className={classes.part1}>{address.email}</span>
        {address.gsm && <span className={classes.part2}> / {address.gsm}</span>}
        {address.tel && <span className={classes.part3}> / {address.tel}</span>}
      </div>
    </div>
  );
};

export default Address;
