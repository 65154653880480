import {
  createStyles,
  Paper,
  Theme,
  WithStyles,
  withStyles,
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepContent,
} from "@material-ui/core";
import * as React from "react";
import ImportIcon from "@material-ui/icons/ImportExport";
import Loader from "./components/Loader";
import { io } from "pickup-lib";
import Step1Prepa from "./components/import/Step1Prepa";
import Step2Preview from "./components/import/Step2Preview";
import Step3Confirm from "./components/import/Step3Confirm";
import Step4Do from "./components/import/Step4Do";
import AppContext from "../AppContext";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      overflow: "auto",
      margin: "auto",
      padding: theme.spacing(3),
    },
    button: {
      margin: theme.spacing(1),
    },
    loader: {
      maxWidth: 300,
    },
  });

interface Params {}

interface Props extends WithStyles<typeof styles> {}

interface State {
  loading: string | null;
  activeStep: number;
  create: boolean;
  importer?: io.ImporterInterface;
}

class Import extends React.Component<Props, State> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;
  private fileTypes =
    ".csv,.xls,.xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel";
  private defaultState = {
    loading: null,
    errors: [],
    activeStep: 0,
    create: false,
  };

  constructor(props: Props) {
    super(props);
    this.state = this.defaultState;
  }

  // tslint:disable-next-line:no-empty
  public componentDidMount() {}

  public render() {
    const { classes } = this.props;
    const { activeStep, importer, loading } = this.state;
    let name: string = "";
    if (importer) {
      name = importer.name;
    }
    return (
      <Paper className={classes.root} elevation={1}>
        <Typography variant="h2">
          <ImportIcon /> Import / Export
        </Typography>
        <Stepper activeStep={activeStep} orientation="vertical">
          <Step>
            <StepLabel>Préparation : {name}</StepLabel>
            <StepContent>
              <Step1Prepa onEnd={this.handleStep1End} />
            </StepContent>
          </Step>
          <Step>
            <StepLabel>Prévisualisation : {name}</StepLabel>
            <StepContent>
              <Step2Preview
                importer={this.state.importer!}
                onEnd={this.handleStep2End}
                onCancel={() =>
                  this.setState({
                    activeStep: 0,
                  })
                }
              />
            </StepContent>
          </Step>
          <Step>
            <StepLabel>Confirmation : {name}</StepLabel>
            <StepContent>
              <Step3Confirm
                importer={this.state.importer!}
                onEnd={this.handleStep3End}
                onCancel={() =>
                  this.setState({
                    activeStep: 1,
                  })
                }
              />
            </StepContent>
          </Step>
          <Step>
            <StepLabel>Rapport : {name}</StepLabel>
            <StepContent>
              <Loader
                className={classes.loader}
                show={Boolean(loading)}
                message={loading}
              >
                <Step4Do
                  importer={this.state.importer!}
                  create={this.state.create}
                  onEnd={() =>
                    this.setState({
                      activeStep: 0,
                    })
                  }
                />
              </Loader>
            </StepContent>
          </Step>
        </Stepper>
      </Paper>
    );
  }

  private handleStep1End = (importer: io.ImporterInterface) => {
    this.setState({
      activeStep: 1,
      importer,
    });
  };

  private handleStep2End = (mapping: io.FieldMapping[]) => {
    this.setState(current => {
      if (current.importer) {
        return {
          activeStep: 2,
          importer: current.importer.setMapping(mapping),
        };
      }
      return { activeStep: 1 }; // N'arrive jamais normalement...
    });
  };

  private handleStep3End = (create: boolean) => {
    this.setState({
      activeStep: 3,
      create,
    });
  };
}

export default withStyles(styles)(Import);
