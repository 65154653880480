import {
  createStyles,
  Theme,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer as MatDrawer,
  Divider,
  Typography,
  makeStyles,
} from "@material-ui/core";
import * as React from "react";
import {
  ShoppingCart as OrderIcon,
  Archive as ArchiveIcon,
  AddShoppingCart as PickingIcon,
  Category as ItemIcon,
  Storage as InventoryIcon,
  Devices as AgentIcon,
  ImportExport as ImportIcon,
  Build as ConfigIcon,
  SupervisorAccount as UserIcon,
  Sync as SyncIcon,
} from "@material-ui/icons";
import { UserContext } from "../../Contexts";
import classNames from "classnames";
import useRouter from "use-react-router";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      [theme.breakpoints.down("md")]: {
        width: theme.spacing(7) + 1,
      },
    },
    drawerPaper: {
      width: drawerWidth,
      [theme.breakpoints.down("md")]: {
        width: theme.spacing(7) + 1,
      },
    },
    toolbar: theme.mixins.toolbar,
    orgname: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    hiddenSm: {
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
  })
);

const Drawer: React.FunctionComponent<{}> = () => {
  const { history } = useRouter();
  const classes = useStyles();
  return (
    <UserContext.Consumer>
      {context => (
        <MatDrawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
          anchor="left"
        >
          <Typography
            variant="h6"
            color="inherit"
            noWrap={true}
            className={classNames(classes.toolbar, classes.orgname)}
          >
            <div className={classes.hiddenSm}>{context.org.name}</div>
          </Typography>
          <Divider />
          <List>
            <ListItem button onClick={() => history.push("/orders")}>
              <ListItemIcon>
                <OrderIcon />
              </ListItemIcon>
              <ListItemText primary="Commandes" className={classes.hiddenSm} />
            </ListItem>
            <ListItem button onClick={() => history.push("/archives")}>
              <ListItemIcon>
                <ArchiveIcon />
              </ListItemIcon>
              <ListItemText primary="Archives" className={classes.hiddenSm} />
            </ListItem>
            <ListItem button onClick={() => history.push("/pickings")}>
              <ListItemIcon>
                <PickingIcon />
              </ListItemIcon>
              <ListItemText primary="Pickings" className={classes.hiddenSm} />
            </ListItem>
            <ListItem button onClick={() => history.push("/items")}>
              <ListItemIcon>
                <ItemIcon />
              </ListItemIcon>
              <ListItemText primary="Articles" className={classes.hiddenSm} />
            </ListItem>
            {context.org.modules.indexOf("douanes") > -1 && (
              <ListItem button onClick={() => history.push("/douanes")}>
                <ListItemIcon>
                  <InventoryIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Inventaires"
                  className={classes.hiddenSm}
                />
              </ListItem>
            )}
            <Divider />
            <ListItem button onClick={() => history.push("/import")}>
              <ListItemIcon>
                <ImportIcon />
              </ListItemIcon>
              <ListItemText
                primary="Imports / Exports"
                className={classes.hiddenSm}
              />
            </ListItem>
            <Divider />
            <ListItem button onClick={() => history.push("/config")}>
              <ListItemIcon>
                <ConfigIcon />
              </ListItemIcon>
              <ListItemText primary="Paramètres" className={classes.hiddenSm} />
            </ListItem>
            <ListItem button onClick={() => history.push("/users")}>
              <ListItemIcon>
                <UserIcon />
              </ListItemIcon>
              <ListItemText
                primary="Utilisateurs"
                className={classes.hiddenSm}
              />
            </ListItem>
            <ListItem button onClick={() => history.push("/agents")}>
              <ListItemIcon>
                <AgentIcon />
              </ListItemIcon>
              <ListItemText primary="Agents" className={classes.hiddenSm} />
            </ListItem>
            <ListItem button onClick={() => history.push("/syncs")}>
              <ListItemIcon>
                <SyncIcon />
              </ListItemIcon>
              <ListItemText
                primary="Synchronisation"
                className={classes.hiddenSm}
              />
            </ListItem>
            <Divider />
          </List>
        </MatDrawer>
      )}
    </UserContext.Consumer>
  );
};

export default Drawer;
