"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

Object.defineProperty(exports, "__esModule", {
  value: true
});

var utils_1 = require("../utils");

var ItemPackagingParser =
/** @class */
function (_super) {
  __extends(ItemPackagingParser, _super);

  function ItemPackagingParser() {
    return _super !== null && _super.apply(this, arguments) || this;
  }

  ItemPackagingParser.prototype.fromString = function (value) {
    return value.split(",").reduce(function (prev, cur) {
      cur = cur.trim();

      if (Boolean(cur)) {
        var i = cur.lastIndexOf("/");
        var qty = 1;
        var label = "";

        if (i > 0) {
          var qtyPart = cur.substr(i + 1);
          qty = utils_1.String.parseNumber(qtyPart);

          if (qty === undefined) {
            qty = 1;
          }

          label = cur.substr(0, i);
        } else {
          label = cur;
        }

        prev.push({
          label: label,
          qty: qty
        });
      }

      return prev;
    }, []);
  };

  return ItemPackagingParser;
}(utils_1.StringParserClass);

exports.ItemPackagingParser = ItemPackagingParser;