import * as React from "react";
import {
  Theme,
  makeStyles,
  createStyles,
  ListItemText,
  TableCell,
  TableRow,
} from "@material-ui/core";
import { db } from "pickup-lib";
import PlaceAvatar from "screens/components/PlaceAvatar";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      pageBreakInside: "avoid",
    },
    avatarCell: {
      width: "1%",
      paddingRight: theme.spacing(1),
    },
    qtyCell: {
      fontSize: "200%",
      width: 100,
      textAlign: "right",
      whiteSpace: "nowrap",
    },
  })
);

interface Props {
  data: db.OrderItemsAggregate;
}

const PickingRow: React.FunctionComponent<Props> = React.memo(({ data }) => {
  const classes = useStyles();
  let secondary = data.label ? data.label : data.srcLabel;
  if (data.srcOptions.length) {
    secondary += ` [${data.srcOptions.join(", ")}]`;
  }
  return (
    <TableRow className={classes.root}>
      <TableCell className={classes.avatarCell}>
        <PlaceAvatar value={data.place} />
      </TableCell>
      <TableCell className={classes.qtyCell}>{data.totalQty} x</TableCell>
      <TableCell>
        <ListItemText
          primary={data.ref ? data.ref : data.srcRef}
          secondary={secondary}
        />
      </TableCell>
    </TableRow>
  );
});

export default PickingRow;
