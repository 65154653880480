import * as React from "react";
import {
  createStyles,
  Theme,
  makeStyles,
  IconButton,
  Grid,
} from "@material-ui/core";
import {
  Print as PrintIcon,
  RemoveRedEye as ViewIcon,
} from "@material-ui/icons";
import { db } from "pickup-lib";
import useRouter from "use-react-router";
import { ObjectId } from "bson";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: "0 1 50px",
      fontSize: 12,
      lineHeight: 1.5,
      color: "rgba(0,0,0,0.54)",
      padding: theme.spacing(1),
    },
    btn: {
      padding: theme.spacing(1),
    },
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  })
);

export type OnPrintCallback = (id: ObjectId) => void;

interface Props {
  order: db.Order;
  onPrint: OnPrintCallback;
}

const MoreCell: React.FunctionComponent<Props> = ({ order, onPrint }) => {
  const { history } = useRouter();
  const classes = useStyles();

  const handleViewClick = function() {
    history.push("/orders/" + order._id!.toHexString());
  };

  const handlePrintClick = function() {
    if (onPrint) {
      onPrint(order._id!);
    }
  };

  return (
    <Grid item className={classes.root}>
      <IconButton onClick={handleViewClick} className={classes.btn}>
        <ViewIcon />
      </IconButton>
      <IconButton onClick={handlePrintClick} className={classes.btn}>
        <PrintIcon />
      </IconButton>
    </Grid>
  );
};

export default MoreCell;
