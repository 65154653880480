"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var StitchReadOperationBrowser =
/** @class */
function () {
  function StitchReadOperationBrowser(op) {
    this.op = op;
  }

  StitchReadOperationBrowser.prototype.first = function () {
    return this.op.first();
  };

  StitchReadOperationBrowser.prototype.toArray = function () {
    return this.op.toArray();
  };

  StitchReadOperationBrowser.prototype.asArray = function () {
    return this.op.asArray();
  };

  StitchReadOperationBrowser.prototype.iterator = function () {
    return this.op.iterator();
  };

  return StitchReadOperationBrowser;
}();

exports.default = StitchReadOperationBrowser;