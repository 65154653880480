import * as React from "react";
import _ from "lodash";
import {
  createStyles,
  Theme,
  makeStyles,
  Paper,
  Typography,
  IconButton,
  Table,
  TableBody,
  Fab,
  Tooltip,
  Grid,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import {
  ArrowBack as BackIcon,
  Print as PrintIcon,
  FilterList as FilterIcon,
} from "@material-ui/icons";
import ReactToPrint from "react-to-print";
import LoaderInline from "./components/LoaderInline";
import PickingRow from "./picking/PickingRow";
import useRouter from "use-react-router";
import AppContext from "AppContext";
import { useErrorHandler } from "ErrorHandler";
import { db, AnvokError, utils } from "pickup-lib";
import moment from "moment";
import "moment/locale/fr";
import ErrorText from "./components/ErrorText";
import OrderChips from "./components/OrderChips";
import IconMenu from "./components/IconMenu";
import clsx from "clsx";
import OrderDetails from "./orderlist/OrderDetails";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      padding: theme.spacing(1),
      display: "flex",
      flexDirection: "column",
      "@media print": {
        overflow: "auto",
        height: "unset",
        display: "unset",
        boxShadow: "none",
      },
    },
    subtitle: {
      marginLeft: theme.spacing(1),
      color: "rgba(0, 0, 0, 0.54)",
    },
    content: {
      flexGrow: 1,
      overflow: "auto",
      "@media print": {
        overflow: "unset",
      },
    },
    orders: {
      display: "none",
      "@media print": {
        display: "block",
      },
    },
    action: {
      padding: theme.spacing(1),
      display: "flex",
    },
    actionConfig: {
      flexGrow: 1,
    },
    actionErrors: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    actionError: {
      display: "block",
      padding: theme.spacing(0.5),
    },
    tableBody: {
      "& tr:nth-child(even)": {
        backgroundColor: theme.palette.grey[50],
      },
    },
    order: {
      "@media print": {
        pageBreakBefore: "always",
        breakBefore: "always",
      },
    },
    printHidden: {
      "@media print": {
        display: "none",
      },
    },
    printBody: {
      padding: "10px",
    },
  })
);

interface Params {
  id: string;
}

interface PickingInfo {
  missingRefs: string[];
  missingPlaces: string[];
  orders: db.OrderItemsAggregateOrder[];
}

const Picking: React.FunctionComponent<{}> = () => {
  const [loading, setLoading] = React.useState("Chargement des commandes");
  const [subtitle, setSubTitle] = React.useState("");
  const [pickingInfo, setPickingInfo] = React.useState<PickingInfo>({
    missingRefs: [],
    missingPlaces: [],
    orders: [],
  });
  const [result, setResult] = React.useState<db.OrderItemsAggregate[]>([]);
  const [showMissingRefs, setShowMissingRefs] = React.useState(true);
  const [showMissingPlaces, setShowMissingPlaces] = React.useState(true);
  const [printWithOrders, setPrintWithOrders] = React.useState(false);
  const [orders, setOrders] = React.useState<db.Order[]>([]);
  const componentRef = React.useRef<React.ReactInstance>();
  const { history, match } = useRouter<Params>();
  const { repos, org } = React.useContext(AppContext);
  const errorHandler = useErrorHandler();
  const classes = useStyles();

  const configOrders = org!.config.orders;

  React.useEffect(() => {
    setShowMissingRefs(configOrders.pickingShowMissingRefs);
    setShowMissingPlaces(configOrders.pickingShowMissingPlaces);
    setPrintWithOrders(configOrders.pickingPrintOrders);
  }, [configOrders]);

  React.useEffect(() => {
    setLoading("Chargement de la liste...");
    const pickings = repos!.getPickings();
    pickings
      .get(match.params.id)
      .then(p => {
        if (!p) {
          return Promise.reject(
            new AnvokError("Liste de picking introuvable", "err")
          );
        }
        setSubTitle(`${moment(p.created).format("LL")} par ${p.username}`);
        return pickings.build(p._id);
      })
      .then(r => {
        const pickingInfo = r.reduce<PickingInfo>(
          (prev, cur) => {
            if (cur.place === "__UNDEFINED_REF") {
              prev.missingRefs.push(cur.srcRef);
            } else if (cur.place === "__UNDEFINED_PLACE") {
              prev.missingPlaces.push(cur.srcRef);
            }
            prev.orders.push(...cur.orders);
            return prev;
          },
          {
            missingRefs: [],
            missingPlaces: [],
            orders: [],
          }
        );
        pickingInfo.orders = _.uniqWith(pickingInfo.orders, (a, b) => {
          return a.id.equals(b.id);
        });
        setPickingInfo(pickingInfo);
        setResult(r);
      })
      .catch(errorHandler)
      .finally(() => {
        setLoading("");
      });
  }, [errorHandler, repos, match.params.id]);

  React.useEffect(() => {
    if (printWithOrders && pickingInfo.orders.length > 0) {
      setLoading("Chargement des commandes...");
      const ids = pickingInfo.orders.map(o => o.id);
      repos!
        .getOrders()
        .listByIds(ids, { date: -1 })
        .then(orders => {
          setOrders(orders);
        })
        .catch(errorHandler)
        .finally(() => setLoading(""));
    } else {
      setOrders([]);
    }
  }, [printWithOrders, pickingInfo, repos, errorHandler]);

  return (
    <Paper elevation={1} className={classes.root} ref={componentRef}>
      <Grid container justify="space-between">
        <Grid item>
          <Typography variant="h2">
            <IconButton
              className={classes.printHidden}
              onClick={() => history.push("/orders")}
            >
              <BackIcon />
            </IconButton>
            Liste de picking
            <Typography
              variant="subtitle1"
              component="span"
              display="inline"
              className={clsx(classes.subtitle, classes.printHidden)}
            >
              {subtitle}
            </Typography>
          </Typography>
        </Grid>
        <Grid item className={classes.printHidden}>
          <IconMenu icon={<FilterIcon />}>
            <MenuItem>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={utils.Form.handleChangeCheck(setShowMissingRefs)}
                    checked={showMissingRefs}
                  />
                }
                label="Références inconnues"
              />
            </MenuItem>
            <MenuItem>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={utils.Form.handleChangeCheck(
                      setShowMissingPlaces
                    )}
                    checked={showMissingPlaces}
                  />
                }
                label="Emplacements inconnus"
              />
            </MenuItem>
          </IconMenu>
        </Grid>
      </Grid>
      <LoaderInline show={Boolean(loading)} message={loading} />
      <div className={classes.content}>
        <OrderChips value={pickingInfo.orders} />
        <Table>
          <TableBody className={classes.tableBody}>
            {result.map(r => {
              if (r.place.startsWith("__UNDEFINED_REF") && !showMissingRefs) {
                return null;
              }
              if (
                r.place.startsWith("__UNDEFINED_PLACE") &&
                !showMissingPlaces
              ) {
                return null;
              }
              if (r.place.startsWith("__NOPICKING")) {
                return null;
              }
              return (
                <PickingRow
                  key={r.srcRef + "/" + r.srcOptions.join("-")}
                  data={r}
                />
              );
            })}
          </TableBody>
        </Table>
        {orders.length > 0 && (
          <div className={classes.orders}>
            {orders.map(o => (
              <OrderDetails
                key={o._id!.toHexString()}
                order={o}
                className={classes.order}
              />
            ))}
          </div>
        )}
      </div>
      <div className={clsx(classes.action, classes.printHidden)}>
        <div className={classes.actionConfig}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={utils.Form.handleChangeCheck(setPrintWithOrders)}
                checked={printWithOrders}
              />
            }
            label="Imprimer les commandes"
          />
        </div>
        <div className={classes.actionErrors}>
          {pickingInfo.missingRefs.length > 0 && (
            <Tooltip title={pickingInfo.missingRefs.join(", ")}>
              <ErrorText
                className={classes.actionError}
                text={pickingInfo.missingRefs.length + " Références inconnues"}
              />
            </Tooltip>
          )}
          {pickingInfo.missingPlaces.length > 0 && (
            <Tooltip title={pickingInfo.missingPlaces.join(", ")}>
              <ErrorText
                className={classes.actionError}
                text={
                  pickingInfo.missingPlaces.length + " Emplacements inconnus"
                }
              />
            </Tooltip>
          )}
        </div>
        <ReactToPrint
          bodyClass={classes.printBody}
          trigger={() => (
            <Fab color="secondary">
              <PrintIcon />
            </Fab>
          )}
          content={() => componentRef.current!}
        />
      </div>
    </Paper>
  );
};

export default Picking;
