import * as React from "react";
import StepToropShop from "./StepToropShop";
import StepPrestashop from "./StepPrestashop";

interface SyncTypeProps {
  onChange: (config: object) => void;
}

type StatusValues = Map<string, string>;

export interface SyncType {
  name: string;
  componentCreate: React.FunctionComponent<SyncTypeProps>;
  getStatuts(): Promise<StatusValues>;
}

const toropStatuts = new Map<string, string>([
  ["-1", "Annulé"],
  ["5", "Attente de paiement"],
  ["10", "Paiement validé"],
  ["15", "En préparation"],
  ["17", "Reliquat"],
  ["18", "Disponible"],
  ["19", "Prête"],
  ["20", "Expédiée"],
  ["25", "Livré"],
]);
class SyncToropShop implements SyncType {
  public readonly name = "ToropShop";
  public readonly componentCreate = StepToropShop;
  public getStatuts(): Promise<StatusValues> {
    return Promise.resolve(toropStatuts);
  }
}

class SyncPrestaShop implements SyncType {
  public readonly name = "PrestaShop";
  public readonly componentCreate = StepPrestashop;
  public getStatuts(): Promise<StatusValues> {
    return Promise.resolve(toropStatuts);
  }
}

const SyncTypes = new Map<string, SyncType>([
  ["prestashop", new SyncPrestaShop()],
  ["toropshop", new SyncToropShop()],
]);

export default SyncTypes;
