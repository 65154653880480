import * as React from "react";
import {
  Snackbar,
  SnackbarContent,
  IconButton,
  makeStyles,
  createStyles,
  Theme,
} from "@material-ui/core";
import { Error as ErrorIcon, Close as CloseIcon } from "@material-ui/icons";
import { stitch, AnvokError, utils } from "pickup-lib";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      backgroundColor: theme.palette.error.dark,
      flexWrap: "nowrap",
    },
    icon: {
      fontSize: 20,
      opacity: 0.9,
      marginRight: theme.spacing(1),
    },
    message: {
      display: "flex",
      alignItems: "center",
    },
  })
);

const ErrorHandlerContext = React.createContext<React.Dispatch<any>>(
  // tslint:disable-next-line:no-empty
  (action: any) => {}
);

interface Props {
  children: React.ReactNode;
}

const reducer: React.Reducer<string[], any> = (state, err) => {
  if (typeof err === "string") {
    if (err === "__close") {
      return state.slice(1);
    } else {
      return [...state, err];
    }
  } else if (utils.CancelablePromise.isCanceled(err)) {
    return [...state];
  } else if (stitch.isTransportError(err)) {
    return [
      ...state,
      "Impossible de joindre le serveur, vérifiez votre connexion",
    ];
  } else if (stitch.isRuleError(err)) {
    return [...state, "Opération non autorisée"];
  } else if (stitch.isDupeError(err)) {
    return [...state, "L'enregistrement existe déja"];
  } else if (err instanceof Error) {
    return [...state, err.message];
  } else if (err instanceof AnvokError) {
    return [...state, err.message];
  }
  return [...state, "Une erreur inconnue s'est produite"];
};

const ErrorHandler = (props: Props) => {
  const [errors, errorHandler] = React.useReducer(reducer, []);
  const classes = useStyles();

  function onClose() {
    errorHandler("__close");
  }

  return (
    <ErrorHandlerContext.Provider value={errorHandler}>
      {props.children}
      <div id="errorhandler">
        {errors.length > 0 && (
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={true}
          >
            <SnackbarContent
              className={classes.content}
              message={
                <span className={classes.message}>
                  <ErrorIcon className={classes.icon} />
                  {errors[0]}
                </span>
              }
              action={[
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  onClick={onClose}
                >
                  <CloseIcon />
                </IconButton>,
              ]}
            />
          </Snackbar>
        )}
      </div>
    </ErrorHandlerContext.Provider>
  );
};

export default ErrorHandler;

export const useErrorHandler = () => {
  return React.useContext(ErrorHandlerContext);
};
