import * as React from "react";
import {
  Paper,
  createStyles,
  Theme,
  makeStyles,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Fab,
  IconButton,
} from "@material-ui/core";
import { Add as AddIcon, Settings as SettingsIcon } from "@material-ui/icons";
import { db } from "pickup-lib";
import AppContext from "../AppContext";
import { useErrorHandler } from "../ErrorHandler";
import LoaderRow from "./components/LoaderRow";
import useRouter from "use-react-router";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
    },
    fab: {
      position: "fixed",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  })
);

const SyncList: React.FunctionComponent<{}> = () => {
  const [loading, setLoading] = React.useState("Chargement...");
  const [syncs, setSyncs] = React.useState<db.Sync[]>([]);
  const { repos } = React.useContext(AppContext);
  const { history } = useRouter();
  const errorHandler = useErrorHandler();
  const classes = useStyles();

  React.useEffect(() => {
    repos!
      .getSyncs()
      .list()
      .then(result => {
        setSyncs(result);
        setLoading("");
      })
      .catch(errorHandler);
  }, [repos, errorHandler]);

  return (
    <Paper elevation={1} className={classes.root}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Clé</TableCell>
            <TableCell>Intitulé</TableCell>
            <TableCell>Dernière mise à jour</TableCell>
            <TableCell>&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading && <LoaderRow cols={3} message={loading} />}
          {syncs.map(s => (
            <TableRow key={s._id!.toHexString()}>
              <TableCell>{s.key}</TableCell>
              <TableCell>{s.label}</TableCell>
              <TableCell>{s.lastUpdate ? s.lastUpdate : "jamais"}</TableCell>
              <TableCell align="right">
                <IconButton
                  onClick={() => history.push("/sync/" + s._id!.toHexString())}
                >
                  <SettingsIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Fab
        color="secondary"
        className={classes.fab}
        onClick={() => history.push("/sync")}
      >
        <AddIcon />
      </Fab>
    </Paper>
  );
};

export default SyncList;
