import * as React from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  TableRow,
  TableCell,
} from "@material-ui/core";
import { db } from "pickup-lib";
import { stockTxt, stockItemClass } from "./Utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    warnStock: {
      color: "#ffa000",
    },
    errStock: {
      color: theme.palette.error.main,
    },
    options: {
      color: "rgb(100,100,100)",
      fontSize: 12,
    },
  })
);

interface Props {
  item: db.OrderItem;
}

const ArticlesDetailsRow: React.FunctionComponent<Props> = ({ item }) => {
  const classes = useStyles();
  const classname = stockItemClass(item, classes.warnStock, classes.errStock);
  return (
    <TableRow>
      <TableCell className={classname}>{item.ref}</TableCell>
      <TableCell className={classname} align="right">
        {stockTxt(item)}
      </TableCell>
      <TableCell className={classname}>
        <div>{item.label}</div>
        {item.options && Boolean(item.options.length) && (
          <div className={classes.options}>{item.options.join(", ")}</div>
        )}
      </TableCell>
    </TableRow>
  );
};

export default ArticlesDetailsRow;
