import * as React from "react";
import {
  makeStyles,
  createStyles,
  Theme,
  Fab,
  Chip,
  Avatar,
  IconButton,
} from "@material-ui/core";
import { Add as AddIcon, Edit as EditIcon } from "@material-ui/icons";
import { db } from "pickup-lib";
import ItemTypeEdit from "./ItemTypesEdit";
import { ItemTypesContext } from "../../Contexts";
import CardFlex from "screens/components/CardFlex";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      margin: theme.spacing(1),
    },
  })
);

interface Props {
  className?: string;
}

const ItemTypes: React.FC<Props> = ({ className }) => {
  const [loading, setLoading] = React.useState(true);
  const [showDialog, setShowDialog] = React.useState(false);
  const [current, setCurrent] = React.useState<db.ItemType | undefined>(
    undefined
  );
  const contextTypes = React.useContext(ItemTypesContext);
  const classes = useStyles();

  React.useEffect(() => {
    setLoading(false);
  }, [contextTypes]);

  function openEdit(type?: db.ItemType) {
    setCurrent(type);
    setShowDialog(true);
  }

  const actions = (
    <Fab
      color="primary"
      size="small"
      onClick={() => openEdit()}
      aria-label="Ajouter"
    >
      <AddIcon />
    </Fab>
  );

  return (
    <CardFlex
      title="Types article"
      actions={actions}
      loading={Boolean(loading)}
    >
      {contextTypes.types.map(i => (
        <div key={i._id.toHexString()}>
          <Chip
            className={classes.chip}
            avatar={<Avatar>{i.key}</Avatar>}
            label={i.label}
          />
          <IconButton
            style={{ float: "right" }}
            onClick={() => openEdit(i)}
          >
            <EditIcon />
          </IconButton>
        </div>
      ))}
      <ItemTypeEdit
        show={showDialog}
        value={current}
        onClose={loading => {
          setLoading(loading);
          setShowDialog(false);
        }}
      />
    </CardFlex>
  );
};

export default ItemTypes;
