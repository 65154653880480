import * as React from "react";
import { Warning as WarnIcon } from "@material-ui/icons";
import { Theme, createStyles, makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      whiteSpace: "nowrap",
      color: theme.palette.error.main,
    },
    icon: {
      fontSize: 18,
      verticalAlign: "text-bottom",
      color: theme.palette.error.main,
    },
  })
);

interface Props {
  text: string;
  className?: string;
}

const ErrorText = React.forwardRef<HTMLSpanElement, Props>(
  (props: Props, ref?: React.Ref<HTMLSpanElement>) => {
    const classes = useStyles();
    return (
      <span
        {...props}
        ref={ref}
        className={clsx(classes.root, props.className)}
      >
        <WarnIcon className={classes.icon} /> {props.text}
      </span>
    );
  }
);

export default ErrorText;
