import {
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
  Button,
} from "@material-ui/core";
import * as React from "react";
import { io } from "pickup-lib";
import AppContext from "../../../AppContext";

export type EndCallBack = (importer: io.ImporterInterface) => void;

interface Props {
  onEnd: EndCallBack;
}

interface State {
  op?: number;
}

interface Importer {
  create: (reader: io.FlatFileReader) => io.ImporterInterface;
  desc: React.ReactElement;
  fileTypes: string;
}

class Step1Prepa extends React.Component<Props, State> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;
  private importers: Importer[] = [
    {
      create: (reader: io.FlatFileReader): io.ImporterInterface => {
        return new io.ItemsImporter(reader, this.context.repos!.getItems());
      },
      desc: (
        <span>
          Importer des <b>articles</b> depuis un fichier (XLS, XLSX, CSV)
        </span>
      ),
      fileTypes:
        ".csv,.xls,.xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel",
    },
    {
      create: (reader: io.FlatFileReader): io.ImporterInterface => {
        return new io.OrderImporter(reader, this.context.repos!.getOrders());
      },
      desc: (
        <span>
          Importer des <b>commandes</b> depuis un fichier (XLS, XLSX, CSV)
        </span>
      ),
      fileTypes:
        ".csv,.xls,.xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel",
    },
  ];
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  public render() {
    const { op } = this.state;
    return (
      <div>
        <FormControl component={"fieldset" as "div"}>
          <FormLabel component={"legend" as "label"}>
            Choix de l'opération
          </FormLabel>
          <RadioGroup
            aria-label="Opération"
            name="op"
            value={op !== undefined ? op.toString() : ""}
            onChange={this.handleOpChange}
          >
            {this.importers.map((element, index) => {
              return (
                <FormControlLabel
                  key={index.toString()}
                  value={index.toString()}
                  control={<Radio color="primary" />}
                  label={element.desc}
                />
              );
            })}
          </RadioGroup>
        </FormControl>
        {op !== undefined && (
          <div>
            <Button component="label" color="primary" variant="contained">
              Choisir mon fichier
              <input
                type="file"
                style={{ display: "none" }}
                accept={this.importers[op].fileTypes}
                onChange={this.handleFile}
              />
            </Button>
          </div>
        )}
      </div>
    );
  }

  private handleFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      this.props.onEnd(
        this.importers[this.state.op!].create(
          new io.FlatFileReader(event.target.files[0])
        )
      );
    }
  };

  private handleOpChange = (event: React.ChangeEvent<{}>, op: string) => {
    this.setState({ op: parseInt(op) });
  };
}

export default Step1Prepa;
