import {
  AppBar,
  createStyles,
  IconButton,
  Menu,
  MenuItem,
  Theme,
  Toolbar,
  Typography,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import UserIcon from "@material-ui/icons/AccountCircle";
import { Route } from "react-router-dom";
import * as React from "react";
import { Link } from "react-router-dom";
import AppContext from "../../AppContext";
import { UserContext } from "../../Contexts";
import Weight from "./Weight";
import NetworkState from "./NetworkState";

const HomeLink = (props: any) => <Link to="/" {...props} />;

const styles = (theme: Theme) =>
  createStyles({
    username: {
      flexGrow: 1,
      textAlign: "right",
      textDecoration: "none",
    },
  });

interface Props extends WithStyles<typeof styles> {}

interface State {
  menuAnchor: HTMLElement | null;
}

class TopBar extends React.Component<Props, State> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;
  constructor(props: Props) {
    super(props);
    this.state = {
      menuAnchor: null,
    };
  }

  public render() {
    const { classes } = this.props;
    const user = this.context.auth!.getUser();
    if (!user) {
      return null;
    }
    const { menuAnchor } = this.state;
    const isMenuOpen = Boolean(menuAnchor);
    return (
      <div>
        <AppBar position="fixed">
          <Toolbar>
            <IconButton color="inherit" aria-label="Menu">
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h6"
              component={HomeLink}
              color="inherit"
              noWrap={true}
              className={classes.username}
            >
              <UserContext.Consumer>
                {context => <span>{context.user.name}</span>}
              </UserContext.Consumer>
            </Typography>
            <Route path="/orders/:id?" exact component={Weight} />
            <NetworkState />
            <IconButton
              color="inherit"
              aria-owns={isMenuOpen ? "material-appbar" : undefined}
              aria-haspopup="true"
              onClick={this.handleProfileMenuOpen}
            >
              <UserIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Menu
          anchorEl={menuAnchor}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          open={isMenuOpen}
          onClose={this.handleMenuClose}
          getContentAnchorEl={null}
        >
          <MenuItem onClick={this.handleMenuParamClick}>Paramètres</MenuItem>
          <MenuItem onClick={this.handleMenuExitClick}>Déconnecter</MenuItem>
        </Menu>
      </div>
    );
  }

  private closeMenu = () => {
    this.setState(current => ({ ...current, menuAnchor: null }));
  };

  private handleMenuClose = (event: React.SyntheticEvent<{}, Event>) => {
    this.closeMenu();
  };

  private handleProfileMenuOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    const target = event.currentTarget;
    this.setState(current => ({ ...current, menuAnchor: target }));
  };

  private handleMenuParamClick = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    this.closeMenu();
  };

  private handleMenuExitClick = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    this.context.auth!.logOff();
    this.closeMenu();
  };
}

export default withStyles(styles)(TopBar);
