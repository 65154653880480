"use strict";

var __values = this && this.__values || function (o) {
  var s = typeof Symbol === "function" && Symbol.iterator,
      m = s && o[s],
      i = 0;
  if (m) return m.call(o);
  if (o && typeof o.length === "number") return {
    next: function next() {
      if (o && i >= o.length) o = void 0;
      return {
        value: o && o[i++],
        done: !o
      };
    }
  };
  throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var Observers =
/** @class */
function () {
  function Observers() {
    this.observers = [];
  }

  Observers.prototype.add = function (fct) {
    var _this = this;

    this.observers.push(fct);
    var i = this.observers.length - 1;
    return function () {
      delete _this.observers[i];
    };
  };

  Observers.prototype.trigger = function (data) {
    var e_1, _a;

    try {
      for (var _b = __values(Object.values(this.observers)), _c = _b.next(); !_c.done; _c = _b.next()) {
        var fct = _c.value;
        fct(data);
      }
    } catch (e_1_1) {
      e_1 = {
        error: e_1_1
      };
    } finally {
      try {
        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
      } finally {
        if (e_1) throw e_1.error;
      }
    }
  };

  return Observers;
}();

exports.Observers = Observers;