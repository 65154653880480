import * as React from "react";
import {
  Theme,
  makeStyles,
  createStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import AppContext from "AppContext";
import { AnvokError } from "pickup-lib";
import { useErrorHandler } from "ErrorHandler";
import useRouter from "use-react-router";
import LoaderInline from "../components/LoaderInline";
import SnackbarStatic from "../components/SnackbarStatic";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "95%",
      maxWidth: "300",
    },
  })
);

type onCloseCallback = () => void;

interface Props {
  ids: string[];
  onClose: onCloseCallback;
}

const CreatePicking: React.FunctionComponent<Props> = React.memo(
  ({ ids, onClose }) => {
    const [loading, setLoading] = React.useState("Traitement des commandes...");
    const [error, setError] = React.useState("");
    const [warn, setWarn] = React.useState("");
    const [redirectUrl, setRedirectUrl] = React.useState();
    const { repos } = React.useContext(AppContext);
    const { history } = useRouter();
    const errorHandler = useErrorHandler();
    const classes = useStyles();

    React.useEffect(() => {
      const repo = repos!.getPickings();
      repo
        .create(ids)
        .then(r => {
          let redirect = true;
          if (r.errors.length > 0) {
            setError(`${r.errors.length} commandes non trouvées`);
            redirect = false;
          }
          if (r.warns.length > 0) {
            setWarn(`${r.warns.length} commandes ignorées car déja préparées`);
            redirect = false;
          }
          if (redirect) {
            history.push("/picking/" + r.id);
          } else {
            setRedirectUrl("/picking/" + r.id);
          }
        })
        .catch(err => {
          errorHandler(
            new AnvokError(
              "Une erreur s'est produite pendant la création",
              "err",
              err
            )
          );
          onClose();
        })
        .finally(() => {
          setLoading("");
        });
    }, [ids, repos, errorHandler, onClose, history]);

    const handleContinue = React.useCallback(() => {
      history.push(redirectUrl);
    }, [history, redirectUrl]);

    const handleClose = () => {
      onClose();
    };

    return (
      <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="types-form-dialog-title"
        classes={{ paper: classes.root }}
      >
        <DialogTitle id="types-form-dialog-title">
          Préparation de la liste de picking
        </DialogTitle>
        <DialogContent>
          <LoaderInline show={Boolean(loading)} message={loading} />
          {Boolean(error) && (
            <SnackbarStatic variant="error">{error}</SnackbarStatic>
          )}
          {Boolean(warn) && (
            <SnackbarStatic variant="warning">{warn}</SnackbarStatic>
          )}
        </DialogContent>
        <DialogActions>
          {!Boolean(loading) && <Button color="primary" onClick={handleContinue}>Continuer</Button>}
        </DialogActions>
      </Dialog>
    );
  }
);

export default CreatePicking;
