import * as React from "react";
import {
  createStyles,
  Theme,
  makeStyles,
  Tooltip,
  Grid,
  Popover,
  Fade,
} from "@material-ui/core";
import {
  List as ListIcon,
  Archive as ArchiveIcon,
  EuroSymbol as EuroIcon,
} from "@material-ui/icons";
import { db } from "pickup-lib";
import ErrorText from "../components/ErrorText";
import { TransitionProps } from "@material-ui/core/transitions";
import clsx from "clsx";
import ArticlesDetails from "./ArticlesDetails";
import { stockOrderClass } from "./Utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: "1 0.5 200px",
      fontSize: 12,
      lineHeight: 1.5,
      color: "rgba(0,0,0,0.54)",
      padding: theme.spacing(1),
    },
    light: {
      whiteSpace: "nowrap",
      color: "rgb(90,90,90)",
    },
    lightIcon: {
      fontSize: 18,
      verticalAlign: "text-bottom",
      color: "rgb(200,200,200)",
    },
    content: {
      fontSize: 12,
      fontFamily: '"Courier New", monospace',
      lineHeight: 1.7,
    },
    warn: {
      whiteSpace: "nowrap",
      color: theme.palette.error.main,
    },
    warnIcon: {
      fontSize: 18,
      verticalAlign: "text-bottom",
      color: theme.palette.error.main,
    },
    warnStock: {
      color: "#ffa000",
    },
    errStock: {
      color: theme.palette.error.main,
    },
    popover: {
      pointerEvents: "none",
    },
    popoverPaper: {
      padding: theme.spacing(1),
      fontSize: 13,
    },
  })
);

interface Props {
  order: db.Order;
}

const Transition = React.forwardRef<unknown, TransitionProps>(
  function Transition(props, ref) {
    return <Fade ref={ref} {...props} />;
  }
);

const ContentCell: React.FunctionComponent<Props> = ({ order }) => {
  const articlesRef = React.useRef(null);
  const [showArticles, setShowArticles] = React.useState(false);
  const classes = useStyles();
  const weight =
    order.internal && order.internal.weight
      ? (order.internal.weight / 1000).toFixed(2)
      : 0;
  const stockClassName = stockOrderClass(order, classes.warnStock, classes.errStock);
  return (
    <Grid item className={classes.root}>
      {order.internal && (
        <div className={classes.content}>
          <span
            className={clsx(classes.light, stockClassName)}
            ref={articlesRef}
            onMouseEnter={() => setShowArticles(true)}
            onMouseLeave={() => setShowArticles(false)}
          >
            <ListIcon className={clsx(classes.lightIcon, stockClassName)} />{" "}
            {order.internal.count} Articles ({order.internal.distinct}&ne;){" "}
          </span>{" "}
          <span className={classes.light}>
            <ArchiveIcon className={classes.lightIcon} /> {` ${weight}Kg`}
          </span>{" "}
          <span className={classes.light}>
            <EuroIcon className={classes.lightIcon} />{" "}
            {order.totalTtc.toFixed(2).replace(".", ",")}
          </span>{" "}
          {order.internal.missing.length > 0 && (
            <Tooltip title={order.internal.missing.join(", ")}>
              <ErrorText
                text={order.internal.missing.length + " Références inconnues"}
              />
            </Tooltip>
          )}
          {showArticles && (
            <Popover
              open={true}
              anchorEl={articlesRef.current}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              className={classes.popover}
              classes={{
                paper: classes.popoverPaper,
              }}
              disableRestoreFocus
              TransitionComponent={Transition}
            >
              <ArticlesDetails items={order.items} />
            </Popover>
          )}
        </div>
      )}
    </Grid>
  );
};

export default ContentCell;
