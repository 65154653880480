import * as React from "react";
import {
  makeStyles,
  createStyles,
  Theme,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Chip,
  Avatar,
} from "@material-ui/core";
import Loader from "../components/Loader";
import { db, stitch } from "pickup-lib";
import AppContext from "../../AppContext";
import { useErrorHandler } from "../../ErrorHandler";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 300,
    },
    content: {},
    actions: {
      position: "absolute",
      bottom: 0,
      borderTop: "1px solid rgba(0,0,0,0.12)",
      textAlign: "right",
      width: "100%",
    },
    btn: {
      marginLeft: "auto",
    },
    chip: {
      margin: theme.spacing(1),
    },
  })
);

type CloseCallback = (loading: boolean) => void;

interface Props {
  show: boolean;
  value?: db.ItemType;
  onClose?: CloseCallback;
}

const ItemTypeEdit: React.FunctionComponent<Props> = ({
  show,
  value,
  onClose,
}) => {
  const [loading, setLoading] = React.useState(false);
  const [key, setKey] = React.useState("");
  const [keyError, setKeyError] = React.useState("");
  const [label, setLabel] = React.useState("");
  const [labelError, setLabelError] = React.useState("");
  const context = React.useContext(AppContext);
  const errorHandler = useErrorHandler();
  const classes = useStyles();

  React.useEffect(() => {
    if (value) {
      setKey(value.key);
      setLabel(value.label);
    } else {
      setKey("");
      setLabel("");
    }
    setKeyError("");
    setLabelError("");
  }, [value]);

  if (!show) {
    if (loading) {
      setLoading(false);
    }
    return null;
  }

  function handleDelete() {
    if (value) {
      setLoading(true);
      context
        .repos!.getItemTypes()
        .delete(value)
        .then(() => {
          close(true);
        });
    }
  }

  function close(loading: boolean) {
    if (onClose) {
      onClose(loading);
    }
  }

  function handleSave() {
    let error = false;
    if (label.trim().length === 0) {
      setLabelError("Valeur requise");
      error = true;
    }
    if (!key || key.trim().length === 0) {
      setKeyError("Valeur requise");
      error = true;
    }
    if (error) {
      return;
    }

    setLoading(true);
    context
      .repos!.getItemTypes()
      .save({ ...(value ? value : {}), key, label })
      .then(() => {
        close(true);
      })
      .catch(err => {
        if (stitch.isDupeError(err)) {
          setKeyError("Déja utilisée par un autre type");
          setLoading(false);
          return;
        }
        errorHandler(err);
      });
  }

  return (
    <Dialog
      open={true}
      onClose={() => close(false)}
      aria-labelledby="types-form-dialog-title"
      classes={{ paper: classes.root }}
    >
      <Loader show={Boolean(loading)}>
        <DialogTitle id="types-form-dialog-title">
          Ajout type article
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Clé"
            value={key}
            error={Boolean(keyError)}
            helperText={keyError}
            fullWidth
            onChange={e => {
              if (e.target.value.trim().length > 3) {
                return;
              }
              setKey(e.target.value.trim().toUpperCase());
            }}
          />
          <TextField
            label="Intitulé"
            value={label}
            error={Boolean(labelError)}
            helperText={labelError}
            fullWidth
            onChange={e => {
              if (e.target.value.length > 20) {
                return;
              }
              setLabel(e.target.value);
            }}
          />
          <div>
            <Chip
              className={classes.chip}
              avatar={<Avatar>{key}</Avatar>}
              label={label}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDelete}
            style={{ visibility: value ? "visible" : "hidden" }}
            color="default"
          >
            Supprimer
          </Button>
          <Button onClick={() => close(false)} color="primary">
            Annuler
          </Button>
          <Button onClick={handleSave} color="primary">
            Enregistrer
          </Button>
        </DialogActions>
      </Loader>
    </Dialog>
  );
};

export default ItemTypeEdit;
