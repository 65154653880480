import * as React from "react";
import {
  createStyles,
  Paper,
  Theme,
  Fab,
  Button,
  makeStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
} from "@material-ui/core";
import { Add as AddIcon, Edit as EditIcon } from "@material-ui/icons";
import LoaderRow from "./components/LoaderRow";
import { db } from "pickup-lib";
import AppContext from "../AppContext";
import { Link } from "react-router-dom";
import useReactRouter from "use-react-router";
import { ItemTypesContext } from "../Contexts";
import ChipGroup from "./components/ChipGroup";
import { useErrorHandler } from "../ErrorHandler";
import clsx from "clsx";

const AddLink = React.forwardRef((props: any, ref) => (
  <Link to="/item" ref={ref} {...props} />
));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      overflowX: "auto",
    },
    table: {
      minWidth: 700,
    },
    fab: {
      position: "fixed",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    chip: {
      margin: theme.spacing(0.5),
    },
    altrefs: {
      color: "rgb(100,100,100)",
      fontSize: 10,
    },
    disabled: {
      color: "rgb(200,200,200)",
      textDecoration: "line-through",
    },
  })
);

const ItemList: React.FunctionComponent = () => {
  const [loading, setLoading] = React.useState(true);
  const [items, setItems] = React.useState<db.Item[]>([]);
  const context = React.useContext(AppContext);
  const contextTypes = React.useContext(ItemTypesContext);
  const { history } = useReactRouter();
  const errorHandler = useErrorHandler();
  const classes = useStyles();

  const labels: Map<string, string> = React.useMemo(() => {
    const r = new Map<string, string>();
    contextTypes.types.forEach(v => r.set(v.key, v.label));
    return r;
  }, [contextTypes]);

  React.useEffect(() => {
    context
      .repos!.getItems()
      .list({ ref: 1 })
      .then(items => {
        setItems(items);
        setLoading(false);
      })
      .catch(errorHandler);
  }, [context.repos, errorHandler]);

  return (
    <Paper className={classes.root} elevation={1}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Ref</TableCell>
            <TableCell>Libellé</TableCell>
            <TableCell align="center">Types</TableCell>
            <TableCell>Stock</TableCell>
            <TableCell>Emplacement</TableCell>
            <TableCell>&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <LoaderRow
            show={loading}
            message="Chargement des données..."
            cols={5}
          />
          {items.map(item => (
            <TableRow key={item._id!.toHexString()}>
              <TableCell>
                <Tooltip title={item.disabled ? "Article désactivé" : ""}>
                  <span className={item.disabled ? classes.disabled : ""}>
                    {item.ref}
                  </span>
                </Tooltip>
                <br />
                {item.altRefs && (
                  <span
                    className={clsx(
                      classes.altrefs,
                      item.disabled ? classes.disabled : ""
                    )}
                  >
                    {item.altRefs.join(", ")}
                  </span>
                )}
              </TableCell>
              <TableCell>{item.label}</TableCell>
              <TableCell align="center">
                <ChipGroup
                  value={item.types}
                  labels={labels}
                  chipClassName={classes.chip}
                />
              </TableCell>
              <TableCell>
                {item.stock}
                {Boolean(item.stockReappro) &&
                  ` (${
                    item.stockReappro && item.stockReappro > 0
                      ? "+" + item.stockReappro
                      : item.stockReappro
                  })`}
              </TableCell>
              <TableCell>{item.place}</TableCell>
              <TableCell align="right">
                <Button
                  color="primary"
                  onClick={() =>
                    history.push(`/item/${item._id!.toHexString()}`)
                  }
                >
                  <EditIcon />
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Fab color="secondary" className={classes.fab} component={AddLink}>
        <AddIcon />
      </Fab>
    </Paper>
  );
};

export default ItemList;
