import * as React from "react";
import { Select, MenuItem, FormControl, InputLabel } from "@material-ui/core";
import { db, utils } from "pickup-lib";
import { SyncActionConfigComponent } from "./SyncActions";
import { SyncContext } from "./SyncContext";
import { useErrorHandler } from "ErrorHandler";

function validateConfig(config: db.SyncActionSetRemoteStateConfig): boolean {
  return Boolean(config.type) && Boolean(config.state) && Boolean(config.label);
}

const ActionSetRemoteStateConfig: SyncActionConfigComponent<db.SyncActionSetRemoteStateConfig> = ({
  config,
  onChange,
}) => {
  const [states, setStates] = React.useState<Map<string, string>>();
  const errorHandler = useErrorHandler();
  const context = React.useContext(SyncContext);

  const state = config.state === undefined ? "" : config.state;
  const label = config.label === undefined ? "" : config.label;
  if (!onChange) {
    return (
      <span>
        Changer le statut sur le site marchand en <strong>{label}</strong>
      </span>
    );
  }

  function handleSelectChange(
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) {
    const state: string | undefined = event.target.value as string;
    const label = utils.Maps.valueByKey(states, state, "Inconnu");
    const newconfig = { ...config, state, label };
    if (onChange) {
      onChange(newconfig, validateConfig(newconfig));
    }
  }

  function handleSelectOpen() {
    if (!states) {
      context
        .type!.getStatuts()
        .then(s => setStates(s))
        .catch(errorHandler);
    }
  }

  const menuItems = states
    ? Array.from(states).map(([k, v]) => (
        <MenuItem key={k} value={k}>
          {v}
        </MenuItem>
      ))
    : [
        <MenuItem key="__loading" value="">
          <em>Chargement des statuts...</em>
        </MenuItem>,
        <MenuItem key={state} value={state}>
          {label}
        </MenuItem>,
      ];

  return (
    <>
      <FormControl fullWidth required>
        <InputLabel>Passer au statut</InputLabel>
        <Select
          name="state"
          value={state}
          onChange={handleSelectChange}
          onOpen={handleSelectOpen}
        >
          {menuItems}
        </Select>
      </FormControl>
    </>
  );
};

export default ActionSetRemoteStateConfig;
