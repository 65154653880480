import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
  Button,
} from "@material-ui/core";
import * as React from "react";
import Loader from "../Loader";
import { io } from "pickup-lib";
import SnackbarGroup from "../SnackbarGroup";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      overflow: "auto",
      margin: "auto",
      padding: theme.spacing(3),
    },
    button: {
      margin: theme.spacing(1),
    },
    content: {
      minHeight: 80,
    },
    loader: {
      maxWidth: 400,
    },
  });

type EndCallBack = () => void;

interface Props extends WithStyles<typeof styles> {
  importer: io.ImporterInterface;
  create: boolean;
  onEnd?: EndCallBack;
}

interface State {
  loading: string | null;
  errors: string[];
  result?: io.ImportResult;
}

class Step4Do extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: "Import des enregistrements...",
      errors: [],
    };
  }

  public componentDidMount() {
    this.props.importer.on("progress", this.handleProgress);
    this.props.importer
      .import(this.props.create)
      .then(result => {
        this.setState({ result, loading: "" });
      })
      .catch(err => {
        this.setState({ errors: [String(err)], loading: "" });
      });
  }

  public componentWillUnmount() {
    this.props.importer.off("progress", this.handleProgress);
  }

  private handleProgress = (value: number, total: number) => {
    this.setState({ loading: `Import des enregistrements ${value}/${total}` });
  };

  private handleEnd = () => {
    if (this.props.onEnd) {
      this.props.onEnd();
    }
  };

  public render() {
    const { classes } = this.props;
    const { loading, result } = this.state;

    const errors: string[] = new Array<string>().concat(
      this.state.errors,
      result ? result.errors : []
    );
    const infos: string[] = new Array<string>().concat(
      result ? result.messages : []
    );

    return (
      <div className={classes.content}>
        <Loader
          className={classes.loader}
          show={Boolean(loading)}
          message={loading}
        >
          <SnackbarGroup info={infos} error={errors} />
          <Button
            className={classes.button}
            component="label"
            color="primary"
            variant="contained"
            onClick={this.handleEnd}
          >
            Terminer
          </Button>
        </Loader>
      </div>
    );
  }
}

export default withStyles(styles)(Step4Do);
