"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var Form =
/** @class */
function () {
  function Form() {}

  Form.handleChange = function (dispatch) {
    return function (event) {
      var value = event.target.value;
      dispatch(value);
    };
  };

  Form.handleChangeProp = function (dispatch, name) {
    return function (event) {
      var value = event.target.value;
      dispatch(function (c) {
        var _a;

        return __assign(__assign({}, c), (_a = {}, _a[name] = value, _a));
      });
    };
  };

  Form.handleChangeCheck = function (dispatch) {
    return function (event, checked) {
      dispatch(checked);
    };
  };

  Form.handleChangeSelect = function (dispatch, def, chain) {
    return function (event) {
      var value = Boolean(event.target.value) ? event.target.value : def || "";
      dispatch(value);

      if (chain) {
        chain(value);
      }
    };
  };

  return Form;
}();

exports.Form = Form;