import * as React from "react";
import { ArrowDownward as ArrowIcon } from "@material-ui/icons";
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      cursor: "pointer",
      display: "inline-flex",
      justifyContent: "flex-start",
      flexDirection: "inherit",
      alignItems: "center",
    },
    arrow: {
      transition: theme.transitions.create(["opacity", "transform"], {
        duration: theme.transitions.duration.shorter,
      }),
    },
    arrowDescending: { transform: "rotate(0deg)" },
    arrowIndeterminate: {
      opacity: 0,
    },
    arrowAscending: {
      transform: "rotate(180deg)",
    },
  })
);

export type OrderDirection = "asc" | "desc" | undefined;
type onChangeCallback = (state: OrderDirection) => void;

interface Props {
  defaultDirection?: OrderDirection;
  state?: OrderDirection;
  children?: React.ReactNode;
  onChange?: onChangeCallback;
  className?: string;
  style?: React.CSSProperties;
}

const SortLabel: React.FunctionComponent<Props> = React.forwardRef(
  (
    { defaultDirection, state, children, onChange, className, style },
    ref: React.Ref<HTMLSpanElement>
  ) => {
    defaultDirection = defaultDirection || "asc";
    const classes = useStyles();
    const handleClick = React.useCallback(() => {
      if (onChange) {
        onChange(state ? (state === "asc" ? "desc" : "asc") : defaultDirection);
      }
    }, [onChange, defaultDirection, state]);

    const arrowStateClass = state
      ? state === "asc"
        ? classes.arrowAscending
        : classes.arrowDescending
      : classes.arrowIndeterminate;

    return (
      <span
        onClick={handleClick}
        className={clsx(classes.root, className)}
        style={style}
        ref={ref}
      >
        {children}
        <ArrowIcon className={clsx(classes.arrow, arrowStateClass)} />
      </span>
    );
  }
);

export default SortLabel;
