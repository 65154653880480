import * as React from "react";
import _ from "lodash";
import {
  createStyles,
  Paper,
  Theme,
  WithStyles,
  withStyles,
  Fab,
  TextField,
  Typography,
  InputAdornment,
  Switch,
  FormControlLabel,
  IconButton,
  Grid,
  Box,
} from "@material-ui/core";
import {
  ArrowBack as BackIcon,
  SaveOutlined as SaveIcon,
} from "@material-ui/icons";
import FormHelper from "../utils/FormHelper";
import Loader from "./components/Loader";
import { RouteComponentProps } from "react-router-dom";
import { db, utils } from "pickup-lib";
import { ObjectId } from "bson";
import AppContext from "../AppContext";
import ItemComponents from "./components/ItemComponents";
import Packagings from "./item/Packagings";
import SelectItemType from "./components/SelectItemType";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "50%",
      maxWidth: 500,
      margin: "auto",
      padding: theme.spacing(3),
    },
    input: { width: "100%", marginTop: 5, marginBottom: 5 },
    chip: {
      margin: theme.spacing(1),
    },
    chips: {
      display: "inline",
    },
    backbtn: {
      margin: theme.spacing(2),
      fontWeight: "bold",
      float: "left",
    },
  });

interface Params {
  id?: string;
}

interface Props
  extends WithStyles<typeof styles>,
    RouteComponentProps<Params> {}

interface State {
  loading: boolean;
  ref: string;
  refError?: string;
  label: string;
  labelError?: string;
  altRefs: string;
  place: string;
  stock: string;
  stockReappro: string;
  weight: string;
  capacity: string;
  components: db.ItemComponent[];
  componentsRefErrors: { [i: number]: string };
  componentsQtyErrors: { [i: number]: string };
  packagings: db.ItemPackaging[];
  packagingsLabelErrors: { [i: number]: string };
  packagingsQtyErrors: { [i: number]: string };
  types: string[];
  cielCatFisc: string;
  tav: string;
  tavError?: string;
  disabled: boolean;
  nopicking: boolean;
}

const initialState: State = {
  ref: "",
  label: "",
  altRefs: "",
  place: "",
  stock: "",
  stockReappro: "",
  weight: "",
  capacity: "",
  components: [],
  componentsRefErrors: {},
  componentsQtyErrors: {},
  packagings: [],
  packagingsLabelErrors: {},
  packagingsQtyErrors: {},
  types: [],
  cielCatFisc: "",
  tav: "",
  disabled: false,
  nopicking: false,
  loading: false,
};

class ItemEdit extends React.Component<Props, State> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;
  constructor(props: Props) {
    super(props);
    this.state = { ...initialState, loading: Boolean(props.match.params.id) };
  }

  public componentDidMount() {
    const id = this.props.match.params.id;
    if (id) {
      this.context
        .repos!.getItems()
        .get(id)
        .then(item => {
          if (item) {
            this.setState({
              ...initialState,
              ...item,
              stock:
                item.stock === undefined || item.stock === null
                  ? ""
                  : item.stock.toString(),
              stockReappro:
                item.stockReappro === undefined || item.stockReappro === null
                  ? ""
                  : item.stockReappro.toString(),
              altRefs: item.altRefs ? item.altRefs.join(", ") : "",
              weight: item.weight ? item.weight.toString() : "",
              capacity: item.capacity ? item.capacity.toString() : "",
              components: item.components ? item.components : [],
              packagings: item.packagings ? item.packagings : [],
              tav: item.tav ? item.tav.toString() : "",
              disabled: item.disabled ? item.disabled : false,
              nopicking: item.nopicking ? item.nopicking : false,
              loading: false,
            });
          } else {
            console.error(`Item not found #${id}`);
          }
        })
        .catch();
    }
  }

  public render() {
    const { classes } = this.props;
    const {
      loading,
      ref,
      refError,
      label,
      labelError,
      altRefs,
      place,
      stock,
      stockReappro,
      types,
      weight,
      capacity,
      components,
      componentsRefErrors,
      componentsQtyErrors,
      packagings,
      packagingsLabelErrors,
      packagingsQtyErrors,
      cielCatFisc,
      tav,
      tavError,
      disabled,
      nopicking,
    } = this.state;
    return (
      <Paper className={classes.root} elevation={1}>
        <Loader show={loading}>
          <form onSubmit={this.handleSubmit}>
            <Typography variant="h2">
              <IconButton onClick={() => this.props.history.push("/items")}>
                <BackIcon />
              </IconButton>
              {this.props.match.params.id ? "Modification" : "Création"} article
            </Typography>
            <TextField
              className={classes.input}
              value={ref}
              error={!!refError}
              name="ref"
              onChange={FormHelper.handleChange(this)}
              label="Référence"
              helperText={refError}
            />
            <TextField
              className={classes.input}
              value={label}
              error={!!labelError}
              name="label"
              onChange={FormHelper.handleChange(this)}
              label="Libellé"
              helperText={labelError}
            />
            <SelectItemType
              className={classes.input}
              value={types}
              onChange={types => this.setState({ types: types as string[] })}
            />
            <TextField
              className={classes.input}
              value={altRefs}
              name="altRefs"
              onChange={FormHelper.handleChange(this)}
              label="Référence alternatives"
            />
            <TextField
              className={classes.input}
              value={place}
              name="place"
              onChange={FormHelper.handleChange(this)}
              label="Emplacement"
            />
            <Box display="flex">
              <Box flexGrow={1}>
                <TextField
                  className={classes.input}
                  value={stock}
                  name="stock"
                  onChange={FormHelper.handleChange(this)}
                  label="Stock"
                  fullWidth
                />
              </Box>
              <Box flexGrow={2}>
                <TextField
                  className={classes.input}
                  value={stockReappro}
                  name="stockReappro"
                  onChange={FormHelper.handleChange(this)}
                  label="Réapprovisionnement en cours"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+</InputAdornment>
                    ),
                  }}
                  fullWidth
                />
              </Box>
            </Box>
            <TextField
              className={classes.input}
              value={weight}
              name="weight"
              onChange={FormHelper.handleChange(this)}
              label="Poids"
              InputProps={{
                endAdornment: <InputAdornment position="end">g</InputAdornment>,
              }}
            />
            <TextField
              className={classes.input}
              value={capacity}
              name="capacity"
              onChange={FormHelper.handleChange(this)}
              label="Contenance"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">ml</InputAdornment>
                ),
              }}
            />
            <TextField
              className={classes.input}
              value={cielCatFisc}
              name="cielCatFisc"
              onChange={FormHelper.handleChange(this)}
              label="Libellé fiscal"
            />
            <TextField
              className={classes.input}
              value={tav}
              error={!!tavError}
              name="tav"
              onChange={FormHelper.handleChange(this)}
              label="Titre alcoométrique volumique"
              helperText={tavError}
            />
            <Typography variant="h6">Composants</Typography>
            <ItemComponents
              value={components}
              refErrors={componentsRefErrors}
              qtyErrors={componentsQtyErrors}
              onChange={value => {
                this.setState({ components: value });
              }}
            />
            <Typography variant="h6">Conditionnements</Typography>
            <Packagings
              value={packagings}
              labelErrors={packagingsLabelErrors}
              qtyErrors={packagingsQtyErrors}
              onChange={packagings => this.setState({ packagings })}
            />

            <Grid container justify="space-between">
              <Grid item>
                <FormControlLabel
                  control={
                    <Switch
                      checked={nopicking}
                      onChange={FormHelper.handleCheckChange("nopicking", this)}
                      value="checkedA"
                    />
                  }
                  label="Picking désactivé"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={disabled}
                      onChange={FormHelper.handleCheckChange("disabled", this)}
                      value="checkedA"
                    />
                  }
                  label="Article désactivé"
                />
              </Grid>
              <Grid item>
                <Fab type="submit" color="secondary">
                  <SaveIcon />
                </Fab>
              </Grid>
            </Grid>
          </form>
        </Loader>
      </Paper>
    );
  }

  private checkForm(): boolean {
    let refError: string | undefined = undefined;
    let labelError: string | undefined = undefined;
    let tavError: string | undefined = undefined;
    if (this.state.ref.length < 2) {
      refError = "La référence doit contenir au moins 2 charactères";
    }
    if (this.state.label.length < 4) {
      labelError = "Le libellé doit contenir au moins 4 charactères";
    }
    if (
      this.state.tav.trim() !== "" &&
      utils.String.parseNumber(this.state.tav) === undefined
    ) {
      tavError = "La valeur doit être numérique";
    }
    const componentsRefErrors: { [i: number]: string } = {};
    const componentsQtyErrors: { [i: number]: string } = {};
    this.state.components.forEach((v, i) => {
      componentsRefErrors[i] = v.ref === "" ? "Référence requise" : "";
      componentsQtyErrors[i] = v.qty === 0 ? "Quantité requise" : "";
    });

    const packagingsLabelErrors: { [i: number]: string } = {};
    const packagingsQtyErrors: { [i: number]: string } = {};
    this.state.packagings.forEach((v, i) => {
      packagingsLabelErrors[i] = v.label === "" ? "Intitulé requis" : "";
      packagingsQtyErrors[i] = v.qty === 0 ? "Quantité requise" : "";
    });

    const hasError = ([] as string[])
      .concat(
        _.values(componentsRefErrors),
        _.values(componentsQtyErrors),
        _.values(packagingsLabelErrors),
        _.values(packagingsQtyErrors)
      )
      .some(v => Boolean(v));

    this.setState({
      refError,
      labelError,
      tavError,
      componentsRefErrors,
      componentsQtyErrors,
      packagingsLabelErrors,
      packagingsQtyErrors,
    });
    return !(refError || labelError || tavError || hasError);
  }

  private handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!this.checkForm()) {
      return;
    }

    this.setState({ loading: true });

    const item: db.NewItem = {
      ref: this.state.ref.trim(),
      altRefs: this.state.altRefs.split(",").map(v => v.trim()),
      label: this.state.label.trim(),
      place: this.state.place.trim(),
      stock: utils.String.parseNumber(this.state.stock),
      stockReappro: utils.String.parseNumber(this.state.stockReappro),
      types: this.state.types,
      weight: utils.String.parseNumber(this.state.weight),
      capacity: utils.String.parseNumber(this.state.capacity),
      components: this.state.components,
      packagings: this.state.packagings,
      cielCatFisc: this.state.cielCatFisc.trim(),
      tav:
        this.state.tav === ""
          ? undefined
          : utils.String.parseNumber(this.state.tav),
      disabled: this.state.disabled,
      nopicking: this.state.nopicking,
    };
    const id = this.props.match.params.id;
    if (id) {
      item._id = ObjectId.createFromHexString(id);
    }

    this.context
      .repos!.getItems()
      .save(item)
      .then(r => {
        this.props.history.push("/items");
      })
      .catch(err => {
        if (err.name === "StitchServiceError" && err.errorCode === 11) {
          this.setState({
            refError: "Cette référence existe déja",
            loading: false,
          });
        } else {
          this.setState({ loading: false });
        }
      });
  };
}

export default withStyles(styles)(ItemEdit);
