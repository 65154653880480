import * as React from "react";
import { Table, TableBody } from "@material-ui/core";
import { db } from "pickup-lib";
import ArticlesDetailsRow from "./ArticlesDetailsRow";

interface Props {
  items: db.OrderItem[];
}

const ArticlesDetails: React.FunctionComponent<Props> = ({ items }) => {
  return (
    <Table size="small">
      <TableBody>
        {items.map(i => (
          <ArticlesDetailsRow key={i.ref} item={i} />
        ))}
      </TableBody>
    </Table>
  );
};

export default ArticlesDetails;
