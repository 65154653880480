"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

var __values = this && this.__values || function (o) {
  var s = typeof Symbol === "function" && Symbol.iterator,
      m = s && o[s],
      i = 0;
  if (m) return m.call(o);
  if (o && typeof o.length === "number") return {
    next: function next() {
      if (o && i >= o.length) o = void 0;
      return {
        value: o && o[i++],
        done: !o
      };
    }
  };
  throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};

var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
      r,
      ar = [],
      e;

  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) {
      ar.push(r.value);
    }
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }

  return ar;
};

var __spread = this && this.__spread || function () {
  for (var ar = [], i = 0; i < arguments.length; i++) {
    ar = ar.concat(__read(arguments[i]));
  }

  return ar;
};

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var db = __importStar(require("../db"));

var utils_1 = require("../utils");

var GenericImporter_1 = require("./GenericImporter");

var defaultData = {
  ref: "",
  altRefs: [],
  label: "",
  types: [],
  components: [],
  packagings: [],
  place: ""
};

var ItemsImporter =
/** @class */
function (_super) {
  __extends(ItemsImporter, _super);

  function ItemsImporter(reader, repo) {
    var _this = _super.call(this, reader) || this;

    _this.batch = {
      update: [],
      insert: []
    };
    _this.batchSize = 100;
    _this.name = "Import articles";
    _this.key = "import_items";

    _this.flush = function () {
      if (_this.batch.update.length + _this.batch.insert.length === 0) {
        return Promise.resolve([]);
      }

      return _this.repo.batch(_this.batch).then(function (r) {
        _this.batch = {
          update: [],
          insert: []
        };
        return r.errors;
      });
    };

    _this.repo = repo;

    _this.fields.set("ref", {
      name: "Référence",
      parser: utils_1.String.parseString,
      required: true
    });

    _this.fields.set("altRefs", {
      name: "Référence alternatives",
      parser: utils_1.String.parseArray(utils_1.String.parseString)
    });

    _this.fields.set("ean", {
      name: "EAN/GTIN",
      parser: utils_1.String.parseString
    });

    _this.fields.set("label", {
      name: "Libellé",
      parser: utils_1.String.parseString
    });

    _this.fields.set("place", {
      name: "Emplacement",
      parser: utils_1.String.parseString
    });

    _this.fields.set("prix", {
      name: "Prix",
      parser: utils_1.String.parseNumber
    });

    _this.fields.set("weight", {
      name: "Poids",
      parser: utils_1.String.parseNumber
    });

    _this.fields.set("stock", {
      name: "Stock",
      parser: utils_1.String.parseNumber
    });

    _this.fields.set("stockReappro", {
      name: "Réapprovisionnement",
      parser: utils_1.String.parseNumber
    });

    _this.fields.set("stockPrev", {
      name: "Stock prévisionnel",
      parser: utils_1.String.parseNumber
    });

    _this.fields.set("capacity", {
      name: "Contenance",
      parser: utils_1.String.parseNumber
    });

    _this.fields.set("packagings", {
      name: "Conditionnements",
      parser: new db.ItemPackagingParser()
    });

    _this.fields.set("cielCatFisc", {
      name: "Libellé fiscal",
      parser: utils_1.String.parseString
    });

    _this.fields.set("tav", {
      name: "Titre alcoométrique volumique",
      parser: utils_1.String.parseNumber
    });

    return _this;
  }

  ItemsImporter.prototype.doDryRun = function () {
    var _this = this;

    return new Promise(function (res, rej) {
      var r = {
        messages: [],
        errors: [],
        count: 0,
        canCreate: false
      };

      if (_this.fields.get("stockPrev").map && !_this.fields.get("stock").map) {
        r.errors.push("Pour importer le stock prévisionnel, le stock doit également être importé");
      }

      if (_this.fields.get("stockPrev").map && _this.fields.get("stockReappro").map) {
        r.errors.push("Il n'est pas possible de combiner l'import du stock prévisionnel et du Réapprovisionnement");
      }

      if (r.errors.length) {
        res(r);
        return;
      }

      _this.getExistingRefs().then(function (refs) {
        _this.refs = refs;
        r.count = refs.length;

        _this.repo.countByRef(_this.refs).then(function (c) {
          if (c > 0 || refs.length > 0) {
            if (c > 0) {
              r.messages.push(c + " article(s) vont \xEAtre modifi\xE9s.");
            }

            if (refs.length - c > 0) {
              r.messages.push(refs.length - c + " article(s) sont iconnus.");
              r.canCreate = true;
            }
          } else {
            r.messages.push("Aucun article d\xE9tect\xE9.");
          }

          if (r.canCreate && !Boolean(_this.fields.get("label").map)) {
            r.canCreate = false;
            r.whyCantCreate = "Pour créer des articles il faut un fichier contenant le libellé";
          }

          res(r);
        });
      });
    });
  };

  ItemsImporter.prototype.getExistingRefs = function () {
    var _this = this;

    var refs = [];
    return new Promise(function (res) {
      var e_1, _a;

      try {
        for (var _b = __values(_this.reader), _c = _b.next(); !_c.done; _c = _b.next()) {
          var rows = _c.value;
          refs.push(rows[_this.fields.get("ref").map].toString().trim());
        }
      } catch (e_1_1) {
        e_1 = {
          error: e_1_1
        };
      } finally {
        try {
          if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        } finally {
          if (e_1) throw e_1.error;
        }
      }

      res(refs);
    });
  };

  ItemsImporter.prototype.import = function (create) {
    var _this = this;

    if (create === void 0) {
      create = false;
    }

    return new Promise(function (res) {
      var prom;

      if (!_this.refs) {
        prom = _this.dryrun();
      } else {
        prom = Promise.resolve();
      }

      prom.then(function () {
        _this.repo.findExistingRefs(_this.refs).then(function (existing) {
          return __awaiter(_this, void 0, void 0, function () {
            var errors, count, created, modified, _a, _b, row, data, _c, _d, _e, err_1, e_2_1, _f, _g, _h, err_2, result;

            var e_2, _j;

            return __generator(this, function (_k) {
              switch (_k.label) {
                case 0:
                  errors = [];
                  count = 0;
                  created = 0;
                  modified = 0;
                  _k.label = 1;

                case 1:
                  _k.trys.push([1, 8, 9, 10]);

                  _a = __values(this.reader), _b = _a.next();
                  _k.label = 2;

                case 2:
                  if (!!_b.done) return [3
                  /*break*/
                  , 7];
                  row = _b.value;
                  data = this.rowToData(row);

                  if (data.stockPrev !== undefined) {
                    data.stockReappro = data.stockPrev - (data.stock || 0);
                  }

                  delete data.stockPrev;

                  if (existing.has(data.ref.toLowerCase())) {
                    this.batch.update.push(__assign({
                      _id: existing.get(data.ref.toLowerCase())
                    }, data));
                    modified++;
                  } else if (create) {
                    this.batch.insert.push(__assign(__assign({}, defaultData), data));
                    created++;
                  }

                  count++;
                  this.emit("progress", count, this.refs.length);
                  if (!(count % this.batchSize === 0)) return [3
                  /*break*/
                  , 6];
                  _k.label = 3;

                case 3:
                  _k.trys.push([3, 5,, 6]);

                  _d = (_c = errors.push).apply;
                  _e = [errors];
                  return [4
                  /*yield*/
                  , this.flush()];

                case 4:
                  _d.apply(_c, _e.concat([__spread.apply(void 0, [_k.sent()])]));

                  return [3
                  /*break*/
                  , 6];

                case 5:
                  err_1 = _k.sent();
                  errors.push("Erreur de traitement: " + err_1);
                  return [3
                  /*break*/
                  , 6];

                case 6:
                  _b = _a.next();
                  return [3
                  /*break*/
                  , 2];

                case 7:
                  return [3
                  /*break*/
                  , 10];

                case 8:
                  e_2_1 = _k.sent();
                  e_2 = {
                    error: e_2_1
                  };
                  return [3
                  /*break*/
                  , 10];

                case 9:
                  try {
                    if (_b && !_b.done && (_j = _a.return)) _j.call(_a);
                  } finally {
                    if (e_2) throw e_2.error;
                  }

                  return [7
                  /*endfinally*/
                  ];

                case 10:
                  _k.trys.push([10, 12,, 13]);

                  _g = (_f = errors.push).apply;
                  _h = [errors];
                  return [4
                  /*yield*/
                  , this.flush()];

                case 11:
                  _g.apply(_f, _h.concat([__spread.apply(void 0, [_k.sent()])]));

                  return [3
                  /*break*/
                  , 13];

                case 12:
                  err_2 = _k.sent();
                  errors.push("Erreur de traitement: " + err_2);
                  return [3
                  /*break*/
                  , 13];

                case 13:
                  result = {
                    messages: [],
                    errors: errors,
                    count: count
                  };

                  if (modified) {
                    result.messages.push(modified + " articles modifi\xE9s");
                  }

                  if (created) {
                    result.messages.push(created + " articles ajout\xE9s");
                  }

                  if (modified + created === 0) {
                    result.messages.push("Aucun article modifié");
                  }

                  res(result);
                  return [2
                  /*return*/
                  ];
              }
            });
          });
        });
      });
    });
  };

  return ItemsImporter;
}(GenericImporter_1.GenericImporter);

exports.ItemsImporter = ItemsImporter;