import {
  Button,
  createStyles,
  Theme,
  WithStyles,
  withStyles,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from "@material-ui/core";
import * as React from "react";
import Loader from "../Loader";
import { io } from "pickup-lib";
import AppContext from "../../../AppContext";
import SnackbarGroup from "../SnackbarGroup";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      overflow: "auto",
      margin: "auto",
      padding: theme.spacing(3),
    },
    button: {
      margin: theme.spacing(1),
    },
    loader: {
      maxWidth: 300,
    },
  });

type EndCallBack = (create: boolean) => void;
type CancelCallBack = () => void;

interface Props extends WithStyles<typeof styles> {
  importer: io.ImporterInterface;
  onEnd: EndCallBack;
  onCancel: CancelCallBack;
}

interface State {
  loading: string | null;
  errors: string[];
  result?: io.DryRunResult;
  create: boolean;
}

class Step3Confirm extends React.Component<Props, State> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: "Recherche des articles existants...",
      errors: [],
      create: false,
    };
  }

  public componentDidMount() {
    this.props.importer
      .dryrun()
      .then(result => {
        this.setState({ result, loading: "" });
      })
      .catch(err => {
        this.setState({ errors: [String(err)], loading: "" });
      });
  }

  public render() {
    const { classes } = this.props;
    const { loading, result, create } = this.state;
    const errors: string[] = new Array<string>().concat(
      this.state.errors,
      result ? result.errors : []
    );
    const infos: string[] = new Array<string>().concat(
      result ? result.messages : []
    );
    let createCheckBox = (
      <FormControlLabel
        control={
          <Checkbox
            checked={create}
            onChange={this.handleCreateChange}
            value="1"
            disabled={Boolean(result && result.whyCantCreate)}
            color="primary"
          />
        }
        label="Créer les enregistrements inconnus"
      />
    );
    if (result && Boolean(result.whyCantCreate)) {
      createCheckBox = (
        <Tooltip title={result.whyCantCreate} placement="right">
          {createCheckBox}
        </Tooltip>
      );
    }
    return (
      <div>
        <Loader
          style={{ maxWidth: 400 }}
          show={Boolean(loading)}
          message={loading}
        >
          <SnackbarGroup error={errors} info={infos} />
          {result &&
            (result.canCreate || Boolean(result.whyCantCreate)) &&
            createCheckBox}
          <div>
            <Button
              className={classes.button}
              component="label"
              onClick={this.handleCancel}
            >
              Annuler
            </Button>
            <Button
              className={classes.button}
              component="label"
              color="primary"
              variant="contained"
              onClick={this.handleContinue}
              disabled={errors.length > 0}
            >
              Continuer
            </Button>
          </div>
        </Loader>
      </div>
    );
  }

  private handleCancel = () => {
    this.props.onCancel();
  };

  private handleContinue = () => {
    this.props.onEnd(this.state.create);
  };

  private handleCreateChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    this.setState({ create: checked });
  };
}

export default withStyles(styles)(Step3Confirm);
