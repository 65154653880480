"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var Maps =
/** @class */
function () {
  function Maps() {}

  Maps.valueByKey = function (map, key, defaultValue) {
    if (!map) {
      return defaultValue;
    }

    var value = map.get(key);
    return value === undefined ? defaultValue : value;
  };

  return Maps;
}();

exports.Maps = Maps;