"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var AnvokError =
/** @class */
function () {
  function AnvokError(message, severity, data) {
    this.anvokError = true;
    this.name = "anvokerror";
    this.message = message;
    this.severity = severity || "err";
    this.data = data;
  }

  AnvokError.isAnvokError = function (error) {
    return error instanceof AnvokError || error.anvokError;
  };

  return AnvokError;
}();

exports.default = AnvokError;