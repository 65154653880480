"use strict";

function __export(m) {
  for (var p in m) {
    if (!exports.hasOwnProperty(p)) exports[p] = m[p];
  }
}

Object.defineProperty(exports, "__esModule", {
  value: true
}); // Prevent crash when importing browser-sdk on node application

if (global && !global.self) {
  global.self = {};
}

__export(require("./StitchCollection"));

__export(require("./StitchDatabase"));

__export(require("./StitchProxy"));

__export(require("./StitchReadOperation"));

__export(require("./Types"));

__export(require("./Utils"));