import * as React from "react";
import {
  makeStyles,
  createStyles,
  Theme,
  Dialog,
  DialogContent,
  Slide,
} from "@material-ui/core";
import LoaderInline from "./LoaderInline";
import { TransitionProps } from "@material-ui/core/transitions";

const Transition = React.forwardRef<unknown, TransitionProps>(
  function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  }
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 400,
    },
  })
);

interface Props {
  children?: React.ReactNode;
}

const DialogLoading: React.FunctionComponent<Props> = ({ children }) => {
  const classes = useStyles();
  if (typeof children === "string") {
    children = <LoaderInline message={children}></LoaderInline>;
  }
  return (
    <Dialog
      open={true}
      aria-labelledby="types-form-dialog-title"
      classes={{ paper: classes.root }}
      TransitionComponent={Transition}
      keepMounted
    >
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

export default DialogLoading;
