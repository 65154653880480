import * as React from "react";

export default class FormHelper {
  public static handleChange = (component: React.Component, name?: string) => (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    if (!name) {
      name = event.target.name.toString();
    }
    const value = event.target.value;
    component.setState(current => ({
      [name!]: value,
    }));
  };

  public static handleChangeArray = (
    component: React.Component,
    index: number,
    name?: string
  ) => (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    if (!name) {
      name = event.target.name.toString();
    }
    const value = event.target.value;
    component.setState((current) => {
      const data: string[] = (current as any)[name!] ? (current as any)[name!] : [];
      data[index] = value;
      return {
        data,
      }
    })
  };

  public static handleCheckChange = (
    name: string,
    component: React.Component
  ) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    component.setState(current => ({
      ...current,
      [name]: value,
    }));
  };
}
