import * as React from "react";
import { TableRow, TableCell } from "@material-ui/core";
import LoaderInline from "./LoaderInline";

interface Props {
  show?: boolean;
  message?: React.ReactNode;
  cols: number;
}

const LoaderRow = (props: Props) => {
  if (props.show === false) {
    return null;
  }
  return (
    <TableRow>
      <TableCell colSpan={props.cols}>
        <LoaderInline message={props.message} />
      </TableCell>
    </TableRow>
  );
};

export default LoaderRow;
