import * as React from "react";
import {
  makeStyles,
  createStyles,
  Theme,
  TextField,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import { Add as AddIcon, Remove as RemoveIcon } from "@material-ui/icons";
import { db, utils } from "pickup-lib";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    line: {
      display: "flex",
    },
    inputlabel: {
      flexGrow: 1,
    },
    inputqty: {
      width: 150,
    },
    buttons: {
      textAlign: "right",
    },
  })
);

type ChangeCallback = (value: db.ItemPackaging[]) => void;

interface Props {
  value: db.ItemPackaging[];
  labelErrors?: { [i: number]: string };
  qtyErrors?: { [i: number]: string };
  onChange?: ChangeCallback;
}

const Packagings: React.FunctionComponent<Props> = ({
  value,
  labelErrors,
  qtyErrors,
  onChange,
}) => {
  const classes = useStyles();

  const handleChangeLabel = (index: number) => (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    value[index].label = event.target.value;
    if (onChange) {
      onChange(value);
    }
  };

  const handleChangeQty = (index: number) => (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    let v = utils.String.parseNumber(event.target.value, 0);
    value[index].qty = v;
    if (onChange) {
      onChange(value);
    }
  };

  const handleAdd = function() {
    value.push({ label: "", qty: 1 });
    if (onChange) {
      onChange(value);
    }
  };

  const handleRemove = function(index: number) {
    value.splice(index, 1);
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <>
      {value.map((v, i) => (
        <div className={classes.line} key={i}>
          <TextField
            label="Intitulé"
            name="label"
            value={v.label}
            className={classes.inputlabel}
            error={labelErrors && Boolean(labelErrors[i])}
            helperText={labelErrors ? labelErrors[i] : ""}
            onChange={handleChangeLabel(i)}
          />
          <TextField
            label="Unités"
            name="qty"
            type="number"
            value={v.qty}
            className={classes.inputqty}
            error={qtyErrors && Boolean(qtyErrors[i])}
            helperText={qtyErrors ? qtyErrors[i] : ""}
            onChange={handleChangeQty(i)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Retirer"
                    onClick={e => {
                      e.stopPropagation();
                      handleRemove(i);
                    }}
                  >
                    <RemoveIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
      ))}
      <div className={classes.buttons}>
        <IconButton aria-label="Ajouter correspondance" onClick={handleAdd}>
          <AddIcon />
        </IconButton>
      </div>
    </>
  );
};

export default Packagings;
