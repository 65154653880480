import * as React from "react";
import { Paper, createStyles, Theme, makeStyles } from "@material-ui/core";
import { db } from "pickup-lib";
import AppContext from "../AppContext";
import { useErrorHandler } from "../ErrorHandler";
import { FixedSizeList, areEqual, ListChildComponentProps } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import DialogLoading from "./components/DialogLoading";
import PickingListRow from "./picking/PickingListRow";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    header: {
      fontSize: 12,
      lineHeight: 1.5,
      color: "rgba(0,0,0,0.54)",
      padding: theme.spacing(1),
      borderBottom: "1px solid rgba(224,224,224,1)",
    },
    content: {
      flexGrow: 1,
      overflowX: "auto",
      overflowY: "hidden",
    },
    footer: {
      padding: theme.spacing(1),
    },
    fabs: {
      position: "absolute",
      bottom: theme.spacing(3),
      right: theme.spacing(4),
    },
    fab: {
      marginLeft: theme.spacing(1),
    },
  })
);

function itemKey(index: number, data: any) {
  return data.pickings[index]._id!.toHexString();
}

const Row = React.memo((props: ListChildComponentProps) => {
  const { index, style, data } = props;
  const picking: db.Picking = data.pickings[index];

  return (
    <div style={style}>
      <PickingListRow picking={picking} />
    </div>
  );
}, areEqual);

const PickingList: React.FunctionComponent<{}> = () => {
  const [loading, setLoading] = React.useState("Chargement des pickings...");
  const [pickings, setPickings] = React.useState<db.Picking[]>([]);
  const { repos } = React.useContext(AppContext);
  const errorHandler = useErrorHandler();
  const classes = useStyles();

  React.useEffect(() => {
    repos!
      .getPickings()
      .list({ created: -1 })
      .then(result => {
        setPickings(result);
        setLoading("");
      })
      .catch(errorHandler);
  }, [repos, errorHandler]);

  const data = React.useMemo(() => {
    return {
      pickings,
    };
  }, [pickings]);

  return (
    <Paper elevation={1} className={classes.root}>
      <>
        <div className={classes.content}>
          <AutoSizer>
            {({ width, height }) => {
              width = width < 1100 ? 1100 : width;
              return (
                <FixedSizeList
                  itemCount={pickings.length}
                  itemData={data}
                  itemSize={42}
                  height={height}
                  width={width}
                  itemKey={itemKey}
                >
                  {Row}
                </FixedSizeList>
              );
            }}
          </AutoSizer>
        </div>
      </>
      {loading && <DialogLoading>{loading}</DialogLoading>}
    </Paper>
  );
};

export default PickingList;
