import * as React from "react";
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
  IconButton,
  Fab,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  InputAdornment,
  ListItem,
  List,
} from "@material-ui/core";
import {
  Edit as EditIcon,
  Add as AddIcon,
  Remove as RemoveIcon,
  SubdirectoryArrowRight as TreeIcon,
} from "@material-ui/icons";
import Loader from "../components/Loader";
import AppContext from "../../AppContext";
import FormHelper from "../../utils/FormHelper";
import { db, utils } from "pickup-lib";
import AutoCompleteInput from "../components/AutoCompleteInput";
import CardFlex from "screens/components/CardFlex";

const styles = (theme: Theme) =>
  createStyles({
    buttons: { textAlign: "right" },
    icon: {
      fontSize: 18,
      verticalAlign: "text-bottom",
      color: "rgb(100,100,100)",
    },
    content: {
      padding: 0,
    },
    editCell: {
      flexGrow: 1,
      textAlign: "right",
    },
    dialogPaper: {
      minWidth: 600,
    },
  });

interface Props extends WithStyles<typeof styles> {
  className?: string;
}

interface State {
  dialog: boolean;
  key?: string;
  label: string;
  matches: string[];
  loading: boolean;
  livraisonModes?: string[];
}

class Config extends React.Component<Props, State> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;
  constructor(props: Props) {
    super(props);
    this.state = {
      dialog: false,
      label: "",
      matches: [""],
      loading: false,
    };
  }

  public render() {
    const { classes } = this.props;
    const { dialog, loading, key, label, matches, livraisonModes } = this.state;
    const carriers = this.context.org!.config.carriers;

    const actions = (
      <Fab
        color="primary"
        size="small"
        onClick={() => this.openDialog()}
        aria-label="Save"
      >
        <AddIcon />
      </Fab>
    );

    return (
      <CardFlex
        title="Transports"
        actions={actions}
        contentClass={classes.content}
        loading={loading}
      >
        <List>
          {carriers.map((c, i) => {
            return (
              <ListItem key={c.key}>
                <div>
                  <div>{c.label}</div>
                  {c.matches.map((v, i) => {
                    return (
                      <div key={i}>
                        <TreeIcon className={classes.icon} /> {v}
                      </div>
                    );
                  })}
                </div>
                <div className={classes.editCell}>
                  <IconButton
                    onClick={() => this.openDialog(i)}
                  >
                    <EditIcon />
                  </IconButton>
                </div>
              </ListItem>
            );
          })}
        </List>
        <Dialog
          open={dialog}
          onClose={this.closeDialog}
          aria-labelledby="carriers-form-dialog-title"
          classes={{ paper: classes.dialogPaper }}
        >
          <Loader show={loading} message="Veuillez patienter...">
            <DialogTitle id="carriers-form-dialog-title">
              Ajout transport
            </DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                value={label}
                name="label"
                label="Intitulé"
                type="text"
                onChange={FormHelper.handleChange(this)}
                fullWidth
              />
              <Typography variant="subtitle1" component="h3">
                Correspondances
              </Typography>
              {matches.map((v, i) => {
                return (
                  <AutoCompleteInput
                    options={livraisonModes}
                    key={i}
                    name="matches"
                    value={v}
                    onChange={value => this.onMatchChange(value, i)}
                    fullWidth
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Retirer correspondance"
                          onClick={e => {
                            e.stopPropagation();
                            this.removeMatch(i);
                          }}
                        >
                          <RemoveIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                );
              })}
              <div className={classes.buttons}>
                <IconButton
                  aria-label="Ajouter correspondance"
                  onClick={this.addMatch}
                >
                  <AddIcon />
                </IconButton>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.delete}
                style={{ visibility: key ? "visible" : "hidden" }}
                color="default"
              >
                Supprimer
              </Button>
              <Button onClick={this.closeDialog} color="primary">
                Annuler
              </Button>
              <Button onClick={this.save} color="primary">
                Enregistrer
              </Button>
            </DialogActions>
          </Loader>
        </Dialog>
      </CardFlex>
    );
  }

  private onMatchChange = (value: string, index: number) => {
    this.setState(current => {
      current.matches[index] = value;
      return {
        matches: current.matches,
      };
    });
  };

  private extractConfig(): db.ConfigCarrier {
    return {
      key: this.state.key
        ? this.state.key
        : utils.String.uniqKey(
            this.state.label,
            this.context.org!.config.carriers.map(v => v.key)
          ),
      label: this.state.label,
      matches: this.state.matches,
    };
  }

  private delete = (): void => {
    this.setState({ loading: true }, () => {
      const repo = this.context.repos!.getOrgs();
      repo.removeConfigCarrier(this.extractConfig());
    });
  };

  private save = (): void => {
    this.setState({ loading: true }, () => {
      const repo = this.context.repos!.getOrgs();
      if (this.state.key) {
        repo.updateConfigCarrier(this.extractConfig());
      } else {
        repo.addConfigCarrier(this.extractConfig());
      }
    });
  };

  private openDialog = (i?: number): void => {
    const state: Pick<
      State,
      "dialog" | "key" | "label" | "matches" | "loading"
    > = {
      dialog: true,
      key: undefined,
      label: "",
      matches: [],
      loading: false,
    };
    if (i !== undefined) {
      state.key = this.context.org!.config.carriers[i].key;
      state.label = this.context.org!.config.carriers[i].label;
      state.matches = [...this.context.org!.config.carriers[i].matches];
    }
    if (!this.state.livraisonModes) {
      state.loading = true;
      this.context
        .repos!.getOrders()
        .getDistinctsLivraisonModes()
        .then(modes => {
          this.setState({ loading: false, livraisonModes: modes });
        });
    }
    this.setState(state);
  };

  private closeDialog = (): void => {
    this.setState({ dialog: false, loading: false });
  };

  private removeMatch = (i: number): void => {
    this.setState(current => {
      current.matches.splice(i, 1);
      return {
        matches: current.matches,
      };
    });
  };

  private addMatch = (): void => {
    this.setState(current => {
      current.matches.push("");
      return {
        matches: current.matches,
      };
    });
  };
}

export default withStyles(styles)(Config);
