import * as React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  Input,
  MenuItem,
  Checkbox,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { ItemTypesContext } from "../../Contexts";
import ChipGroup from "./ChipGroup";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: theme.spacing(0.5),
    },
  })
);

type ChangeCallback = (value: string[]) => void;

interface Props {
  value: string[];
  onChange: ChangeCallback;
  label?: string;
  className?: string;
  style?: React.CSSProperties;
  fullWidth?: boolean;
}

let lastId = 0;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const SelectItemType: React.FunctionComponent<Props> = ({
  value,
  onChange,
  label,
  className,
  style,
  fullWidth,
}) => {
  const [htmlId] = React.useState(`selectitemtype-${lastId++}`);
  const contextTypes = React.useContext(ItemTypesContext);
  const classes = useStyles();

  const labels: Map<string, string> = React.useMemo(() => {
    const r = new Map<string, string>();
    contextTypes.types.forEach(v => r.set(v.key, v.label));
    return r;
  }, [contextTypes]);

  function handleChange(event: React.ChangeEvent<{ value: unknown }>) {
    onChange((event.target.value as string[]).filter(key => labels.has(key)));
  }

  return (
    <FormControl className={className} style={style} fullWidth={fullWidth}>
      <InputLabel htmlFor={htmlId}>{label ? label : "Types"}</InputLabel>
      <Select
        multiple
        value={value}
        onChange={handleChange}
        input={<Input id={htmlId} />}
        renderValue={selected => (
          <ChipGroup
            value={selected as string[]}
            labels={labels}
            className={classes.chips}
            chipClassName={classes.chip}
          />
        )}
        MenuProps={MenuProps}
      >
        {contextTypes.types.map(v => (
          <MenuItem key={v._id.toHexString()} value={v.key}>
            <Checkbox checked={value.indexOf(v.key) > -1} />
            <ChipGroup
              value={[v.key]}
              labels={labels}
              variant={value.indexOf(v.key) > -1 ? "outlined" : "default"}
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectItemType;
