import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
  TextField,
  Button,
  Link,
} from "@material-ui/core";
import * as React from "react";
import FormHelper from "../../utils/FormHelper";
import Loader from "../components/Loader";
import { IAuth } from "pickup-lib";

const styles = (theme: Theme) =>
  createStyles({
    main: {},
    input: { width: "100%", marginTop: 15, marginBottom: 15 },
    buttons: { textAlign: "right" },
    forgot: {
      marginBottom: 10,
      marginRight: 10,
      cursor: "pointer",
    },
  });

type onForgot = () => void;

interface Props extends WithStyles<typeof styles> {
  className?: string;
  auth: IAuth;
  onForgot: onForgot;
}

interface State {
  email: string;
  password: string;
  error: string;
  loading: boolean;
}

class LoginForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      error: "",
      loading: false,
    };
  }

  public render() {
    const classes = this.props.classes;
    return (
      <div className={this.props.className}>
        <h2>Se connecter</h2>
        <Loader show={this.state.loading}>
          <form onSubmit={this.handleSubmit}>
            <TextField
              name="email"
              className={classes.input}
              value={this.state.email}
              onChange={FormHelper.handleChange(this)}
              label="Email"
              error={Boolean(this.state.error)}
              helperText={this.state.error}
            />
            <TextField
              type="password"
              name="password"
              className={classes.input}
              value={this.state.password}
              onChange={FormHelper.handleChange(this)}
              label="Mot de passe"
              error={Boolean(this.state.error)}
              helperText={this.state.error}
            />
            <div className={classes.buttons}>
              <Link
                color="secondary"
                underline="hover"
                className={classes.forgot}
                onClick={this.props.onForgot}
              >
                Mot de passe oublié ?
              </Link>
              <Button type="submit" variant="contained" color="primary">
                Connecter
              </Button>
            </div>
          </form>
        </Loader>
      </div>
    );
  }

  private handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.setState({ loading: true });
    this.props.auth
      .logIn(this.state.email, this.state.password)
      .catch((err: any) => {
        this.setState({
          loading: false,
          error: "Unable to login, please check email and password",
          password: "",
        });
      });
  };
}

export default withStyles(styles)(LoginForm);
