"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var bson_1 = require("bson");

var UsersRepo =
/** @class */
function () {
  function UsersRepo(coll) {
    this.coll = coll;
  }

  UsersRepo.prototype.get = function (id) {
    if (!(id instanceof bson_1.ObjectId)) {
      id = bson_1.ObjectId.createFromHexString(id);
    }

    return this.coll.findOne({
      _id: id
    });
  };

  UsersRepo.prototype.list = function (sort, projection) {
    if (sort === void 0) {
      sort = {};
    }

    if (projection === void 0) {
      projection = {};
    }

    return this.coll.find({}, {
      sort: sort,
      projection: projection
    }).asArray();
  };

  return UsersRepo;
}();

exports.default = UsersRepo;