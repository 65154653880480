"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var CancelablePromise =
/** @class */
function () {
  function CancelablePromise(promise) {
    var _this = this;

    this.isCanceled = false;
    this[Symbol.toStringTag] = promise[Symbol.toStringTag];
    this.wrapped = new Promise(function (res, rej) {
      promise.then(function (r) {
        if (_this.isCanceled) {
          rej({
            isCanceledPromise: true
          });
        } else {
          res(r);
        }
      }).catch(function (r) {
        if (_this.isCanceled) {
          rej({
            isCanceledPromise: true
          });
        } else {
          rej(r);
        }
      });
    });
  }

  CancelablePromise.prototype.then = function (onfulfilled, onrejected) {
    return this.wrapped.then(onfulfilled, onrejected);
  };

  CancelablePromise.prototype.catch = function (onrejected) {
    return this.wrapped.catch(onrejected);
  };

  CancelablePromise.prototype.finally = function (onfinally) {
    return this.wrapped.finally(onfinally);
  };

  CancelablePromise.prototype.cancel = function () {
    this.isCanceled = true;
  };

  CancelablePromise.isCanceled = function (val) {
    if (val && val.isCanceledPromise) {
      return true;
    }

    return false;
  };

  CancelablePromise.makeCancelable = function (promise) {
    return new CancelablePromise(promise);
  };

  return CancelablePromise;
}();

exports.CancelablePromise = CancelablePromise;