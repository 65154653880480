import { db } from "pickup-lib";

export function stockItemClass(
  item: db.OrderItem,
  warnClass: string,
  errClass: string
): string | undefined {
  if (!item.internal) {
    return undefined;
  }
  return item.internal.instock
    ? undefined
    : item.internal!.instockReappro
    ? warnClass
    : errClass;
}

export function stockOrderClass(
  order: db.Order,
  warnClass: string,
  errClass: string
): string | undefined {
  if (!order.internal) {
    return undefined;
  }

  switch (order.internal.stock) {
    case db.OrderStock.Reappro:
      return warnClass;
    case db.OrderStock.Missing:
      return errClass;
  }
}

export function stockTxt(item: db.OrderItem): string {
  let stockTxt = "";
  if (item.internal) {
    if (!item.internal.instock) {
      stockTxt += item.internal.stock;
      stockTxt += `(+${item.internal.stockReappro})`;
      stockTxt += " / ";
    }
    stockTxt += item.qty.toString();
  }
  return stockTxt;
}
