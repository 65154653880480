import * as React from "react";
import {
  createStyles,
  Theme,
  makeStyles,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { db } from "pickup-lib";
import { ArrowRight as DisplayIcon } from "@material-ui/icons";
import SyncEvents, { SyncEventConfigComponent } from "./SyncEvents";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    treeIcon: {
      fontSize: 18,
      verticalAlign: "text-bottom",
      color: "rgb(100,100,100)",
    },
  })
);

const EventConfig: SyncEventConfigComponent<db.SyncEventConfig> = ({
  config,
  onChange,
}) => {
  const classes = useStyles();

  function handleSelectChange(
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) {
    let value: db.SyncEventTypes | undefined = event.target.value as db.SyncEventTypes;
    if (onChange && config.type !== value) {
      onChange({ ...config, type: value }, false);
    }
  }

  const type = config.type === undefined ? "" : config.type;
  const event = SyncEvents.get(type);
  const EventComponent = event ? event.componentConfig : null;

  if (!onChange) {
    return (
      <div>
        En cas de <strong>{SyncEvents.get(config.type)!.name}</strong>
        <div>
          <DisplayIcon className={classes.treeIcon} />
          {type && EventComponent && <EventComponent config={config} />}
        </div>
      </div>
    );
  }

  return (
    <>
      <FormControl fullWidth required>
        <InputLabel>En cas de</InputLabel>
        <Select name="type" value={type} onChange={handleSelectChange}>
          {Array.from(SyncEvents).map(([k, v]) => (
            <MenuItem key={k} value={k}>
              {v.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {type && EventComponent && (
        <EventComponent config={config} onChange={onChange} />
      )}
    </>
  );
};

export default EventConfig;
