import * as React from "react";
import {
  createStyles,
  Theme,
  makeStyles,
  IconButton,
  Slide,
  Dialog,
} from "@material-ui/core";
import { Close as CloseIcon, Print as PrintIcon } from "@material-ui/icons";
import { TransitionProps } from "@material-ui/core/transitions";
import { db } from "pickup-lib";
import ReactToPrint from "react-to-print";
import OrderDetails from "./OrderDetails";
import AppContext from "AppContext";
import { useErrorHandler } from "ErrorHandler";
import { OrderState } from "pickup-lib/dist/src/db";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { marginLeft: 240, marginTop: 65, boxShadow: "none" },
    hiddenPrint: {
      "@media print": {
        display: "none",
      },
    },
    btns: {
      position: "absolute",
      right: theme.spacing(2),
      zIndex: 10000,
      color: "white",
    },
    printBody: {
      padding: "10px",
    },
  })
);

const Transition = React.forwardRef<unknown, TransitionProps>(
  function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  }
);

type OnCloseCallBack = () => void;

interface Props {
  orders: db.Order[];
  print?: boolean;
  onClose?: OnCloseCallBack;
}

const OrderDetailsDialog: React.FunctionComponent<Props> = ({
  orders,
  print,
  onClose,
}) => {
  const componentRef = React.useRef(null);
  const [printElement, setPrintElement] = React.useState<ReactToPrint | null>(
    null
  );
  const { repos } = React.useContext(AppContext);
  const errorHandler = useErrorHandler();
  const classes = useStyles();

  const handleViewClose = function() {
    if (onClose) {
      onClose();
    }
  };

  React.useEffect(() => {
    if (print && printElement) {
      printElement.handlePrint();
    }
  }, [print, printElement]);

  const handleAfterPrint = React.useCallback(() => {
    if (print && printElement && onClose) {
      const ids = orders
        .filter(o => o.state === OrderState.Loaded)
        .map(o => o._id!); // Update only if order is not already picked
      repos!
        .getOrders()
        .updateState(ids, 20)
        .catch(errorHandler)
        .finally(onClose);
    }
  }, [print, printElement, onClose, orders, repos, errorHandler]);

  return (
    <Dialog
      fullScreen
      open={orders.length > 0}
      onClose={handleViewClose}
      TransitionComponent={Transition}
      hideBackdrop={true}
      className={classes.root}
      PaperProps={{ elevation: 0 }}
    >
      <div className={classes.btns}>
        <ReactToPrint
          bodyClass={classes.printBody}
          ref={ref => setPrintElement(ref)}
          trigger={() => (
            <IconButton
              color="inherit"
              aria-label="print"
              className={classes.hiddenPrint}
            >
              <PrintIcon />
            </IconButton>
          )}
          content={() => componentRef.current!}
          onAfterPrint={handleAfterPrint}
        />
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleViewClose}
          aria-label="close"
          className={classes.hiddenPrint}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <div ref={componentRef}>
        {orders.map(o => (
          <OrderDetails key={o._id!.toHexString()} order={o} />
        ))}
      </div>
    </Dialog>
  );
};

export default OrderDetailsDialog;
