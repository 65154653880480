"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var Types_1 = require("./Types");

function isDupeError(err) {
  return err.errorCode === 11 && err.errorCodeName === "MongoDBError" && (err.message.startsWith("Duplicate key error:") || err.message.startsWith("E11000 duplicate key error collection:"));
}

exports.isDupeError = isDupeError;

function isTransportError(err) {
  return err && typeof err === "object" && err.errorCode === Types_1.StitchRequestErrorCode.TRANSPORT_ERROR && err.errorCodeName === "TRANSPORT_ERROR";
}

exports.isTransportError = isTransportError;

function isRuleError(err) {
  return err && typeof err === "object" && err.errorCode === 13 && err.errorCodeName === "FunctionExecutionError" && err.message.startsWith("rule not matched for function");
}

exports.isRuleError = isRuleError;