import * as React from "react";
import {
  createStyles,
  Theme,
  makeStyles,
  Grid,
  Checkbox,
  LinearProgress,
  FormControl,
  Input,
  InputAdornment,
} from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";
import FilterMenu, { FilterMenuValue } from "screens/components/FilterMenu";
import SortLabel, { OrderDirection } from "screens/components/SortLabel";
import clsx from "clsx";
import AppContext from "AppContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontSize: 12,
      lineHeight: 1.5,
      color: "rgba(0,0,0,0.54)",
      padding: 0,
      borderBottom: "1px solid rgba(224,224,224,1)",
    },
    sort: {
      fontWeight: "bold",
    },
    state: {
      flex: "0 0 200px",
      display: "flex",
    },
    selectAll: {
      display: "inline-flex",
      justifyContent: "flex-start",
      flexDirection: "inherit",
      alignItems: "center",
    },
    sortDate: {
      flexGrow: 1,
      justifyContent: "center",
    },
    client: {
      flex: "3 1.5 600px",
      display: "flex",
    },
    loading: {
      fontWeight: "bold",
      display: "flex",
      flexGrow: 1,
      alignItems: "center",
      justifyContent: "center",
    },
    livraison: {
      flex: "1 1 150px",
      display: "flex",
    },
    progress: {
      height: 4,
    },
    filter: {
      paddingTop: 4,
    },
  })
);

type onSelectAllCallback = (checked: boolean) => void;
type onFilterCallback = (filter: FilterMenuValue) => void;
type onSortCallback = (sort: SortValue) => void;
type onSearchCallback = (search: string) => void;

export interface SortValue {
  key: string;
  state: OrderDirection;
}

interface Props {
  selected: number;
  count: number;
  loading: string;
  sort: SortValue;
  search: string;
  onSelectAllChange?: onSelectAllCallback;
  onFilterChange?: onFilterCallback;
  onSortChange?: onSortCallback;
  onSearchChange?: onSearchCallback;
  className?: string;
  style?: React.CSSProperties;
}

const OrderHeader: React.FunctionComponent<Props> = ({
  selected,
  count,
  loading,
  sort,
  search,
  onSelectAllChange,
  onFilterChange,
  onSortChange,
  onSearchChange,
  className,
  style,
}) => {
  const { org } = React.useContext(AppContext);
  const classes = useStyles();

  const configOrders = org!.config.orders;

  const handleSelectAllChange = React.useCallback(() => {
    if (onSelectAllChange) {
      onSelectAllChange(selected < count);
    }
  }, [onSelectAllChange, selected, count]);

  const handleSortChange = React.useCallback(
    (sort: SortValue) => {
      if (onSortChange) {
        onSortChange(sort);
      }
    },
    [onSortChange]
  );

  const handleSearchChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (onSearchChange) {
        onSearchChange(event.target.value.trim());
      }
    },
    [onSearchChange]
  );

  return (
    <Grid
      container
      justify="space-between"
      className={clsx(className, classes.root)}
      style={style}
    >
      <Grid item className={classes.state}>
        <span className={classes.selectAll}>
          <Checkbox
            color="default"
            checked={count > 0 && selected === count}
            indeterminate={selected > 0 && selected < count}
            onChange={handleSelectAllChange}
          />{" "}
          {selected}/{count}
        </span>
        <SortLabel
          defaultDirection="desc"
          className={clsx(classes.sort, classes.sortDate)}
          state={sort.key === "date" ? sort.state : undefined}
          onChange={v => handleSortChange({ key: "date", state: v })}
        >
          Date
        </SortLabel>
      </Grid>
      <Grid item className={classes.client}>
        <SortLabel
          className={classes.sort}
          state={sort.key === "client" ? sort.state : undefined}
          onChange={v => handleSortChange({ key: "client", state: v })}
        >
          Client
        </SortLabel>
        <div className={classes.loading}>{loading}</div>
      </Grid>
      <Grid item className={classes.livraison}>
        <SortLabel
          defaultDirection={configOrders.sortLivraisonReverse ? "desc" : "asc"}
          className={classes.sort}
          state={sort.key === "livraison" ? sort.state : undefined}
          onChange={v => handleSortChange({ key: "livraison", state: v })}
        >
          Livraison
        </SortLabel>
        <FormControl>
          <Input
            id="input-with-icon-adornment"
            value={search}
            onChange={handleSearchChange}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
          />
        </FormControl>
        <FilterMenu className={classes.filter} onChange={onFilterChange} />
      </Grid>
      <Grid item xs={12} className={classes.progress}>
        {loading && <LinearProgress variant="query" />}
      </Grid>
    </Grid>
  );
};

export default OrderHeader;
