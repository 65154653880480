import * as React from "react";
import { useMessageHandler, STATE } from "MessageHandler";
import {
  SignalCellularNull as IconOffline,
  SignalCellularConnectedNoInternet0Bar as IconConnecting,
  SignalCellular4Bar as IconConnected,
  SignalCellularConnectedNoInternet4Bar as IconDisconnected,
} from "@material-ui/icons";
import {
  makeStyles,
  createStyles,
  Theme,
  Popover,
  Typography,
  IconButton,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
    popover: {
      pointerEvents: "none",
    },
    paper: {
      padding: theme.spacing(1),
    },
  })
);

const icons: { [k in STATE]: JSX.Element } = {
  [STATE.Offline]: <IconOffline />,
  [STATE.Connecting]: <IconConnecting />,
  [STATE.Connected]: <IconConnected />,
  [STATE.Disconnected]: <IconDisconnected />,
};

const NetworkState: React.FunctionComponent = () => {
  const [state, setState] = React.useState<STATE>(STATE.Offline);
  const [error, setError] = React.useState();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const msg = useMessageHandler();
  const classes = useStyles();

  msg.on("state", (state, err) => {
    setState(state);
    setError(err);
  });

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <IconButton
        color="inherit"
        aria-owns={Boolean(anchorEl) ? "networkstate-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {icons[state]}
      </IconButton>
      {error && (
        <Popover
          id="networkstate-popover"
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography>{error}</Typography>
        </Popover>
      )}
    </div>
  );
};

export default NetworkState;
