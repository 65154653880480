import * as React from "react";
import { createStyles, Theme, makeStyles, Grid } from "@material-ui/core";
import { db } from "pickup-lib";
import { areEqual } from "react-window";
import StateCell from "./StateCell";
import AddressCell from "./AddressCell";
import ContentCell from "./ContentCell";
import ShippingCell, { OnCarrierChangeCallback } from "./ShippingCell";
import MoreCell, { OnPrintCallback } from "./MoreCell";
import Loader from "../components/Loader";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 95,
      borderBottom: "1px solid rgba(224,224,224,1)",
    },
    loading: {
      textAlign: "left",
      paddingLeft: 110,
    },
  })
);

type OnChangeCallback = (id: string, selected: boolean) => void;
export type OnWaitUpdateCallback = (id: string) => void;

interface Props {
  order: db.Order;
  selected: boolean;
  loading: boolean;
  onChange: OnChangeCallback;
  onCarrierChange: OnCarrierChangeCallback;
  onPrint: OnPrintCallback;
}

const OrderRow: React.FunctionComponent<Props> = React.memo(
  ({ order, selected, loading, onChange, onCarrierChange, onPrint }) => {
    const classes = useStyles();
    return (
      <Loader
        show={loading}
        className={classes.loading}
        backgroundStyle={{ background: "rgba(255,255,255,0.75)" }}
      >
        <Grid container className={classes.root}>
          <StateCell order={order} selected={selected} onChange={onChange} />
          <AddressCell order={order} />
          <ContentCell order={order} />
          <ShippingCell order={order} onCarrierChange={onCarrierChange} />
          <MoreCell order={order} onPrint={onPrint} />
        </Grid>
      </Loader>
    );
  },
  areEqual
);

export default OrderRow;
