import * as React from "react";
import {
  makeStyles,
  createStyles,
  Theme,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import Loader from "../components/Loader";
import { db } from "pickup-lib";
import AppContext from "../../AppContext";
import moment from "moment";
import "moment/locale/fr";
import { ObjectID } from "bson";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 400,
    },
  })
);

type CloseCallback = (id?: ObjectID, type?: db.DouaneType) => void;

interface Props {
  onClose: CloseCallback;
}

const Create: React.FunctionComponent<Props> = ({ onClose }) => {
  moment.locale("fr");
  const defaultLabel = `Inventaire du ${moment().format("LL")}`;
  const [loading, setLoading] = React.useState("");
  const [label, setLabel] = React.useState(defaultLabel);
  const [labelError, setLabelError] = React.useState("");
  const [type, setType] = React.useState<db.DouaneType>(
    db.DouaneType.FULL
  );
  const context = React.useContext(AppContext);
  const classes = useStyles();

  function handleTypeChange(event: React.ChangeEvent<{ value: unknown }>) {
    const type: db.DouaneType = event.target.value as db.DouaneType;
    const typeName = db.DouaneTypeLabel.get(type);
    setType(type);
    setLabel(`${typeName} du ${moment().format("LL")}`);
  }

  function handleSave() {
    if (label.trim().length === 0) {
      setLabelError("Valeur requise");
      return;
    }
    setLabelError("");
    setLoading("Préparation...");

    context
      .repos!.getItems()
      .find({ types: "AL" }, { place: 1, ref: 1 })
      .then(items => {
        setLoading(`Préparation de ${items.length} articles`);
        const woPlace = items.filter(i => !Boolean(i.place));
        items = items.slice(woPlace.length).concat(woPlace);
        context
          .repos!.getDouanes()
          .create(label, items, type)
          .then(id => {
            setLoading("");
            onClose(id, type);
          });
      });
  }

  return (
    <Dialog
      open={true}
      onClose={() => onClose()}
      aria-labelledby="types-form-dialog-title"
      classes={{ paper: classes.root }}
    >
      <Loader show={Boolean(loading)} message={loading}>
        <DialogTitle id="types-form-dialog-title">Création</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <InputLabel shrink>Type</InputLabel>
            <Select fullWidth value={type} onChange={handleTypeChange}>
              {Array.from(db.DouaneTypeLabel).map(([k, v]) => {
                return (
                  <MenuItem key={k} value={k}>
                    {v}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <TextField
            label="Intitulé"
            value={label}
            error={Boolean(labelError)}
            helperText={labelError}
            fullWidth
            onChange={e => {
              setLabel(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose()} color="primary">
            Annuler
          </Button>
          <Button onClick={handleSave} color="primary">
            Enregistrer
          </Button>
        </DialogActions>
      </Loader>
    </Dialog>
  );
};

export default Create;
