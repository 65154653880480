import { createStyles, Paper, Theme, makeStyles } from "@material-ui/core";
import * as React from "react";
import LoginForm from "./LoginForm";
import { IAuth } from "pickup-lib";
import useRouter from "use-react-router";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      display: "block", // Fix IE 11 issue.
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      marginTop: theme.spacing(3),
      [theme.breakpoints.up(400 + theme.spacing(3 * 2))]: {
        marginLeft: "auto",
        marginRight: "auto",
        maxWidth: 300,
        width: "50%",
      },
      width: "auto",
    },
    paper: {
      alignItems: "center",
      padding: theme.spacing(2),
    },
    login: {
      padding: theme.spacing(2),
    },
  })
);

interface Props {
  auth: IAuth;
}

const Login: React.FunctionComponent<Props> = ({ auth }) => {
  const classes = useStyles();
  const { history } = useRouter();
  return (
    <div className={classes.main}>
      <Paper className={classes.paper} elevation={1}>
        <LoginForm
          auth={auth}
          onForgot={() => {
            history.push("/passwordLost");
          }}
        />
      </Paper>
    </div>
  );
};

export default Login;
