import * as React from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  Card,
  CardHeader,
  CardContent,
  CardActions,
} from "@material-ui/core";
import clsx from "clsx";
import Loader from "./Loader";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
    },
    header: {
      borderBottomWidth: 1,
      borderBottomColor: theme.palette.divider,
      borderBottomStyle: "solid",
    },
    content: {
      flexGrow: 1,
    },
    actions: {
      borderTopWidth: 1,
      borderTopColor: theme.palette.divider,
      borderTopStyle: "solid",
      justifyContent: "right",
    },
  })
);

interface Props {
  title?: string;
  actions?: React.ReactNode;
  loading?: boolean;
  contentClass?: string;
  className?: string;
}

const CardFlex: React.FC<Props> = ({
  title,
  actions,
  loading,
  contentClass,
  className,
  children,
}) => {
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, className)}>
      {title && <CardHeader className={classes.header} title={title} />}
      <CardContent className={clsx(classes.content, contentClass)}>
        <Loader show={Boolean(loading)}>{children}</Loader>
      </CardContent>
      {actions && (
        <CardActions className={classes.actions}>{actions}</CardActions>
      )}
    </Card>
  );
};

export default CardFlex;
