import * as React from "react";
import {
  Paper,
  createStyles,
  Theme,
  makeStyles,
  Fab,
  Tooltip,
  Grid,
  Checkbox,
} from "@material-ui/core";
import { Unarchive as UnarchiveIcon } from "@material-ui/icons";
import { db } from "pickup-lib";
import AppContext from "../AppContext";
import { useErrorHandler } from "../ErrorHandler";
import { FixedSizeList, areEqual, ListChildComponentProps } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import useRouter from "use-react-router";
import DialogLoading from "./components/DialogLoading";
import ArchiveRow from "./archive/ArchiveRow";
import OrderDetailsDialog from "./orderlist/OrderDetailsDialog";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    header: {
      fontSize: 12,
      lineHeight: 1.5,
      color: "rgba(0,0,0,0.54)",
      padding: theme.spacing(1),
      borderBottom: "1px solid rgba(224,224,224,1)",
    },
    content: {
      flexGrow: 1,
      overflowX: "auto",
      overflowY: "hidden",
    },
    footer: {
      padding: theme.spacing(1),
    },
    fabs: {
      position: "absolute",
      bottom: theme.spacing(3),
      right: theme.spacing(4),
    },
    fab: {
      marginLeft: theme.spacing(1),
    },
  })
);

interface Params {
  id?: string;
}

function itemKey(index: number, data: any) {
  return data.orders[index]._id!.toHexString();
}

const Row = React.memo((props: ListChildComponentProps) => {
  const { index, style, data } = props;
  const order: db.Order = data.orders[index];
  const id: string = order._id!.toHexString();
  const selected: boolean = data.selected.indexOf(id) > -1;

  return (
    <div style={style}>
      <ArchiveRow
        order={order}
        selected={selected}
        onChange={data.handleChange}
      />
    </div>
  );
}, areEqual);

const OrderList: React.FunctionComponent<{}> = () => {
  const [loading, setLoading] = React.useState("Chargement des archives...");
  const [orders, setOrders] = React.useState<db.Order[]>([]);
  const [order, setOrder] = React.useState<db.Order>();
  const [selected, setSelected] = React.useState<string[]>([]);
  const { repos } = React.useContext(AppContext);
  const { match, history } = useRouter<Params>();
  const errorHandler = useErrorHandler();
  const classes = useStyles();

  React.useEffect(() => {
    const idx = orders.findIndex(o => {
      return o._id!.toHexString() === match.params.id;
    });
    setOrder(idx > -1 ? orders[idx] : undefined);
  }, [match.params.id, orders]);

  const handleUnarchiveClick = React.useCallback(() => {
    setLoading(`Mise à jour de ${selected.length} commandes`);
    repos!
      .getOrders()
      .archive(selected, false)
      .then(() => {
        setOrders(c =>
          c.filter(v => selected.indexOf(v._id!.toHexString()) === -1)
        );
        setSelected([]);
        setLoading("");
      })
      .catch(err => {
        setLoading("");
        errorHandler(err);
      });
  }, [errorHandler, repos, selected]);

  const handleChange = React.useCallback((id: string, selected: boolean) => {
    if (selected) {
      setSelected(c => [...c, id]);
    } else {
      setSelected(c => c.filter(v => v !== id));
    }
  }, []);

  const handleSelectAllChange = React.useCallback(() => {
    setSelected(c =>
      c.length < orders.length ? orders.map(o => o._id!.toHexString()) : []
    );
  }, [orders]);

  React.useEffect(() => {
    repos!
      .getOrders()
      .listOrders(true)
      .then(result => {
        setOrders(result);
        setLoading("");
      })
      .catch(errorHandler);
  }, [repos, errorHandler]);

  const data = React.useMemo(() => {
    return {
      orders,
      selected,
      handleChange,
    };
  }, [orders, selected, handleChange]);

  return (
    <Paper elevation={1} className={classes.root}>
      <>
        <Grid container justify="space-between" className={classes.header}>
          <Grid item>
            <Checkbox
              color="default"
              checked={selected.length === orders.length}
              indeterminate={
                selected.length > 0 && selected.length < orders.length
              }
              onChange={handleSelectAllChange}
            />{" "}
            {selected.length}/{orders.length}
          </Grid>
        </Grid>
        <div className={classes.content}>
          <AutoSizer>
            {({ width, height }) => {
              width = width < 1100 ? 1100 : width;
              return (
                <FixedSizeList
                  itemCount={orders.length}
                  itemData={data}
                  itemSize={42}
                  height={height}
                  width={width}
                  itemKey={itemKey}
                >
                  {Row}
                </FixedSizeList>
              );
            }}
          </AutoSizer>
        </div>
        {selected.length > 0 && (
          <div className={classes.fabs}>
            <Tooltip title="Désarchiver" aria-label="Archiver">
              <Fab
                color="primary"
                size="medium"
                className={classes.fab}
                onClick={handleUnarchiveClick}
              >
                <UnarchiveIcon />
              </Fab>
            </Tooltip>
          </div>
        )}
      </>
      {loading && <DialogLoading>{loading}</DialogLoading>}
      {order && (
        <OrderDetailsDialog
          orders={[order]}
          onClose={() => history.push("/archives")}
        />
      )}
    </Paper>
  );
};

export default OrderList;
