import * as React from "react";
import {
  createStyles,
  Paper,
  Theme,
  Fab,
  makeStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
} from "@material-ui/core";
import {
  Add as AddIcon,
  Done as ActiveIcon,
  Announcement as InviteIcon,
} from "@material-ui/icons";
import LoaderRow from "./components/LoaderRow";
import { db } from "pickup-lib";
import AppContext from "../AppContext";
import { useErrorHandler } from "../ErrorHandler";
import Invite from "./user/Invite";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      overflowX: "auto",
    },
    table: {
      minWidth: 700,
    },
    fab: {
      position: "fixed",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  })
);

const UserList: React.FunctionComponent = () => {
  const [loading, setLoading] = React.useState(false);
  const [invite, setInvite] = React.useState(false);
  const [users, setUsers] = React.useState<db.User[]>([]);
  const context = React.useContext(AppContext);
  const classes = useStyles();
  const errorHandler = useErrorHandler();

  React.useEffect(() => {
    setLoading(true);
    context
      .repos!.getUsers()
      .list({ name: 1 })
      .then(users => setUsers(users))
      .catch(errorHandler)
      .finally(() => setLoading(false));
  }, [context.repos, errorHandler]);

  function handleClose(created: boolean) {
    setInvite(false);
    if (created) {
      setLoading(true);
      context
        .repos!.getUsers()
        .list({ name: 1 })
        .then(users => setUsers(users))
        .catch(errorHandler)
        .finally(() => setLoading(false));
    }
  }

  return (
    <Paper className={classes.root} elevation={1}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Nom</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Etat</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <LoaderRow
            show={loading}
            message="Chargement des données..."
            cols={4}
          />
          {users.map(u => (
            <TableRow key={u._id!.toHexString()}>
              <TableCell>{u.name}</TableCell>
              <TableCell>{u.email}</TableCell>
              <TableCell> </TableCell>
              <TableCell>
                {u.password.length > 0 ? (
                  <Chip
                    size="small"
                    label="Actif"
                    color="primary"
                    icon={<ActiveIcon />}
                  />
                ) : (
                  <Chip
                    size="small"
                    label="Invité"
                    color="secondary"
                    icon={<InviteIcon />}
                  />
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Fab
        color="secondary"
        className={classes.fab}
        onClick={() => setInvite(true)}
      >
        <AddIcon />
      </Fab>
      {invite && <Invite onClose={handleClose} />}
    </Paper>
  );
};

export default UserList;
