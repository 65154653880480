import * as React from "react";
import _ from "lodash";
import {
  createStyles,
  Theme,
  makeStyles,
  AppBar,
  Toolbar,
  Typography,
  Grid,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  Select,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";
import { EuroSymbol as EuroIcon } from "@material-ui/icons";
import { db } from "pickup-lib";
import moment from "moment";
import "moment/locale/fr";
import Address from "screens/components/Address";
import clsx from "clsx";
import AppContext from "AppContext";
import { useErrorHandler } from "ErrorHandler";
import { stockItemClass, stockTxt } from "./Utils";
import RelaisId from "./RelaisId";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    appBar: {
      position: "relative",
      "@media print": {
        background: "none",
        color: "black",
      },
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    content: {
      padding: theme.spacing(2),
    },
    spanCell: {
      borderBottom: "none",
    },
    livrRefCell: {
      textTransform: "uppercase",
    },
    icon: {
      marginLeft: theme.spacing(1),
      verticalAlign: "text-bottom",
    },
    nowrap: {
      whiteSpace: "nowrap",
    },
    breakAfter: {
      "@media print": {
        breakAfter: "always",
      },
    },
    light: {
      whiteSpace: "nowrap",
      color: "rgb(90,90,90)",
    },
    lightIcon: {
      fontSize: 18,
      verticalAlign: "text-bottom",
      color: "rgb(200,200,200)",
    },
    relaisId: {
      marginLeft: 20,
    },
    carrierSelect: {
      minWidth: "50%",
      "@media print": {
        "&:before": {
          border: "none",
        },
      },
    },
    carrierSelectIcon: {
      "@media print": {
        display: "none",
      },
    },
    warnStock: {
      color: "#ffa000",
    },
    errStock: {
      color: theme.palette.error.main,
    },
    options: {
      color: "rgb(100,100,100)",
      fontSize: 14,
    },
  })
);

function renderCurrency(value: number): string {
  return value.toFixed(2).replace(".", ",");
}

interface Props {
  order: db.Order;
  className?: string;
}

const OrderDetails: React.FunctionComponent<Props> = ({ order, className }) => {
  const { org, repos } = React.useContext(AppContext);
  const [carrierChangeLoading, setCarrierChangeLoading] = React.useState(false);
  const errorHandler = useErrorHandler();
  const classes = useStyles();

  const sameAddr =
    order &&
    _.isEqual(
      order.facturation,
      _.pick(order.livraison, _.keys(order.facturation))
    );

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
      const value = event.target.value as string;
      setCarrierChangeLoading(true);
      repos!
        .getOrders()
        .updateCarrier(order._id!, value)
        .catch(errorHandler)
        .finally(() => setCarrierChangeLoading(false));
    },
    [order._id, repos, errorHandler]
  );

  return (
    <div className={clsx(classes.root, className)}>
      <AppBar className={classes.appBar}>
        <Toolbar variant="dense">
          <Typography variant="h6" className={classes.title}>
            {order.ref}
            {org!.config.orders.showSource && order.source
              ? " (" + order.source + ") "
              : " "}
            le {moment(order.date).utc().format("L")}{" "}
            {moment(order.date).utc().format("LT")}
          </Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.content}>
        <Grid container spacing={3}>
          <Grid item xs={6} md={6} lg>
            <Typography gutterBottom variant="h5">
              Facturation{sameAddr && " & Livraison"}
            </Typography>
            <Address
              address={order.facturation}
              refClient={order.refClient}
              loyalty={order.clientOrderCount}
            />
          </Grid>
          {!sameAddr && (
            <Grid item xs={6} md={6} lg>
              <Typography gutterBottom variant="h5">
                Livraison
              </Typography>
              <Address
                address={order.livraison}
                refClient={order.refClient}
                loyalty={order.clientOrderCount}
              />
            </Grid>
          )}
          <Grid item xs={6} md={6} lg>
            <Typography gutterBottom variant="h5">
              Expédition
            </Typography>
            <div>
              <span className={classes.light}>
                {order.livraison!.modeLabel}
              </span>
              <RelaisId
                className={clsx(classes.light, classes.relaisId)}
                id={order.livraison!.relaisId}
                address={order.livraison!.relaisAdr}
              />
            </div>
            {order.internal && (
              <div>
                <Select
                  className={classes.carrierSelect}
                  classes={{
                    icon: classes.carrierSelectIcon,
                  }}
                  value={order.internal.carrier}
                  onChange={handleChange}
                  readOnly={carrierChangeLoading}
                >
                  {org!.config.carriers.map((carrier) => {
                    return (
                      <MenuItem key={carrier.key} value={carrier.key}>
                        {carrier.label}
                      </MenuItem>
                    );
                  })}
                </Select>
                {carrierChangeLoading && (
                  <CircularProgress
                    key="progress"
                    color="secondary"
                    style={{ marginLeft: 10 }}
                    size={24}
                  />
                )}
              </div>
            )}
          </Grid>
        </Grid>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Référence</TableCell>
              <TableCell>Libellé</TableCell>
              <TableCell align="right">Quantité</TableCell>
              <TableCell align="right" className={classes.nowrap}>
                Total TTC
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {order.items.map((i, idx) => {
              const stockClassName = stockItemClass(
                i,
                classes.warnStock,
                classes.errStock
              );
              return (
                <TableRow key={idx}>
                  <TableCell className={stockClassName}>{i.ref}</TableCell>
                  <TableCell className={stockClassName}>
                    <div>{i.label}</div>
                    {i.options && Boolean(i.options.length) && (
                      <div className={classes.options}>
                        {i.options.join(", ")}
                      </div>
                    )}
                  </TableCell>
                  <TableCell className={stockClassName} align="right">
                    {stockTxt(i)}
                  </TableCell>
                  <TableCell align="right" className={classes.nowrap}>
                    {renderCurrency(i.ttc)}
                    <EuroIcon fontSize="small" className={classes.icon} />
                  </TableCell>
                </TableRow>
              );
            })}
            {order.livraison && (
              <TableRow>
                <TableCell className={classes.livrRefCell}>
                  {order.livraison.mode}
                </TableCell>
                <TableCell>{order.livraison.modeLabel}</TableCell>
                <TableCell align="right">1</TableCell>
                <TableCell align="right" className={classes.nowrap}>
                  {renderCurrency(order.livraison!.totalTtc)}
                  <EuroIcon fontSize="small" className={classes.icon} />
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell
                rowSpan={3}
                colSpan={2}
                className={classes.spanCell}
              ></TableCell>
              <TableCell align="right" className={classes.nowrap}>
                Total HT
              </TableCell>
              <TableCell align="right" className={classes.nowrap}>
                {renderCurrency(order.totalHt)}
                <EuroIcon fontSize="small" className={classes.icon} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="right" className={classes.nowrap}>
                Total TVA
              </TableCell>
              <TableCell align="right" className={classes.nowrap}>
                {renderCurrency(order.totalTva)}
                <EuroIcon fontSize="small" className={classes.icon} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="right" className={classes.nowrap}>
                Total TTC
              </TableCell>
              <TableCell align="right" className={classes.nowrap}>
                {renderCurrency(order.totalTtc)}
                <EuroIcon fontSize="small" className={classes.icon} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default OrderDetails;
