import * as React from "react";
import _ from "lodash";
import {
  createStyles,
  Theme,
  makeStyles,
  IconButton,
  Popover,
  Typography,
  Grid,
} from "@material-ui/core";
import {
  MapOutlined as MapIcon,
  Receipt as ReceiptIcon,
} from "@material-ui/icons";
import { db } from "pickup-lib";
import Address from "../components/Address";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: "2 1 400px",
      fontSize: 12,
      lineHeight: 1.5,
      color: "rgba(0,0,0,0.54)",
      padding: theme.spacing(1),
    },
    popover: {
      pointerEvents: "none",
    },
    popoverPaper: {
      padding: theme.spacing(1),
      fontSize: 13,
    },
  })
);

const mapLink = function (adr?: db.Address): string {
  if (!adr) {
    return "";
  }
  return `https://www.google.fr/maps?q=${adr.line1},${adr.postCode} ${adr.city},${adr.countryCode}`;
};

interface Props {
  order: db.Order;
}

const AddressCell: React.FunctionComponent<Props> = ({ order }) => {
  const classes = useStyles();
  const [showBilling, setShowBilling] = React.useState(false);
  const billingRef = React.useRef(null);

  return (
    <Grid item className={classes.root}>
      <IconButton
        color="primary"
        style={{ float: "right" }}
        href={mapLink(order.livraison)}
        target="_blank"
      >
        <MapIcon />
      </IconButton>
      {!_.isEqual(
        order.facturation,
        _.pick(order.livraison, _.keys(order.facturation))
      ) && (
        <IconButton
          ref={billingRef}
          color="secondary"
          style={{ float: "right" }}
          onMouseEnter={() => setShowBilling(true)}
          onMouseLeave={() => setShowBilling(false)}
        >
          <ReceiptIcon />
        </IconButton>
      )}
      {showBilling && (
        <Popover
          open={true}
          anchorEl={billingRef.current}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          className={classes.popover}
          classes={{
            paper: classes.popoverPaper,
          }}
          disableRestoreFocus
        >
          <Typography gutterBottom variant="h5" component="h2">
            Adresse facturation
          </Typography>
          <Address address={order.facturation} refClient={order.refClient} />
        </Popover>
      )}
      <Address address={order.livraison} refClient={order.refClient} loyalty={order.clientOrderCount} />
    </Grid>
  );
};

export default AddressCell;
