import EventOrderStatutConfig from "./EventOrderStatutConfig";
import EventOrderArchiveConfig from "./EventOrderArchiveConfig";
import { db } from "pickup-lib";

export interface EventCompontentProps<C extends db.SyncEventConfig> {
  config: C;
  onChange?: (config: C, valid: boolean) => void;
}

export type SyncEventConfigComponent<
  C extends db.SyncEventConfig
> = React.FunctionComponent<EventCompontentProps<C>>;

interface SyncEvent {
  name: string;
  componentConfig: React.FunctionComponent<EventCompontentProps<any>>;
}

class EventOrderStatut implements SyncEvent {
  public readonly name = "Changement de statut";
  public readonly componentConfig = EventOrderStatutConfig;
}

class EventOrderArchive implements SyncEvent {
  public readonly name = "Archivage";
  public readonly componentConfig = EventOrderArchiveConfig;
}

const SyncEvents = new Map<string, SyncEvent>([
  ["state", new EventOrderStatut()],
  ["archive", new EventOrderArchive()],
]);

export default SyncEvents;
