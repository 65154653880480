import * as React from "react";
import _ from "lodash";
import AppContext from "AppContext";
import { db, stitch } from "pickup-lib";
import { EventEmitter } from "events";

export enum STATE {
  Offline,
  Connecting,
  Connected,
  Disconnected,
}

let state = STATE.Offline;
function setState(newstate: STATE, err?: any) {
  state = newstate;
  listeners.forEach(l => l.handleStateChange(state, err));
}

const listeners: InternalListener[] = [];
class InternalListener {
  public readonly subjects?: string[];
  public readonly emitter: Emitter;
  constructor(emitter: Emitter, subjects?: string | string[]) {
    if (!Array.isArray(subjects)) {
      subjects = subjects ? [subjects.toString()] : undefined;
    }
    this.subjects = subjects;
    this.emitter = emitter;
  }

  public handleStateChange = (state: STATE, data?: any) => {
    this.emitter.emit("state", state);
  };

  public handleMessage = (message: db.Message) => {
    if (this.subjects && this.subjects.indexOf(message.subject) > -1) {
      this.emitter.emit(message.subject, message);
    }
  };
}

function handleStreamNext(msg: stitch.StitchChangeEvent<db.Message>) {
  listeners.forEach(l => l.handleMessage(msg.fullDocument!));
}

function handleStreamError(err: Error) {
  setState(STATE.Disconnected, err);
}

function addListener(l: InternalListener) {
  listeners.push(l);
}

function removeListener(l: InternalListener) {
  _.pull(listeners, l);
}

class Emitter extends EventEmitter {
  on(
    event: "weigh",
    listener: (msg: db.Message<db.WeighMessage>) => void
  ): this;
  on(
    event: "order",
    listener: (msg: db.Message<db.OrderMessage>) => void
  ): this;
  on(event: "user", listener: (msg: db.Message<db.UserMessage>) => void): this;
  on(event: "org", listener: (msg: db.Message<db.OrgMessage>) => void): this;
  on(event: "state", listener: (state: STATE, data?: any) => void): this;
  on(event: string | symbol, listener: (...args: any[]) => void): this {
    return super.on(event, listener);
  }
}

export const useMessageHandler = (subject?: string | string[]): Emitter => {
  const ctx = React.useContext(AppContext);

  React.useEffect(() => {
    if (ctx.proxy && state === STATE.Offline) {
      setState(STATE.Connecting);
      ctx.proxy.db
        .collection<db.Message>("messages")
        .watch({
          operationType: "insert",
        })
        .then(s => {
          setState(STATE.Connected);
          listeners.forEach(l => l.handleStateChange(state));
          s.onNext(handleStreamNext);
          s.onError(handleStreamError);
        })
        .catch(err => {
          setState(STATE.Offline, err);
          listeners.forEach(l => l.handleStateChange(state));
        });
    }
  }, [ctx.proxy]);

  const emitter = React.useMemo(() => {
    return new Emitter();
  }, []);

  const listener = React.useMemo(() => {
    return new InternalListener(emitter, subject);
  }, [subject, emitter]);

  React.useEffect(() => {
    addListener(listener);
    return () => {
      removeListener(listener);
    };
  }, [listener]);
  return emitter;
};
