"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var mongodb_stitch_core_services_mongodb_remote_1 = require("mongodb-stitch-core-services-mongodb-remote");

var mongodb_stitch_core_sdk_1 = require("mongodb-stitch-core-sdk");

exports.StitchOperationType = mongodb_stitch_core_services_mongodb_remote_1.OperationType;
exports.StitchRequestErrorCode = mongodb_stitch_core_sdk_1.StitchRequestErrorCode;