import { db } from "pickup-lib";
import ActionSetRemoteStateConfig from "./ActionSetRemoteStateConfig";

export interface ActionCompontentProps<C extends db.SyncActionConfig> {
  config: C;
  onChange?: (config: C, valid: boolean) => void;
}

export type SyncActionConfigComponent<
  C extends db.SyncActionConfig
> = React.FunctionComponent<ActionCompontentProps<C>>;

interface SyncAction {
  name: string;
  componentConfig: React.FunctionComponent<ActionCompontentProps<any>>;
}

class ActionSetRemoteState implements SyncAction {
  public readonly name = "Changer le statut sur le site marchand";
  public readonly componentConfig = ActionSetRemoteStateConfig;
}

const SyncActions = new Map<string, SyncAction>([
  ["setremotestate", new ActionSetRemoteState()],
]);

export default SyncActions;