import * as React from "react";
import {
  Fab,
  List,
} from "@material-ui/core";
import { Save as SaveIcon } from "@material-ui/icons";
import AppContext from "../../AppContext";
import { utils } from "pickup-lib";
import ListItemSwitch from "screens/components/ListItemSwitch";
import CardFlex from "screens/components/CardFlex";

interface Props {
  className?: string;
}

const Commande: React.FC<Props> = ({ className }) => {
  const [loading, setLoading] = React.useState(false);
  const [showSource, setShowSource] = React.useState(false);
  const [sortLivraisonReverse, setSortLivraisonReverse] = React.useState(false);
  const [pickingPrintOrders, setPickingPrintOrders] = React.useState(false);
  const [
    pickingShowMissingPlaces,
    setPickingShowMissingPlaces,
  ] = React.useState(true);
  const [pickingShowMissingRefs, setPickingShowMissingRefs] = React.useState(
    true
  );
  const context = React.useContext(AppContext);

  React.useEffect(() => {
    setShowSource(context.org!.config.orders.showSource);
    setSortLivraisonReverse(context.org!.config.orders.sortLivraisonReverse);
    setPickingPrintOrders(context.org!.config.orders.pickingPrintOrders);
    setPickingShowMissingRefs(
      context.org!.config.orders.pickingShowMissingRefs
    );
    setPickingShowMissingPlaces(
      context.org!.config.orders.pickingShowMissingPlaces
    );
  }, [context]);

  function handleSave() {
    setLoading(true);
    context
      .repos!.getOrgs()
      .updateConfigOrders({
        showSource,
        sortLivraisonReverse,
        pickingPrintOrders,
        pickingShowMissingPlaces,
        pickingShowMissingRefs,
      })
      .then(() => {
        setLoading(false);
      });
  }

  const actions = (
    <Fab
      color="primary"
      size="small"
      onClick={handleSave}
      aria-label="Enregistrer"
    >
      <SaveIcon />
    </Fab>
  );

  return (
    <CardFlex title="Commandes" actions={actions} loading={loading}>
      <List>
        <ListItemSwitch
          label="Afficher la source des commandes"
          checked={Boolean(showSource)}
          onChange={utils.Form.handleChangeCheck(setShowSource)}
        />
        <ListItemSwitch
          label="Inverser ordre tri livraison"
          checked={Boolean(sortLivraisonReverse)}
          onChange={utils.Form.handleChangeCheck(setSortLivraisonReverse)}
        />
        <ListItemSwitch
          label="Picking : Imprimer les commandes"
          checked={Boolean(pickingPrintOrders)}
          onChange={utils.Form.handleChangeCheck(setPickingPrintOrders)}
        />
        <ListItemSwitch
          label="Picking : Afficher références inconnues"
          checked={Boolean(pickingShowMissingRefs)}
          onChange={utils.Form.handleChangeCheck(setPickingShowMissingRefs)}
        />
        <ListItemSwitch
          label="Picking : Afficher emplacements inconnus"
          checked={Boolean(pickingShowMissingPlaces)}
          onChange={utils.Form.handleChangeCheck(setPickingShowMissingPlaces)}
        />
      </List>
    </CardFlex>
  );
};

export default Commande;
