import { db, io, AnvokError, utils } from "pickup-lib";
import { XmlWriter } from "pickup-lib/dist/src/io";

export interface CielValue {
  stockStart: number;
  stockIn: number;
  stockOut: number;
  stockOutAcq: number;
  stockOutSusp: string;
  stockEnd: number;
}

export type CielValues = { [k: string]: CielValue };

export const reduceCielValue = (stockOutSusp: { [id: string]: number }) => (
  prev: CielValues,
  cur: db.DouaneMove
): CielValues => {
  const id = cur.id.toHexString();
  prev[id] = {
    stockStart: cur.startStock,
    stockIn: cur.in,
    stockOut: cur.out,
    stockOutAcq: cur.out - (stockOutSusp[id] || 0),
    stockOutSusp: stockOutSusp[id] ? stockOutSusp[id].toString() : "",
    stockEnd: cur.endStock,
  };
  return prev;
};

interface ProduitData {
  "libelle-personnalise": string;
  "libelle-fiscal": string;
  tav: number;
  observations: string;
  "balance-stock": {
    "stock-debut-periode": string;
    "entrees-periode": {
      "entree-droits-suspendus": string;
    };
    "sorties-periode": {
      "sorties-avec-paiement-droits": {
        "sorties-avec-paiement-annee-courante": string;
      };
      "sorties-sans-paiement-droits": {
        "sorties-definitives": string;
      };
    };
    "stock-fin-periode": string;
  };
}

function formatHl(ml: number): string {
  return (ml / 100000).toFixed(5);
}

function createCielXmlProduit(
  item: db.Item,
  value: CielValue
): Promise<ProduitData> {
  if (!item.tav) {
    return Promise.reject("Titre alcoométrique volumique non défini");
  }
  if (!item.cielCatFisc) {
    return Promise.reject("Libellé fiscal non défini");
  }
  if (!item.capacity) {
    return Promise.reject("Contentance non définie");
  }
  const data: ProduitData = {
    "libelle-personnalise": item.cielLabel || item.ref + ": " + item.label,
    "libelle-fiscal": item.cielCatFisc || "",
    tav: item.tav || 0,
    observations: "",
    "balance-stock": {
      "stock-debut-periode": formatHl(value.stockStart * item.capacity),
      "entrees-periode": {
        "entree-droits-suspendus": formatHl(value.stockIn * item.capacity),
      },
      "sorties-periode": {
        "sorties-avec-paiement-droits": {
          "sorties-avec-paiement-annee-courante": formatHl(
            value.stockOutAcq * item.capacity
          ),
        },
        "sorties-sans-paiement-droits": {
          "sorties-definitives": formatHl(
            utils.String.parseNumber(value.stockOutSusp, 0) * item.capacity
          ),
        },
      },
      "stock-fin-periode": formatHl(value.stockEnd * item.capacity),
    },
  };
  if (Boolean(item.cielLabel)) {
    return Promise.resolve(data);
  } else {
    return db.Repos.repos()
      .getItems()
      .update({ _id: item._id, cielLabel: item.ref + ": " + item.label })
      .then(function() {
        return data;
      });
  }
}

function createCielXmlProduits(
  items: db.Item[],
  values: CielValues
): Promise<ProduitData[]> {
  return new Promise<ProduitData[]>((res, rej) => {
    let current: Promise<ProduitData | void> = Promise.resolve();
    const errors: { [id: string]: string } = {};
    Promise.all(
      items.map(item => {
        current = current
          .then(function() {
            if (!values[item._id.toHexString()]) {
              return Promise.reject("Pas de données");
            }
            return createCielXmlProduit(item, values[item._id.toHexString()]);
          })
          .then(function(result) {
            return result;
          })
          .catch(err => {
            errors[item._id.toHexString()] = err;
          });
        return current;
      })
    ).then(r => {
      if (Object.keys(errors).length > 0) {
        rej(errors);
      } else {
        res(r as ProduitData[]);
      }
    });
  });
}

export function createCielXml(
  numeroAccise: string | undefined,
  mois: string,
  annee: string,
  items: db.Item[],
  values: CielValues
): Promise<XmlWriter> {
  return new Promise<XmlWriter>((res, rej) => {
    if (!numeroAccise) {
      rej(new AnvokError("Numéro d'accise non défini"));
      return;
    }

    createCielXmlProduits(items, values)
      .then(r => {
        const xml = new io.XmlWriter("mouvements-balances", {
          "xsi:schemaLocation":
            "http://douane.finances.gouv.fr/app/ciel/dtiplus/v1 ciel-dti-plus_v1.0.7.xsd",
          xmlns: "http://douane.finances.gouv.fr/app/ciel/dtiplus/v1",
          "xmlns:xsi": "http://www.w3.org/2001/XMLSchema-instance",
        });
        xml.element("periode-taxation", { mois, annee });
        xml.element("identification-redevable", numeroAccise);
        xml.element("droits-suspendus", [
          {
            produit: r,
          },
        ]);
        res(xml);
      })
      .catch(errors =>
        rej(
          new AnvokError(
            "Certaines données articles sont manquantes",
            "fatal",
            errors
          )
        )
      );
  });
}
