import * as React from "react";

const Loading: React.FunctionComponent = () => {
  // tslint:disable-next-line:max-line-length
  const pathA = "M 0 68.6 L 26.8 0 L 45 0 L 71.8 68.6 L 56 68.6 L 51.1 55.5 L 19.9 55.5 L 15 68.6 L 0 68.6 Z M 24.4 43.4 L 46.5 43.4 L 40 26 A 60.979 60.979 0 0 1 39.384 24.26 Q 38.418 21.417 36.772 16.034 A 839.522 839.522 0 0 1 35.7 12.5 L 35.2 12.5 A 806.837 806.837 0 0 1 33.935 16.667 Q 32.329 21.898 31.403 24.59 A 55.617 55.617 0 0 1 30.9 26 L 24.4 43.4 Z";
  // tslint:disable-next-line:max-line-length
  const pathV = "M 24.8 68.6 L 0 0 L 15.7 0 L 28.6 37.6 Q 29.499 40.214 31.932 48.033 A 2044.955 2044.955 0 0 1 33.1 51.8 L 33.6 51.8 A 1268.598 1268.598 0 0 1 34.9 47.361 Q 37.041 40.1 37.9 37.6 L 50.7 0 L 65.8 0 L 41 68.6 L 24.8 68.6 Z";
  return (
    <div className="anvok-loading heartBeat">
      <svg
        className="anvok-loading-a"
        viewBox="0 0 71.8 68.6"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="svgGroup"
          strokeLinecap="round"
          fillRule="evenodd"
          fontSize="9pt"
          stroke="#000"
          strokeWidth="0.25mm"
          fill="currentColor"
          style={{ stroke: "#000", strokeWidth: "0.25mm", fill: "currentColor" }}
        >
          <path d={pathA} />
        </g>
      </svg>
      <svg
        className="anvok-loading-vbg"
        viewBox="0 0 65.8 68.6"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="svgGroup"
          strokeLinecap="round"
          fillRule="evenodd"
          fontSize="9pt"
          stroke="white"
          strokeWidth="0mm"
          fill="currentColor"
          style={{ stroke: "white", strokeWidth: "0mm", fill: "currentColor" }}
        >
          <path d={pathV} />
        </g>
      </svg>
      <svg
        className="anvok-loading-v"
        viewBox="0 0 65.8 68.6"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="svgGroup"
          strokeLinecap="round"
          fillRule="evenodd"
          fontSize="9pt"
          stroke="white"
          strokeWidth="0mm"
          fill="currentColor"
          style={{ stroke: "white", strokeWidth: "0mm", fill: "currentColor" }}
        >
          <path d={pathV} />
        </g>
      </svg>
    </div>
  );
};

export default Loading;
