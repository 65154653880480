import * as React from "react";
import {
  Button,
  TextField,
  makeStyles,
  Theme,
  createStyles,
  Grid,
} from "@material-ui/core";
import Loader from "../components/Loader";
import { utils, IAuth, AnvokError } from "pickup-lib";
import SnackbarStatic from "../components/SnackbarStatic";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {},
    input: { width: "100%", marginTop: 15, marginBottom: 15 },
  })
);

type onCancel = () => void;

interface Props {
  auth: IAuth;
  onCancel: onCancel;
}

const ForgotForm: React.FunctionComponent<Props> = ({ auth, onCancel }) => {
  const [email, setEmail] = React.useState("");
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [done, setDone] = React.useState(false);
  const classes = useStyles();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    auth
      .passwordLost(email)
      .then(() => {
        setDone(true);
      })
      .catch((err: AnvokError) => {
        setError(err.message);
      })
      .finally(() => setLoading(false));
  };

  return (
    <div>
      <h2>Mot de passe oublié</h2>
      <Loader show={loading}>
        <form onSubmit={handleSubmit}>
          {!done && (
            <TextField
              name="email"
              className={classes.input}
              value={email}
              onChange={utils.Form.handleChange(setEmail)}
              label="Email"
              error={Boolean(error)}
              helperText={error}
            />
          )}
          {done && (
            <SnackbarStatic variant="success">
              Nous venons d'envoyer un email pour changer votre mot de passe
            </SnackbarStatic>
          )}
          <Grid container justify="space-between">
            <Grid item>
              <Button onClick={onCancel}>Retour</Button>
            </Grid>
            <Grid item>
              {!done && (
                <Button type="submit" variant="contained" color="primary">
                  Récupérer
                </Button>
              )}
            </Grid>
          </Grid>
        </form>
      </Loader>
    </div>
  );
};

export default ForgotForm;
